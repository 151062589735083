import React, { useEffect, useState } from 'react';
import {
  TableBody, TableContainer, Table,
  Button,
  Box
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import MainContent from '../../customComponents/mainContent';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import {
  TableHeader,
  GridContainer,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MenuButton,
} from './newlyAdded.styles';
import CustomSearchField from '../../shared/CustomSearchField';
import TablePagination from '../../shared/tablePagination';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import { SAVE_CHANGES_ON_NEWLY_ADDED, COMPLETED_NEWLY_ADDED } from '../../../mutations/products';
import { NEWLY_ADDED_PRODUCTS } from '../../../queries/products';
import { FIND_CATEGORY_QUERY } from '../../../queries/categories';

const headers = [
  'S/N',
  'SKU',
  'Product Name',
  'Supplier',
  'Quantity',
  'Cost Price',
  'Selling Price',
  'Pricing Category',
  'Price in Use'
];

const initialState = {
  searchText: '',
  search: '',
};

const NewlyAddedProduct = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [rows, setRows] = useState([]);
  const [changedRows, setChangedRows] = useState([]);
  const [updated, setUpdated] = useState(false);

  const {
    searchText,
    search
  } = state;

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header}>
      {header}
    </TableHeader>
  ));

  useEffect(() => {
    if (searchText && searchText.length >= 1) {
      setState({ ...state, search: searchText });
    } else if (!searchText) {
      setState({ ...state, search: '' });
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const changeSellingPrice = (e, rowId) => {
    const { value } = e.target;
    const updatedRows = rows.map((product) => {
      if (product.id === rowId) {
        setChangedRows((prev) => {
          const existing = prev.find((item) => item.id === rowId);
          if (existing) {
            return prev.map((item) => (item.id === rowId ? { ...item, sellingPrice: Number(value) } : item));
          }
          return [...prev, { ...product, sellingPrice: value }];
        });
        return {
          ...product,
          sellingPrice: value,
        };
      }
      return product;
    });
    setUpdated(true);
    setRows(updatedRows);
  };

  const [complete, { loading: completeLoading }] = useMutation(COMPLETED_NEWLY_ADDED);
  const handleDone = () => {
    complete({}).then(({ data: { completeInboundProcess: { message } } }) => {
      toast.success(message);
      navigate('/inventory/inbound');
    }).catch((err) => {
      toast.error(err.message);
    });
  };

  const { loading, data: categories } = useQuery(FIND_CATEGORY_QUERY);
  const { data, refetch } = useQuery(NEWLY_ADDED_PRODUCTS, {
    variables: { search }
  });

  const [saveChanges, { loading: saveChangesLoading }] = useMutation(SAVE_CHANGES_ON_NEWLY_ADDED);
  const saveChangesHandler = () => {
    const request = changedRows.map((item) => ({
      productId: Number(item.id),
      sellingPrice: Number(item.sellingPrice),
      categoryId: Number(item.categoryId),
      automaticErpPrice: item.priceInUse !== 'Fixed'
    }));

    const changesData = [
      ...request
    ];
    saveChanges({
      variables: { changesData }
    }).then(({ data: { saveRecentlyAddedProductChanges: { message } } }) => {
      refetch();
      toast.success(message);
      setChangedRows([]);
      setUpdated(false);
    }).catch((err) => {
      toast.error(err.message);
    });
  };

  const changePriceFormat = (value, row, type) => {
    const category = categories?.allCategories.find((item) => item.categoryName === value);
    const updatedRows = rows.map((product) => {
      if (product.id === row.id) {
        const updatedProduct = {
          ...product,
          ...(type === 'priceInUse' && { priceInUse: value }),
          ...(type === 'category' && { pricingCategory: value }),
          ...(type === 'category' && { categoryId: Number(category.id) }),
        };

        // Update the changedRows state
        setChangedRows((prev) => {
          const existing = prev.find((item) => item.id === row.id);
          if (existing) {
            return prev.map((item) => (item.id === row.id ? { ...existing, ...updatedProduct } : item));
          }
          return [...prev, { ...updatedProduct }];
        });
        return updatedProduct;
      }
      return product;
    });
    setUpdated(true);
    setRows(updatedRows);
  };

  useEffect(() => {
    const products = data?.businessRecentlyAddedProducts || [];
    if (products.length) {
      const result = products.map((product) => {
        const {
          quantity,
          product: {
            id, sku, brandName: productName, erpVersion: {
              priceInUse, category: { categoryName: pricingCategory, id: categoryId }, supplier: { name: supplier }, orderCost: costPrice, fixedSellingPrice: sellingPrice
            }
          }
        } = product;
        return ({
          id,
          sku,
          productName,
          supplier,
          quantity,
          costPrice,
          sellingPrice,
          categoryId,
          pricingCategory,
          priceInUse: priceInUse === 'fixed_selling_price' ? 'Fixed' : 'Automatic'
        });
      });
      setRows(result);
    }
  }, [data]);

  const totalNumber = data?.businessRecentlyAddedProductsTotalNumber || 0;

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <MenuButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            Back
          </MenuButton>
          <TitleTextGridContainer>
            <PrimaryTitle variant="h5">Newly Added Products</PrimaryTitle>
            <Box>
              <Button
                variant="outlined"
                disabled={saveChangesLoading}
                onClick={saveChangesHandler}
                style={{ width: '8rem', height: '3rem' }}
              >
                { saveChangesLoading ? 'Loading...' : 'Save Changes' }
              </Button>
              <Button
                variant="contained"
                disabled={updated || completeLoading}
                onClick={handleDone}
                style={{ width: '8rem', height: '3rem', marginLeft: '10px' }}
              >
                { completeLoading ? 'Loading ...' : 'Done' }
              </Button>
            </Box>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchFlex>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search"
              handleChange={(e) => handleSearch(e.target.value)}
              style={{ width: '100%' }}
            />
          </SearchFlex>
          <TableGrid item container>
            {loading ? (
              <ProductsTableLoader />
            ) : (
              <TableContainer>
                <Table>
                  <MainTableHead>
                    {returnHeaders()}
                  </MainTableHead>
                  <TableBody>
                    {
                      rows?.length
                        ? rows?.map((item, index) => {
                          const idx = item?.id;
                          return <ReturnRow key={idx} row={item} sn={index + 1} categories={categories} changeSellingPrice={changeSellingPrice} changePriceFormat={changePriceFormat} />;
                        })
                        : (
                          <MainTableRow>
                            <BodyCell colspan={9} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                          </MainTableRow>
                        )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TableGrid>
          <FooterWrapper item container>
            {totalNumber > 0 && (
              <TablePagination
                total={totalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default NewlyAddedProduct;
