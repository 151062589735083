import React from 'react';
import {
  TableBody, TableContainer, Table,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import {
  TableHeader,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  SecondaryTitle,
} from './SyncLog.styles';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import { PrimaryTitle, TitleTextGridContainer } from './styles';
import { BUSINESS_SYNC_LOG_QUERY } from '../../../../queries/businesses';
import { useStateValue } from '../../../../providers/stateProvider';

const headers = [
  'Date',
  'Synchronization Trails',
  'Status',
];

const SyncLog = () => {
  const [{
    user: {
      businessId
    }
  }] = Object.values(useStateValue());
  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}
    >
      {header}
    </TableHeader>
  ));

  const { loading, data } = useQuery(BUSINESS_SYNC_LOG_QUERY, {
    variables: {
      businessId,
    },
    fetchPolicy: 'network-only'
  });

  const response = data?.businessSyncLog || [];
  return (
    <>
      <TitleTextGridContainer>
        <div>
          <PrimaryTitle variant="h5">
            App Synchronization Log
          </PrimaryTitle>
          <SecondaryTitle>
            See all the times your application synced online with offline and vice-versa
          </SecondaryTitle>
        </div>
      </TitleTextGridContainer>
      <PaperWrapper elevation={0}>
        <TableGrid item container>
          {loading ? (
            <ProductsTableLoader />
          ) : (
            <TableContainer>
              <Table>
                <MainTableHead>
                  {returnHeaders()}
                </MainTableHead>
                <TableBody>
                  {
                  response?.length
                    ? response?.map((item) => {
                      const idx = item?.id;
                      return <ReturnRow key={idx} row={item} />;
                    })
                    : (
                      <MainTableRow>
                        <BodyCell colspan={7} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                      </MainTableRow>
                    )
                }
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TableGrid>
      </PaperWrapper>
    </>
  );
};

export default SyncLog;
