import React, { useEffect, useState } from 'react';
import {
  Box, DialogContent, Slide
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  DialogContainer,
  DialogContentStyled,
  Title,
} from './mapProductDialog.styles';
import CustomSearchField from '../../../shared/CustomSearchField';
import MapProductReturnRow from './mapProductReturnRow';
import NoDisplay from './noDisplay';
import ConfirmDialog from '../../../shared/confirmDialog';
import SuccessDialog from '../../../shared/successDialog';
import { MAP_POS_PRODUCT_QUERY } from '../../../../queries/products';
import { MATCH_PRODUCT_WITH_INVENTORY } from '../../../../mutations/products';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const MapProductDialog = ({
  id, openDialog, setOpenDialog, refetch
}) => {
  const [search, setSearch] = useState('');
  const [mapStatus, setMapStatus] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [productRow, setProductRow] = useState({});
  const [successDialog, setSuccessDialog] = useState(false);

  const {
    loading, data
  } = useQuery(MAP_POS_PRODUCT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { search, searchType: 'products' },
    skip: !search.length
  });

  const rows = data?.salesPageProducts || [];

  const handleClose = () => {
    setOpenDialog(!openDialog);
  };

  const [matchProduct, { loading: mapLoading }] = useMutation(MATCH_PRODUCT_WITH_INVENTORY);

  useEffect(() => {
    if (mapStatus) {
      const request = {
        inboundOrderQueueProductId: Number(id),
        mappingData: {
          productName: productRow?.brandName,
          packSize: productRow?.erpVersion?.packSize,
        }
      };
      matchProduct({
        variables: { inventoryProductData: { ...request } },
      }).then(() => {
        setSuccessDialog(true);
      }).catch((err) => toast.error(err.message));
      setMapStatus(false);
    }
  }, [mapStatus]);

  const matchInstanceHandler = (row) => {
    setProductRow(row);
    setOpenConfirmDialog(true);
    handleClose();
  };

  const renderRowComp = () => {
    if (loading) return <Box sx={{ textAlign: 'center', marginTop: '1rem' }}>Loading...</Box>;
    if (!rows.length) {
      return (
        <Box sx={{
          margin: '1.5rem 0'
        }}
        >
          <NoDisplay />
        </Box>
      );
    }
    if (rows.length > 0) {
      return (
        <Box sx={{
          margin: '1.5rem 0', width: '100%', height: '35rem', overflow: 'scroll'
        }}
        >
          { rows.map((item) => (
            <MapProductReturnRow
              key={item.id}
              row={item}
              matchHandler={matchInstanceHandler}
            />
          ))}
        </Box>
      );
    }
  };

  return (
    <>
      <DialogContainer
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentStyled>
          <Close
            fontSize="small"
            style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
            onClick={handleClose}
          />
          <Title>Map to Existing Product</Title>
          <DialogContent sx={{ marginTop: '0', paddingX: 0 }}>
            <Box>
              <CustomSearchField
                name="search"
                value={search}
                placeholder="Search inventory for product item"
                handleChange={(e) => setSearch(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>
            {renderRowComp()}
          </DialogContent>
        </DialogContentStyled>
      </DialogContainer>
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Map Product?"
        desc="Are you sure you want to map product? When product is mapped, this product will carry the properties of the existing product"
        options={['Cancel', 'Yes, Map']}
        setStatus={setMapStatus}
        loading={mapLoading}
      />
      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={setSuccessDialog}
        title="Product Mapped"
        desc="Your product mapping was successful! the quantity of the product will be updated accordingly when added to your inventory"
        option="Ok"
        refetch={refetch}
      />
    </>
  );
};

MapProductDialog.propTypes = {
  id: PropTypes.string.isRequired,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  refetch: PropTypes.func.isRequired,
};

MapProductDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
};

export default MapProductDialog;
