import React from 'react';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GridContainer,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MenuButton,
  PaperWrapper,
} from './styles';
import MainContent from '../../../../../customComponents/mainContent';
import ReturnsOrderDetails from './logDetails';

const InboundOrderDetailsContainer = () => {
  const navigate = useNavigate();
  const { id, orderId } = useParams();

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <MenuButton onClick={() => navigate('/inbound-history')}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            Back
          </MenuButton>
          <TitleTextGridContainer>
            <div>
              <PrimaryTitle variant="h5">
                Order &nbsp;
                {orderId}
              </PrimaryTitle>
            </div>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <ReturnsOrderDetails id={id} />
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default InboundOrderDetailsContainer;
