import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@mui/material';
import CustomNewTextField from '../../shared/CustomNewTextField';
import CustomButton from '../../customComponents/customButton';
import {
  DatePicker, FiltersGrid,
} from './affiliateFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import MobileFilter from './mobileFilter';
import { useStateValue } from '../../../providers/stateProvider';

const AffiliateFilter = ({
  state, handleFilterChange, handleSetFilter, handleDateFilter, mobile, handleMobile,
  handleClear
}) => {
  const status = [
    { name: 'All', value: '' },
    { name: 'Verified', value: 'VERIFIED' },
    { name: 'Unverified', value: 'UNVERIFIED' },
    { name: 'Suspended', value: 'SUSPENDED' },
  ];

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  return (
    <>
      {!mobile && (
        <Grid container xs={12}>
          <FiltersGrid container item justifyContent="flex-end">
            <DatePicker>
              <CustomDateRanges
                handleSubmit={handleDateFilter}
                xs={12}
              />
            </DatePicker>
            <CustomNewTextField
              field={{ name: 'affiliateName', label: 'Affiliate Name' }}
              state={state}
              handleChange={handleFilterChange}
            />
            <CustomNewTextField
              field={{ name: 'phoneNumber', label: 'Phone Number' }}
              state={state}
              handleChange={handleFilterChange}
            />
            <CustomNewTextField
              field={{ name: 'email', label: 'Email Address' }}
              state={state}
              handleChange={handleFilterChange}
            />
            <CustomNewTextField
              field={{ name: 'status', label: 'Status' }}
              state={state}
              select
              handleChange={handleFilterChange}
              style={{ width: '10rem' }}
            >
              {status.map(({ name, value }) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </CustomNewTextField>
            <CustomButton
              type="primary"
              style={{ width: '6.5rem', height: '2.95rem' }}
              onClick={handleSetFilter}
              disabled={!userPermissions?.includes('manage_affiliates_set_filter')}
            >
              Set Filter
            </CustomButton>
          </FiltersGrid>
        </Grid>
      )}
      {mobile && (
        <MobileFilter
          state={state}
          handleFilterChange={handleFilterChange}
          handleSetFilter={handleSetFilter}
          handleDateFilter={handleDateFilter}
          handleMobile={handleMobile}
          handleClear={handleClear}
        />
      )}
    </>
  );
};

AffiliateFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  handleDateFilter: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
  handleMobile: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired
};

AffiliateFilter.defaultProps = {
  state: {},
};

export default AffiliateFilter;
