import { gql } from '@apollo/client';

export const GET_NOTIFICATION_BY_ID = gql`
  query notificationsById($notificationId: Int) {
    notificationsById(notificationId: $notificationId) {
      id
      title
      message
      notificationType
      status
      dateCreated
      lastUpdated
      action
      pathname
      meta
      businessUser {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notifications($businessUser: Int, $status: String) {
    notifications(businessUser: $businessUser, status: $status) {
      id
      title
      message
      notificationType
      status
      dateCreated
      lastUpdated
      action
      pathname
      meta
      businessUser {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_NOTIFICATION_SETTINGS = gql`
  query businessNotificationSettings {
    businessNotificationSettings {
      lowOnStockAuto
      lowOnStockActive
      lowOnStockTimelines
      expiryActive
      expiryTimelines
      prescriptionActive
      prescriptionTimelines
    }
  }
`;
export const ERP_NOTIFICATIONS = gql`
  query erpNotifications(
    $pageCount: Int $pageNumber: Int
  ) {
    erpNotifications(
      pageCount: $pageCount pageNumber: $pageNumber
    ) {
      id
      productId
      productName
      batchNo
      title
      message
      status
      dateCreated
      lastUpdated
      meta
    }
    erpNotificationsTotalNumber
  }
`;

export const ARCHIVED_ERP_NOTIFICATIONS = gql`
  query archivedErpNotifications(
    $pageCount: Int $pageNumber: Int
  ) {
    archivedErpNotifications(
      pageCount: $pageCount pageNumber: $pageNumber
    ) {
      id
      title
      message
      notificationType
      productId
      productName
      batchNo
      status
      dateCreated
      lastUpdated
      deletedBy {
        id
        fullName
      }
    }
    archivedErpNotificationsTotalNumber
  }
`;
