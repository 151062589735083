import React, { useState } from 'react';
import {
  TableBody, TableContainer, Table, Button,
} from '@mui/material';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  TableHeader,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
} from './returnDetails.styles';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import { EXPORT_OREDER_RETURN_PRODUCT_MUTATION } from '../../../../../../mutations/reports';
import TablePagination from '../../../../../shared/tablePagination';
import CustomSearchField from '../../../../../shared/CustomSearchField';
import ProductsTableLoader from '../../../../../customComponents/loaders/productsTableLoader';
import { INBOUND_RETURN_ORDER_PRODUCTS, INBOUND_RETURN_ORDERS_PRODUCTS_RECEIPT } from '../../../../../../queries/orders';
import useSearchParamsState from '../../../../../helpers/ulrSearchParams';
import { PrimaryTitle, TitleTextGridContainer } from './styles';
import { ReceiptSvg } from '../../../../../../assets/svgs';
import ReasonReceiptDialog from '../../../singleInboundDetails/reasonReceiptDialog';

const headers = [
  'S/N',
  'Product Name',
  'Batch No.',
  'QTY Received',
  'QTY Returned',
  'UOM',
  'Dosage Strength',
  'Cost Price',
  'Expiry Date'
];

const ReturnsOrderDetails = () => {
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useSearchParamsState('search', '');
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  const [receiptData, setReceiptData] = useState({});

  const { id, receiptNumber } = useParams();

  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}
    >
      {header}
    </TableHeader>
  ));

  const handleSearch = (text) => {
    setSearch(text);
  };

  const { loading, data: ReturnData } = useQuery(INBOUND_RETURN_ORDER_PRODUCTS, {
    variables: {
      returnOrderId: id, pageCount, pageNumber,
      ...(search.length >= 3 ? { search } : { search: '' }),
    },
    fetchPolicy: 'network-only'
  });

  const response = ReturnData?.businessInboundQueueOrderReturnDetail || [];
  const totalNumber = ReturnData?.businessInboundQueueOrderReturnDetailTotalNumber || 0;

  const [exportMutation, { loading: exportLoading }] = useMutation(EXPORT_OREDER_RETURN_PRODUCT_MUTATION);
  const handleDownloadCSV = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    exportMutation({
      variables: {
        type: 'download_inbound_queue_order_return_products', name: `download_inbound_queue_order_return_products${randomNum}`, search, returnOrderId: id
      }
    })
      .then(({ data: exportData }) => {
        const { message } = exportData?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };
  const [getReceiptQuery, { loading: receiptLoading }] = useLazyQuery(INBOUND_RETURN_ORDERS_PRODUCTS_RECEIPT, {
    variables: { returnOrderId: id },
    onCompleted: (data) => {
      setReceiptData(data?.singleInboundQueueOrderReturn);
      setOpenReceiptDialog(true);
    },
    onError: () => {
      toast.error('Failed to fetch receipt.');
    }
  });

  const handleAction = () => {
    getReceiptQuery();
  };

  return (
    <>
      <TitleTextGridContainer>
        <div>
          <PrimaryTitle variant="h5">
            #
            {receiptNumber}
          </PrimaryTitle>
        </div>
        <Button
          onClick={handleAction}
          disabled={receiptLoading}
          variant="contained"
        >
          <ReceiptSvg style={{ fontSize: '20px', marginRight: '10px' }} />
          {receiptLoading ? 'loading...' : 'View receipt'}
        </Button>
      </TitleTextGridContainer>
      <PaperWrapper elevation={0}>
        <SearchFlex>
          <CustomSearchField
            name="search"
            value={search}
            placeholder="Search for products..."
            handleChange={(e) => handleSearch(e.target.value)}
            style={{ width: '85%', marginRight: '10px' }}
          />
          <Button
            onClick={handleDownloadCSV}
            disabled={exportLoading}
            variant="outlined"
            style={{ width: '15%' }}
          >
            {exportLoading ? 'loading...' : 'Export CSV'}
          </Button>
        </SearchFlex>
        <TableGrid item container>
          {loading ? (
            <ProductsTableLoader />
          ) : (
            <TableContainer>
              <Table>
                <MainTableHead>
                  {returnHeaders()}
                </MainTableHead>
                <TableBody>
                  {
                  response?.length
                    ? response?.map((item, index) => {
                      const idx = item?.id;
                      return <ReturnRow key={idx} row={item} index={index + 1} />;
                    })
                    : (
                      <MainTableRow>
                        <BodyCell colspan={7} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                      </MainTableRow>
                    )
                }
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TableGrid>
        <FooterWrapper item container>
          {totalNumber > 0 && (
            <TablePagination
              total={totalNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </FooterWrapper>
        <ReasonReceiptDialog openDialog={openReceiptDialog} setOpenDialog={setOpenReceiptDialog} row={receiptData} />
      </PaperWrapper>
    </>
  );
};

export default ReturnsOrderDetails;
