import React from 'react';
import {
  Box, DialogContent, Slide, Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  DialogContainer,
  DialogContentStyled,
  ReturnReceiptHeader,
  ReturnReceiptStoreName,
  ReturnReceiptStoreNumber,
  ReturnReceiptHeaderBg,
  ReturnReceiptStoreAddress
} from './mapProductDialog.styles';
import currencyFormatter from '../../../shared/currencyFormatter';
import { JSONParse } from '../../../../utils/json';
import { toTitleCase } from '../../../../utils/toTitleCase';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ReasonReceiptDialog = ({ openDialog, setOpenDialog, row }) => {
  const {
    receiptNumber, business, dateCreated, returnedBy, inboundQueueOrderReturnProducts
  } = row;
  const handleClose = () => {
    setOpenDialog(!openDialog);
  };

  let total = 0;
  const formatDate = moment(dateCreated).format('ddd, MMM DD YYYY h:mm a');
  const user = returnedBy?.user;
  const { last_name: lastName, first_name: firstName } = JSONParse(user?.profile) || {};
  const contact = JSONParse(business?.contacts);
  const name = `${toTitleCase(lastName)} ${toTitleCase(firstName)}`;

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{
            position: 'absolute',
            top: '1.9rem',
            right: '1.5rem',
            cursor: 'pointer'
          }}
          onClick={handleClose}
        />
        <DialogContent sx={{ marginTop: '0', paddingX: 0 }}>
          <ReturnReceiptHeader>
            <ReturnReceiptStoreName>{business?.name}</ReturnReceiptStoreName>
            <ReturnReceiptStoreAddress>{contact?.address_line_1}</ReturnReceiptStoreAddress>
            <ReturnReceiptStoreNumber>{contact?.mobile_number}</ReturnReceiptStoreNumber>
          </ReturnReceiptHeader>
          <ReturnReceiptHeaderBg>
            <Typography style={{ fontWeight: 'bold' }}>
              Return Receipt #
              {receiptNumber}
            </Typography>
            <Typography style={{ fontSize: '14px', fontWeight: '400', color: '#FFFFFF' }}>
              {formatDate}
            </Typography>
          </ReturnReceiptHeaderBg>

          <table style={{ width: '100%' }} cellPadding={0} cellSpacing={0}>
            <thead>
              <tr style={{
                background: '#F3F9FF', textAlign: 'left', fontWeight: '700', fontSize: '16px', color: '#606060'
              }}
              >
                <th style={{ padding: '10px 0 10px 15px' }}>Product Name</th>
                <th style={{ paddingLeft: '10px' }}>Batch No.</th>
                <th>QTY Returned</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {
                inboundQueueOrderReturnProducts?.map((item) => {
                  const { quantityReturned, inboundQueueOrderProduct: { productName, batchNo, costPrice } } = item;
                  total += (costPrice * quantityReturned);
                  return (
                    <tr
                      key={batchNo}
                      style={{
                        fontWeight: '400', fontSize: '16px', color: '#606060'
                      }}
                    >
                      <td style={{ padding: '15px 0 15px 15px', whiteSpace: 'wrap', width: '18rem' }}>{productName}</td>
                      <td style={{ paddingLeft: '10px' }}>{`#${batchNo}`}</td>
                      <td>{quantityReturned}</td>
                      <td>
                        ₦
                        {currencyFormatter(costPrice)}
                      </td>
                    </tr>
                  );
                })
              }
              <tr style={{
                fontWeight: '600', fontSize: '16px', color: '#606060'
              }}
              >
                <td colSpan={3} style={{ padding: '15px 0 15px 15px' }}>Total Amount</td>
                <td>
                  ₦
                  {currencyFormatter(total)}
                </td>
              </tr>

            </tbody>
          </table>

          <Box sx={{
            borderTop: '1px solid #E7E8E9', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem', padding: '2rem 0 1rem  0', margin: '3rem 1rem 2rem 1rem'
          }}
          >
            <Typography sx={{ fontWeight: '400', fontSize: '14px', color: '#000000' }}>Account Handler</Typography>
            <Typography sx={{ fontWeight: '600', fontSize: '16px', color: '#000000' }}>{name}</Typography>
          </Box>

        </DialogContent>
      </DialogContentStyled>
    </DialogContainer>
  );
};

ReasonReceiptDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  row: PropTypes.instanceOf(Object)
};

ReasonReceiptDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  row: {}
};

export default ReasonReceiptDialog;
