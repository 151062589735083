import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Box, Paper, Table, TableBody,
} from '@mui/material';
import toast from 'react-hot-toast';
import TablePagination from '../../shared/tablePagination';
import {
  TableContainerWrapper,
  DivFullWidth,
  MinMaxContainer,
  MinMaxDescription,
  MinMaxTitle,
  CustomCheckbox, InfoImage
} from './orders.styles';
import {
  TableComponent,
  TableColumnHeaders,
  TableHeader
} from '../../shared/tableGlobalStyle.styles';
import ReturnRow from './returnRow';
import OrdersFilter from './ordersFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { BUSINESS_ORDER_LIST_PRODUCTS } from '../../../queries/orders';
import { UPDATE_ORDER_LIST } from '../../../mutations/products';
import { BodyCell, MainTableRow } from './returnRow.styles';
import InfoIcon from '../../../assets/images/Infoicon.png';

const headers = [
  { name: 'Date Added', width: '190px' },
  { name: 'SKU', width: '210px' },
  { name: 'Product Name (POS)', width: '180px' },
  { name: 'Pack Size', width: '170px' },
  { name: 'Quantity In Stock', width: '250px' },
  { name: 'Quantity to Order', width: '200px' },
  { name: 'Product Name (Marketplace)', width: '200px' },
  { name: 'Marketplace Price', width: '200px' },
  { name: 'Status', width: '200px' },
  { name: 'Action', width: '200px' }
];

const OrdersList = ({ selected, setSelected }) => {
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const [stateRows, setStateRows] = useState([]);
  const [minMax, setMinMax] = useState(false);
  const [changedRows, setChangedRows] = useState({});

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = stateRows.map((product) => product?.product?.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === stateRows.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  const counterHandler = (type, row, val) => {
    const { id, quantity } = row;
    const newData = { ...stateRows.find(({ id: rowId }) => id === rowId) };

    if (!newData) return;

    let currentQuantity = changedRows[id]?.quantity ?? quantity;

    switch (type) {
      case 'increment':
        currentQuantity = Number(currentQuantity) + val;
        break;
      case 'decrement':
        currentQuantity = Math.max(Number(currentQuantity) - val, 0);
        break;
      case 'change':
        currentQuantity = Number(val);
        break;
      default:
        return;
    }

    newData.quantity = currentQuantity;
    setChangedRows((prev) => ({ ...prev, [id]: newData }));
  };

  const {
    loading, error, data, refetch
  } = useQuery(
    BUSINESS_ORDER_LIST_PRODUCTS,
    {
      variables: { pageCount, pageNumber, productName: search },
      fetchPolicy: 'no-cache',
    }
  );

  const rows = data?.businessOrderListProducts || [];
  const total = data?.businessOrderListProductsTotalNumber || 0;

  useEffect(() => {
    if (!rows.length) return;
    setStateRows(rows);
  }, [data]);

  useEffect(() => {
    if (minMax) {
      const newChangedRows = {};
      rows.forEach((row) => {
        newChangedRows[row.id] = { ...row, quantity: Math.floor(row.product.erpVersion.reorderMax) };
      });
      setChangedRows(newChangedRows);
    } else {
      setChangedRows({});
    }
  }, [minMax, rows]);

  const [saveChanges, { loading: saveChangesLoading }] = useMutation(UPDATE_ORDER_LIST);

  const saveChangesHandler = () => {
    const dataArray = Object.values(changedRows);
    if (!dataArray.length) return toast.error('Kindly select at least one product');
    const request = dataArray.map((item) => ({ productId: item?.product?.id, quantity: item?.quantity })).filter((item) => item.quantity !== 0);

    saveChanges({
      variables: { orderListProducts: request }
    }).then(() => {
      refetch();
      toast.success('Successfully saved changes');
    }).catch((err) => toast.error(err.message));
  };

  if (error) return <div>{error.message}</div>;

  return (
    <>
      <MinMaxContainer>
        <InfoImage alt="info icon" src={InfoIcon} />
        <Box>
          <MinMaxTitle>Min/Max function to autofill quantity</MinMaxTitle>
          <MinMaxDescription>
            The quantity of products on your order list will be automatically filled based on your previous purchases over time.
          </MinMaxDescription>
        </Box>
      </MinMaxContainer>
      <OrdersFilter
        search={search}
        setSearch={setSearch}
        loading={saveChangesLoading}
        saveChangesHandler={saveChangesHandler}
      />
      <TableContainerWrapper component={Paper}>
        {loading ? (
          <ProductsTableLoader />
        ) : (
          <TableComponent>
            <Table>
              <TableColumnHeaders>
                <TableHeader style={{ minWidth: '50px' }}>
                  {renderCheckbox()}
                </TableHeader>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                { !rows.length && (
                <MainTableRow>
                  <BodyCell colSpan={11} style={{ textAlign: 'center' }}>No record found</BodyCell>
                </MainTableRow>
                )}
                {rows.map((order) => (
                  <ReturnRow
                    key={order.id}
                    row={order}
                    selected={selected}
                    handleSelect={handleSelect}
                    counterHandler={counterHandler}
                    minMax={minMax}
                    changedValue={changedRows[order.id]?.quantity}
                  />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
      </TableContainerWrapper>
      <DivFullWidth>
        {
            rows.length > 0 && (
            <TablePagination
              total={total}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
            )
          }
      </DivFullWidth>
    </>
  );
};

OrdersList.propTypes = {
  selected: PropTypes.instanceOf(Array).isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default OrdersList;
