import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Paper, Table, TableBody
} from '@mui/material';
import TablePagination from '../../shared/tablePagination';
import { INBOUND_QUEUE_ORDERS } from '../../../queries/orders';
import { TableContainerWrapper, DivFullWidth } from './inbounds.styles';
import { TableComponent, TableColumnHeaders, TableHeader } from '../../shared/tableGlobalStyle.styles';
import ReturnRow from './returnRow';
import InboundsFilter from './inboundsFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import useSearchParamsState from '../../helpers/ulrSearchParams';

const headers = [
  { name: 'Date Received', width: '200px' }, { name: 'Order ID', width: '170px' }, { name: 'Amount of Products', width: '180px' }, { name: 'Total Order Value', width: '220px' },
  { name: 'Marketplace Account', width: '250px' }
];

const Inbounds = () => {
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');

  const initialState = {
    dateFrom: Date,
    dateTo: Date,
    filters: {}
  };

  const [state, setState] = useState(initialState);
  const [search, setSearch] = useSearchParamsState('search', '');

  const {
    dateFrom, dateTo, filters
  } = state;

  const handleSetFilter = () => {
    setState({
      ...state,
      filters: {
        dateFrom,
        dateTo
      }
    });
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    pageCount,
    pageNumber,
    dateFrom,
    dateTo,
    ...(search.length >= 1 ? { orderId: search } : { orderId: '' }),
    ...filters,
  };

  const {
    loading, error, data
  } = useQuery(INBOUND_QUEUE_ORDERS, {
    fetchPolicy: 'no-cache',
    variables
  });

  if (error) return <div>{error.message}</div>;

  return (
    <>
      <InboundsFilter
        search={search}
        setSearch={setSearch}
        setState={setState}
        state={state}
        handleFilterChange={handleFilterChange}
        handleSetFilter={handleSetFilter}
      />
      <TableContainerWrapper component={Paper}>
        {loading ? <ProductsTableLoader /> : (
          <TableComponent>
            <Table>
              <TableColumnHeaders>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {data?.businessInboundQueueOrders.map((order) => (
                  <ReturnRow
                    key={order.id}
                    row={order}
                  />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
        <DivFullWidth>
          <TablePagination
            total={data?.businessInboundQueueOrdersTotalNumber}
            pageCount={+pageCount}
            setPageCount={setPageCount}
            pageNumber={+pageNumber}
            setPageNumber={setPageNumber}
          />
        </DivFullWidth>
      </TableContainerWrapper>
    </>
  );
};

export default Inbounds;
