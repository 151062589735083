import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, TableContainer, Table, Button,
  Grid,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import moment from 'moment';
import {
  TableHeader,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
} from './returnDetails.styles';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import { EXPORT_OREDER_LOG_PRODUCT_MUTATION } from '../../../../../../mutations/reports';
import TablePagination from '../../../../../shared/tablePagination';
import CustomSearchField from '../../../../../shared/CustomSearchField';
import ProductsTableLoader from '../../../../../customComponents/loaders/productsTableLoader';
import { INBOUND_QUEUE_ORDERS_PRODUCTS } from '../../../../../../queries/orders';
import useSearchParamsState from '../../../../../helpers/ulrSearchParams';
import CustomDateRanges from '../../../../../shared/customDateRanges/customDateRanges';

const headers = [
  'S/N',
  'Date Inbounded',
  'Product Name',
  'Batch No.',
  'QTY Received',
  'QTY Inbounded',
  'UOM',
  'Dosage Strength',
  'Cost Price',
  'Expiry Date'
];
const initialState = {
  searchText: '',
  search: '',
  dateFrom: Date,
  dateTo: Date
};

const InboundOrderDetails = ({ id }) => {
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [search, setSearch] = useSearchParamsState('search', '');
  const [state, setState] = useState(initialState);

  const {
    dateFrom, dateTo
  } = state;

  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}
    >
      {header}
    </TableHeader>
  ));

  const handleSearch = (text) => {
    setSearch(text);
  };
  const variables = {
    pageCount,
    pageNumber,
    dateFrom,
    dateTo,
    ...(search.length >= 3 ? { productName: search } : { productName: '' }),
    inboundQueueOrderId: id,
    history: true
  };

  const {
    loading, error, data,
  } = useQuery(INBOUND_QUEUE_ORDERS_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const [exportMutation, { loading: exportLoading }] = useMutation(EXPORT_OREDER_LOG_PRODUCT_MUTATION);
  const handleDownloadCSV = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    exportMutation({
      variables: {
        type: 'download_inbound_queue_order_history_products', name: `download_inbound_queue_order_history_products${randomNum}`, search, orderId: id
      }
    })
      .then(({ data: exportData }) => {
        const { message } = exportData?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };
  const handleSubmit = (dateRange) => {
    const { dateFrom: dFrom, dateTo: dTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(dFrom).format('YYYY-MM-DD'),
      dateTo: moment(dTo).format('YYYY-MM-DD')
    }));
  };

  const { businessInboundQueueOrderProducts = [], businessInboundQueueOrderProductsTotalNumber = 0 } = data || { };
  if (error) return <div>{error.message}</div>;

  return (
    <PaperWrapper elevation={0}>
      <SearchFlex>
        <CustomSearchField
          name="search"
          value={search}
          placeholder="Search for products..."
          handleChange={(e) => handleSearch(e.target.value)}
          style={{ width: '65%', marginRight: '10px' }}
        />
        <Grid item xs={2} style={{ width: '15%' }}>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            xs={12}
            styles={{
              height: '47px',
              width: '105%',
              border: '1px solid #bababa',
              fontSize: '12px'
            }}
          />
        </Grid>
        <Button
          onClick={handleDownloadCSV}
          disabled={exportLoading}
          variant="outlined"
          style={{ width: '15%' }}
        >
          {exportLoading ? 'loading...' : 'Export CSV'}
        </Button>
      </SearchFlex>
      <TableGrid item container>
        {loading ? (
          <ProductsTableLoader />
        ) : (
          <TableContainer>
            <Table>
              <MainTableHead>
                {returnHeaders()}
              </MainTableHead>
              <TableBody>
                {
                businessInboundQueueOrderProducts?.length
                  ? businessInboundQueueOrderProducts?.map((item, index) => {
                    const idx = item?.id;
                    return <ReturnRow key={idx} row={item} index={index} />;
                  })
                  : (
                    <MainTableRow>
                      <BodyCell colspan={7} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                    </MainTableRow>
                  )
              }
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TableGrid>
      <FooterWrapper item container>
        {businessInboundQueueOrderProductsTotalNumber > 0 && (
          <TablePagination
            total={businessInboundQueueOrderProductsTotalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </FooterWrapper>
    </PaperWrapper>
  );
};

InboundOrderDetails.propTypes = {
  id: PropTypes.string.isRequired,
};

export default InboundOrderDetails;
