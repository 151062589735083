import {
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DivFullWidth = styled('div')`
  width: 100%;
`;

export const GridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
  align-items: center;
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

export const TitleTextGridContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 24px;
    margin-top: .5rem;
  }
`;

export const MenuButton = styled(IconButton)`
  background-color: none;
  color: #235a91;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 0 20px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;
