import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { UPDATE_PROMOTION_MUTATION } from '../../mutations/promotions';
import MainContent from '../customComponents/mainContent';
import CustomInputBase from '../customComponents/customInputBase';
import CustomSelectInputBase from '../customComponents/customSelectInputBase';
import CustomButton from '../customComponents/customButton';
import {
  TitleGrid, TitleTextGridContainer, PrimaryTitle, PaperWrapper, MenuButton,
  PromoGrid, ActionGrid, CircularProgressLoader
} from './createPromotion.styles';
import PromotionsActionTypes from '../../providers/reducers/promotions/promotionsTypes';

import { useStateValue } from '../../providers/stateProvider';

const initialState = {
  promoName: '',
  description: '',
  promoInput: 'By Amount',
  promoValue: '',
  startDate: '',
  endDate: '',
};

const initialErrorState = {
  promoNameError: false,
  promoValueError: false,
  startDateError: false,
  endDateError: false,
};

const CreatePromotion = () => {
  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);

  const [{ promotions }, dispatch] = Object.values(useStateValue());

  const navigate = useNavigate();
  const { id: promoId } = useParams();

  const {
    promoNameError, promoValueError, startDateError, endDateError
  } = errorState;

  useEffect(() => {
    if (promoId && promotions?.id) {
      const {
        promoName, description, promoInput, promoValue, startDate, endDate
      } = promotions;
      setState((_state) => ({
        _state, promoName, description, promoInput, promoValue, startDate, endDate
      }));
    } else if (promoId) {
      navigate(`/promotions/details/${promoId}`);
    }
  }, [promoId, promotions]);

  const [updatePromotion, { loading }] = useMutation(UPDATE_PROMOTION_MUTATION);
  const handleSendPromo = () => {
    const {
      promoName, description, promoInput, promoValue, startDate, endDate
    } = state;
    if (!promoId) {
      dispatch({
        type: PromotionsActionTypes.UPDATE_PROMOTIONS,
        payload: state
      });
      return navigate('/promotions/products');
    }
    updatePromotion({
      variables: {
        promotionId: promoId,
        name: promoName,
        description,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        unit: promoInput === 'By Amount' ? 'AMOUNT' : 'PERCENTAGE',
        value: promoValue,
      }
    }).then(({ data }) => {
      const { updatePromotion: { message } } = data ?? {};
      toast.success(message);
      navigate(`/promotions/review-confirm/${promoId}/${promoName}`);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleProceed = () => {
    const {
      promoName, promoInput, promoValue, startDate, endDate
    } = state;
    if (promoName === '') {
      toast.error('Promo Name is required');
    } else if (promoValue === '') {
      toast.error('Promo Value is required');
    } else if (
      promoInput === 'By Percentage' && (Number(promoValue) <= 0 || Number(promoValue) > 100)
    ) {
      toast.error('Please Enter Promo Value in a proper range');
    } else if (startDate === '') {
      toast.error('Start Date is required');
    } else if (endDate === '') {
      toast.error('End Date is required');
    } else if (promoName.length > 1) handleSendPromo();
    return setErrorState({
      ...errorState,
      promoNameError: promoName === '',
      promoValueError: promoValue === '',
      startDateError: startDate === '',
      endDateError: endDate === '',
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'promoName':
      case 'promoValue':
      case 'startDate':
      case 'endDate':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !(value.length)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'promoName', label: 'Promo Name', required: true, placeholder: 'Enter Promotion Name',
      error: promoNameError, helperText: 'Promo name is required'
    },
  ];

  const bottomFields = [
    {
      name: 'promoInput', label: 'Promo Input', required: true, placeholder: 'Select', options: ['By Percentage', 'By Amount'],
    },
    {
      name: 'promoValue', label: 'Promo Value', required: true, placeholder: 'Enter Value', error: promoValueError,
      helperText: 'Please set a proper promo value'
    },
    {
      name: 'startDate', label: 'Start Date', type: 'date', required: true, placeholder: 'Enter start date', error: startDateError,
      disabled: promoId && promotions?.status === 'ONGOING'
    },
    {
      name: 'endDate', label: 'End Date', type: 'date', required: true, placeholder: 'Enter end date', error: endDateError,
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, placeholder, val, type, disabled
    } = field;
    const value = state[fieldName];
    if (['promoInput'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          placeholder={placeholder}
          handleChange={handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        required={required}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        cSize="lg"
      />
    );
  };

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item style={{ marginBottom: '0.5rem' }}>
          <TitleTextGridContainer item xs={12} md={4}>
            <MenuButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft style={{ fontSize: '1.8rem', fill: '#235A91' }} />
            </MenuButton>
            <PrimaryTitle style={{ fontSize: '14px', fontWeight: '500', color: '#235A91' }}>back</PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGrid>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={4}>
            <PrimaryTitle variant="h5" style={{ marginLeft: '0.5rem' }}>{promoId ? 'Edit Promo' : 'Create Promo'}</PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <PromoGrid item container xs={8}>
            <Grid container spacing={2}>
              {topFields.map((field) => (
                <Grid item key={field?.name} xs={12}>{returnTextField(field)}</Grid>
              ))}
              <Grid item xs={12}>
                <CustomInputBase
                  multiline
                  minRows={2}
                  label="Promo Description"
                  placeholder="Describe Promo"
                  name="description"
                  value={state.description}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: '8px' }}>
              {bottomFields.map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
            <ActionGrid item container>
              <CustomButton
                type="primary"
                header
                onClick={handleProceed}
                disabled={loading}
                style={{
                  width: '11rem', height: '2.7rem', background: '#235A91', borderRadius: '5px', border: '1px solid #235A91',
                  boxShadow: '0px 4px 14px rgba(151, 151, 151, 0.19)', color: '#ffffff', fontSize: '12px', fontWeight: '700'
                }}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : 'Proceed'}
              </CustomButton>
            </ActionGrid>
          </PromoGrid>
        </PaperWrapper>
      </Grid>
    </MainContent>
  );
};

export default CreatePromotion;
