import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText,
  Slide,
  Grid,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  DialogContainer,
  CardTitle,
  DialogContentStyled,
  TitleDesc,
  ItemWrapper,
  ItemHeader,
  ItemDesc,
  DialogCancelButton,
  DialogActionButtonText,
  DialogOkButton,
  DialogActionWrapper,
  LinkSvg,
  ConvertSvg,
  RocketSvg,
  ItemContainer,
  ItemHeaderDesc,
  Header, CopySvg, MpLink
} from './uploadFlow.style';
import { marketplaceEnvRoute } from '../../../utils/funcs';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const UploadFlow = ({ openDialog, toggleHandler }) => {
  const marketplaceUrl = marketplaceEnvRoute();
  const RedirectToMP = () => {
    toggleHandler();
    window.open(marketplaceUrl, '_blank');
  };

  return (
    <DialogContainer
      data-testid="product-dialog"
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <DialogContentText>
          <Grid style={{ marginTop: '1rem' }}>
            <Box display="flex" justifyContent="space-between">
              <CardTitle>Link your OGApharmacy POS to Marketplace</CardTitle>
              <Close
                fontSize="small"
                onClick={toggleHandler}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <TitleDesc>It seems you have&apos;t connected your OGApharmacy POS to the Marketplace yet.</TitleDesc>
          </Grid>
          <ItemContainer container>
            <RocketSvg />
            <Grid>
              <Header>Connect your POS to Marketplace in few steps</Header>
              <ItemHeaderDesc>Follow the simple procedure outlined below, and you&apos;ll be done before you know it.</ItemHeaderDesc>
            </Grid>
          </ItemContainer>
          <ItemWrapper container>
            <LinkSvg />

            <Grid style={{ width: '87%' }}>
              <ItemHeader>How to Link Your POS to Your Marketplace Account</ItemHeader>
              <ItemDesc>
                To link your POS to your Marketplace account, simply log in at
                <br />
                <MpLink href={marketplaceUrl} target="_blank" rel="noreferrer">marketplace.ogapharmacy.com.</MpLink>
                Head to your Profile page, scroll down to the Link POS section, and click the Link button to generate your link code.
              </ItemDesc>
            </Grid>
          </ItemWrapper>
          <ItemWrapper container>
            <CopySvg />
            <Grid style={{ width: '87%' }}>
              <ItemHeader>Copy Your POS Link Code</ItemHeader>
              <ItemDesc>
                After clicking the Link POS button, a modal will appear displaying your link code. Simply copy the code and return to the OGApharmacy POS.
              </ItemDesc>
            </Grid>
          </ItemWrapper>
          <ItemWrapper container>
            <ConvertSvg />
            <Grid style={{ width: '87%' }}>
              <ItemHeader>Link Your POS to the OGApharmacy Marketplace</ItemHeader>
              <ItemDesc>
                Go to your Account Setup page and select Connect Marketplace from the side menu. Click the Connect Marketplace button, then enter the code you copied from the Marketplace to complete linking your POS to the OGApharmacy Marketplace.
              </ItemDesc>
            </Grid>
          </ItemWrapper>
          <Grid style={{ marginTop: '2rem' }}>
            <DialogActionWrapper container>
              <DialogCancelButton
                onClick={toggleHandler}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={RedirectToMP}
              >
                <DialogActionButtonText>
                  Redirect to Marketplace
                </DialogActionButtonText>
              </DialogOkButton>
            </DialogActionWrapper>
          </Grid>
        </DialogContentText>
      </DialogContentStyled>
    </DialogContainer>
  );
};

UploadFlow.propTypes = {
  openDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
};

UploadFlow.defaultProps = {
  openDialog: false
};

export default UploadFlow;
