import React from 'react';
import PropTypes from 'prop-types';
import { BodyCell, MainTableRow } from './returnRow.styles';
import currencyFormatter from '../../../utils/currencyFormatter';

const LogReturnRow = ({ row }) => {
  const {
    product: {
      sku,
      brandName,
      searchablePackSize,
    },
    quantity,
    mpProductPrice
  } = row;
  return (
    <MainTableRow>
      <BodyCell>{sku}</BodyCell>
      <BodyCell>{brandName}</BodyCell>
      <BodyCell>{searchablePackSize}</BodyCell>
      <BodyCell>{quantity}</BodyCell>
      <BodyCell>{currencyFormatter(mpProductPrice)}</BodyCell>
    </MainTableRow>
  );
};
LogReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

LogReturnRow.defaultProps = {
  row: {}
};

export default LogReturnRow;
