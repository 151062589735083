import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import currencyFormatter from '../../shared/currencyFormatter';
import { UPDATE_PRODUCTS_STATUS_MUTATION, UPDATE_ORDER_LIST, CLEAR_PRODUCT_QUANTITY } from '../../../mutations/products';
import { CustomCheckbox, ColorField, Status } from './returnRow.styles';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';
import ActionPopper from './actionPopper';
import SingleBatchDialog from '../../products/productDetails/singleBatchDialog';
import SuccessDialog from '../../shared/successDialog';
import ConfirmDialog from '../../shared/confirmDialog';

const ReturnRow = ({
  row, selected, handleSelect, refetchData, productBatchPreference
}) => {
  const [action, setAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [clearProductsDialog, setClearProductsDialog] = useState(false);
  const [clearProductsSuccessDialog, setClearProductsSuccessDialog] = useState(false);

  const isLarge = useMediaQuery('(min-width:992px)');

  const navigate = useNavigate();
  const isSelected = () => selected.indexOf(row.id) !== -1;
  const {
    dateCreated, brandName, quantityInStock, resolvedPriceInUseValue,
    orderCost, status, supplier, id
  } = row;

  const price = currencyFormatter(resolvedPriceInUseValue);
  const cost = currencyFormatter(orderCost);
  const [updateProductStatus] = useMutation(UPDATE_PRODUCTS_STATUS_MUTATION);

  const getStatus = () => {
    if (status === 'ACTIVE') {
      return (
        <ColorField>
          <Status active />
          Enabled
        </ColorField>
      );
    }
    return (
      <ColorField>
        <Status />
        Disabled
      </ColorField>
    );
  };

  const erpCells = [cost, price];

  const returnCell = (value) => value || 'N/A';

  const renderBodyCells = () => erpCells.map((cell) => (
    <TableColumnRowText key={cell}>{isLarge ? returnCell(cell) : ''}</TableColumnRowText>
  ));

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row.id);
      }}
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const updateProductStatusHandler = (type, productId) => {
    updateProductStatus({
      variables: {
        productId,
        status: type ? 'IN_ACTIVE' : 'ACTIVE'
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateProductStatus || {};
        toast.success(message);
        refetchData();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const [updateOrderList] = useMutation(UPDATE_ORDER_LIST);

  const addToOrderList = (productId) => {
    updateOrderList({
      variables: { orderListProducts: [{ productId, quantity: null }] },
      refetchQueries: ['businessOrderListProductsTotalNumber']
    }).then(({ data }) => {
      toast.success(data?.updateOrderList?.message);
    }).catch((err) => toast.error(err.message));
  };

  const handleAction = (event, type, productRow) => {
    if (type === 'add cart') {
      addToOrderList(+productRow.id);
    } else if (type === 'add batch') {
      if (!productBatchPreference('batch')) return;
      setOpenModal(true);
    } else if (type === 'clear Products') {
      setClearProductsDialog(true);
    } else {
      updateProductStatusHandler(type, productRow.id);
    }
    handleOptions(event);
  };

  const [clearProductQuantities, { loading: clearProductLoading }] = useMutation(CLEAR_PRODUCT_QUANTITY);

  const clearProductHandler = () => {
    clearProductQuantities({
      variables: {
        productId: id
      },
    }).then(() => {
      refetchData();
      setClearProductsDialog(false);
      setClearProductsSuccessDialog(true);
    }).catch((err) => toast.error(err.message));
  };

  const renderDate = moment(dateCreated).format('DD/MM/YY (h:mm)');

  return (
    <>
      <TableColumnRows className="erp-uat-product-tb-row">
        <TableColumnRowText>{renderCheckbox()}</TableColumnRowText>
        <TableColumnRowText>{renderDate}</TableColumnRowText>
        <TableColumnRowText style={{ cursor: 'pointer' }} onClick={() => navigate(`/products/${row?.id}/details`)}>
          {row?.sku}
        </TableColumnRowText>
        <TableColumnRowText className="erp-uat-product-tb-brandName" style={{ cursor: 'pointer' }} onClick={() => navigate(`/products/${row?.id}/details`)}>
          {brandName}
        </TableColumnRowText>
        <TableColumnRowText>{ supplier?.name }</TableColumnRowText>
        {renderBodyCells()}
        <TableColumnRowText>{quantityInStock || 0}</TableColumnRowText>
        <TableColumnRowText>{getStatus()}</TableColumnRowText>
        <TableColumnRowText className="erp-uat-action-popper-td">
          <ActionPopper
            handleAction={handleAction}
            row={row}
          />
        </TableColumnRowText>
      </TableColumnRows>

      <SingleBatchDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        row={row}
        refetch={refetchData}
      />
      <ConfirmDialog
        openDialog={clearProductsDialog}
        setOpenDialog={setClearProductsDialog}
        title="Clear Product Quantities?"
        desc="All quantities attached to this product will be zero out as soon as you confirm this."
        options={['Cancel', 'Yes, Clear']}
        loading={clearProductLoading}
        handleCallBack={clearProductHandler}
      />
      <SuccessDialog
        openDialog={clearProductsSuccessDialog}
        setOpenDialog={setClearProductsSuccessDialog}
        title="Product Quantities Cleared!"
        desc="Quantities successfully cleared for the selected product."
        option="Ok"
      />
    </>

  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
  selected: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func.isRequired,
  refetchData: PropTypes.func.isRequired,
  productBatchPreference: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  selected: []
};

export default ReturnRow;
