import React from 'react';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle,
} from './titleGrid.styles';

export function TitleGrid() {
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle>
          Desktop App Setup
        </PrimaryTitle>
        <SecondaryTitle>
          View license key and also download desktop app to your PC
        </SecondaryTitle>
      </TitleTextGridContainer>

    </TitleGridContainer>
  );
}

export default TitleGrid;
