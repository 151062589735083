import React from 'react';
import {
  Grid, Paper, Typography, Switch, Select,
  TableContainer,
  Button,
  Box
} from '@mui/material';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { styled } from '@mui/material/styles';

export const HeaderText = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: #303030;
  margin-bottom: 10px;
`;

export const SubHeaderText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #606060;
`;

export const MainGrid = styled(Paper)`
  margin-bottom: 1rem;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;
  width: 100%;
`;
export const CardItemContent = styled(Grid)`
  display: ${(props) => (props.open ? 'block' : 'none')};
  transition: transform 1s;
  width: 100%;
`;

export const OptionsGrid = styled(Grid)`
  align-items: center;
  border-radius: 12px;
  background: #fff;
  padding: 15px 25px;
  margin-bottom: 12px;
  box-shadow: 0px 2.9600000381469727px 66.63999938964844px 0px
    rgba(163, 171, 185, 0.12);
`;

export const OptionsGridHalf = styled(OptionsGrid)`
  width: calc(50% - 16px);
  margin-right: 16px;
`;

export const MainSectionPaper = styled(Grid)`
  border-radius: 12px;
  // padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  // box-shadow: 0px 2.9600000381469727px 66.63999938964844px 0px
    rgba(163, 171, 185, 0.12);
`;

export const RadioInfoHeaderGrid = styled(Grid)`
  font-size: 14px;
  font-weight: 700;
  color: #606060;
  margin-bottom: 5px;
  text-transform: capitalize;
`;

export const RadioInfoGrid = styled(Grid)`
  font-size: 12px;
  font-weight: 400;
  color: #606060;
`;

export const HelperText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #606060;
`;

export const SelectFieldStyled = styled(Select)`
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: #606060;
  border-radius: 8px;

  & .MuiOutlinedInput-notchedOutline {
    border: 0.5px solid #d8d8d8;
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1.3px solid #78aada;
  }
`;

export const StoreTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #303030;
  margin-top: 1rem;
  margin-bottom: .5rem;
`;

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 60,
  // width: 42,
  height: 31,
  // height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    color: '#8db8df',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#086ccb',
      '& + .MuiSwitch-track': {
        backgroundColor: '#F1F8FF',
        opacity: 1,
        border: '1px solid #78AADA'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 26,
    height: 26
  },
  '& .MuiSwitch-track': {
    borderRadius: 30 / 2,
    // borderRadius: 26 / 2,
    backgroundColor: '#F1F8FF',
    opacity: 1,
    border: '1px solid #acc0d4',
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0.5rem;
  box-shadow: none;
`;

export const VatTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  color: #606060
`;

export const VatDescription = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #606060;
`;

export const VatButton = styled(Button)`
  border: 1px solid #235A91;
  font-size: 10px;
  font-weight: 700;
  color: #235A91;
  margin-top: 10px;
  padding: 5px 25px;
`;

export const HeaderWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconContainer = styled(Box)`
  background: #F3F9FF;
  padding: 7px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

export const ExpandIcon = styled(AddSharpIcon)`
  transition: transform .5s;
  transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0deg)')};
  color: #235A91;
  font-size: 1.7rem;
`;
