import { TableRow, TableCell } from '@mui/material';
import styled, { css } from 'styled-components';

export const MainTableRow = styled(TableRow)`
  :nth-child(even) {
    background: #f3f9ff;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

const PendingStyles = css`
  background-color: #F29053;
`;

const CompletedStyles = css`
  background-color: #00B588;
`;
const FailedStyles = css`
  background-color: #FF0F0F;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'SUCCESS':
      return CompletedStyles;
    case 'FAILED':
      return FailedStyles;
    default:
      return PendingStyles;
  }
};

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`;

export const Status = styled('div')`
  border-radius: 50%;
  width: 9px;
  height: 9px;

  ${getStyles}
`;

export const StatusContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const OrderListButton = styled('button')`
  border: 1px solid #235a91;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: 600;
  color: #235A91;
  background-color:  #fff;
`;
