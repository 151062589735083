import { gql } from '@apollo/client';

export const CREATE_ORDER_MUTATION = gql`
  mutation createOrder($isManualOrder: Boolean) {
    createOrder(isManualOrder: $isManualOrder) {
      message
      order {
        id
        supplierorderSet {
          id
          orderproductSet {
            id
            name
            quantity
            hybrid
          }
        }
      }
    }
  }
`;

export const UPDATE_ORDER_MUTATION = gql`
  mutation updateOrderProduct(
    $productId: Int!
    $quantity: Int!
    $isManualOrder: Boolean
  ) {
    updateOrderProduct(
      productId: $productId
      quantity: $quantity
      isManualOrder: $isManualOrder
    ) {
      message
      order {
        id
        supplierorderSet {
          id
          orderproductSet {
            id
            name
            quantity
          }
        }
      }
    }
  }
`;

export const BULK_BUY_MUTATION = gql`
  mutation bulkBuy($productIds: [BulkBuyType]!) {
    bulkBuy(productIds: $productIds) {
      message
    }
  }
`;

export const CANCEL_SUPPLIER_ORDERS = gql`
  mutation cancelSupplierOrders($supplierOrderIds: [Int]) {
    cancelSupplierOrders(supplierOrderIds: $supplierOrderIds) {
      message
    }
  }
`;

export const RECEIVE_SUPPLIER_ORDERS = gql`
  mutation receiveSupplierOrders($supplierOrderIds: [Int]) {
    receiveSupplierOrders(supplierOrderIds: $supplierOrderIds) {
      message
    }
  }
`;

export const PLACE_SUPPLIER_ORDERS = gql`
  mutation placeSupplierOrders($supplierOrderIds: [Int]) {
    placeSupplierOrders(supplierOrderIds: $supplierOrderIds) {
      message
    }
  }
`;

export const DISPATCH_ORDER_PRODUCTS_MUTATION = gql`
  mutation dispatchOrder(
    $cart: [ProductQuantityCommentType]!
    $deliveryLocationId: Int!
    $orderId: Int!
  ) {
    dispatchOrder(
      cart: $cart
      orderId: $orderId
      deliveryLocationId: $deliveryLocationId
    ) {
      message
      failed
    }
  }
`;

export const DISPATCH_MULTIPLE_ORDERS = gql`
  mutation dispatchKwikOrders($orderIds: [String]!, $jobId: String!) {
    dispatchKwikOrders(orderIds: $orderIds, jobId: $jobId) {
      message
    }
  }
`;

export const CLOSE_ORDER = gql`
  mutation closeOrder($orderId: Int!) {
    closeOrder(orderId: $orderId) {
      message
    }
  }
`;

export const RETURN_ORDER = gql`
  mutation returnOrder(
    $returnOrderId: Int!
    $products: [ReturningProductType]!
  ) {
    returnOrder(returnOrderId: $returnOrderId, products: $products) {
      message
    }
  }
`;

export const COMPLETE_RETURN_ORDER = gql`
  mutation completeReturnOrder(
    $returnOrderId: Int!
    $products: [ReturningProductType]!
  ) {
    completeReturnOrder(returnOrderId: $returnOrderId, products: $products) {
      message
    }
  }
`;

export const CANCEL_RETURN_ORDER = gql`
  mutation cancelOrderReturn($returnOrderId: Int!) {
    cancelOrderReturn(returnOrderId: $returnOrderId) {
      message
    }
  }
`;

export const UPDATE_SUPPLIER_ORDER_META_MUTATION = gql`
  mutation updateSupplierOrderMeta(
    $supplierOrderId: Int!
    $keyValues: [ModelMetaType]!
  ) {
    updateSupplierOrderMeta(
      supplierOrderId: $supplierOrderId
      keyValues: $keyValues
    ) {
      message
    }
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus(
    $orderId: Int!
    $status: String!
    $changes: [ProductQuantityReceivedType]
  ) {
    updateOrderStatus(orderId: $orderId, status: $status, changes: $changes) {
      message
    }
  }
`;

export const RECEIVE_MANUAL_SUPPLIER_ORDER = gql`
  mutation receiveManualSupplierOrder(
    $supplierOrderId: Int!
    $keyValues: [ModelMetaType]!
  ) {
    receiveManualSupplierOrder(
      supplierOrderId: $supplierOrderId
      keyValues: $keyValues
    ) {
      message
      supplierOrder {
        id
        meta
        lastUpdated
      }
    }
  }
`;

export const RECEIVE_AUTOMATIC_SUPPLIER_ORDER = gql`
  mutation receiveAutomaticSupplierOrder(
    $supplierOrderId: Int!
    $keyValues: [ModelMetaType]!
  ) {
    receiveAutomaticSupplierOrder(
      supplierOrderId: $supplierOrderId
      keyValues: $keyValues
    ) {
      message
      supplierOrder {
        id
        meta
        lastUpdated
      }
    }
  }
`;

export const RECONCILE_MANUAL_SUPPLIER_ORDER = gql`
  mutation reconcileManualSupplierOrder($supplierOrderId: Int!) {
    reconcileManualSupplierOrder(supplierOrderId: $supplierOrderId) {
      message
      supplierOrder {
        id
        meta
      }
    }
  }
`;

export const RECONCILE_AUTOMATIC_SUPPLIER_ORDER = gql`
  mutation reconcileAutomaticSupplierOrder($supplierOrderId: Int!) {
    reconcileAutomaticSupplierOrder(supplierOrderId: $supplierOrderId) {
      message
      supplierOrder {
        id
        meta
      }
    }
  }
`;

export const MANUAL_ORDER_PAY_NOW = gql`
  mutation manualOrderPayNow($supplierOrderId: Int!) {
    manualOrderPayNow(supplierOrderId: $supplierOrderId) {
      message
      supplierOrder {
        id
        meta
      }
    }
  }
`;

export const SAVE_MANUAL_ORDER_PROGRESS = gql`
  mutation saveManualOrderProgress(
    $supplierOrderId: Int!
    $productBatches: [ProductBatchType]!
  ) {
    saveManualOrderProgress(
      supplierOrderId: $supplierOrderId
      productBatches: $productBatches
    ) {
      message
      supplierOrder {
        id
        meta
      }
    }
  }
`;

export const SAVE_AUTOMATIC_ORDER_PROGRESS = gql`
  mutation saveAutomaticOrderProgress(
    $supplierOrderId: Int!
    $productBatches: [ProductBatchType]!
  ) {
    saveAutomaticOrderProgress(
      supplierOrderId: $supplierOrderId
      productBatches: $productBatches
    ) {
      message
      supplierOrder {
        id
        meta
      }
    }
  }
`;

export const RECONCILE_MANUAL_SUPPLIER_ORDER_NOT_RECEIVED = gql`
  mutation notReceiveManualSupplierOrder(
    $supplierOrderId: Int!
    $productBatches: [ProductBatchType]!
  ) {
    notReceiveManualSupplierOrder(
      supplierOrderId: $supplierOrderId
      productBatches: $productBatches
    ) {
      message
      supplierOrder {
        id
        meta
      }
    }
  }
`;

export const VERIFY_ORDER_PAYMENT = gql`
  mutation verifyPayment(
    $reference: String!
    $status: String!
    $orderId: Int!
    $supplierOrderId: Int!
    $orderProducts: [OrderProductPaymentType]!
  ) {
    verifyPayment(
      reference: $reference
      status: $status
      orderId: $orderId
      supplierOrderId: $supplierOrderId
      orderProducts: $orderProducts
    ) {
      message
    }
  }
`;

export const MANUAL_PAYMENT = gql`
  mutation manualPayment(
    $amount: Int!
    $via: String!
    $affiliateId: String
    $supplierId: String
    $paymentType: String
    $paymentDate: String!
    $description: String
    $orders: [PaymentOrderType]
  ) {
    manualPayment(
      amount: $amount
      via: $via
      affiliateId: $affiliateId
      supplierId: $supplierId
      paymentType: $paymentType
      paymentDate: $paymentDate
      description: $description
      orders: $orders
    ) {
      message
    }
  }
`;

export const UPDATE_ORDER_WHILE_SORTING = gql`
  mutation updateOrderWhileSorting(
    $cart: [ProductQuantityType]!
    $orderId: Int!
  ) {
    updateOrderWhileSorting(cart: $cart, orderId: $orderId) {
      message
      order {
        id
        status
        meta
        metaStatus
      }
      supplierOrders {
        id
        supplier {
          id
          name
        }
        orderproductSet {
          alternativeSupplier {
            id
            name
          }
          id
          product {
            meta
            price
          }
          quantity
        }
      }
    }
  }
`;

export const AUTOMATIC_ORDERING_MUTATION = gql`
  mutation automaticOrdering(
    $isManualOrder: Boolean!
    $supplierIds: [Int]!
    $hybrid: String!
  ) {
    automaticOrdering(
      isManualOrder: $isManualOrder
      supplierIds: $supplierIds
      hybrid: $hybrid
    ) {
      message
    }
  }
`;

export const JUST_IN_TIME_MUTATION = gql`
  mutation justInTimeOrder($orderId: Int!) {
    justInTimeOrder(orderId: $orderId) {
      message
    }
  }
`;

export const CREATE_MANUAL_REORDER_MUTATION = gql`
  mutation createManualReorder(
    $cart: [ProductQuantityReturnType]!
    $supplierId: Int!
  ) {
    createManualReorder(cart: $cart, supplierId: $supplierId) {
      message
    }
  }
`;

export const UPDATE_CURRENT_ORDER_BUSINESS = gql`
  mutation updateCurrentOrderBusiness($businessId: Int!) {
    updateCurrentOrderBusiness(businessId: $businessId) {
      message
      order {
        id
      }
    }
  }
`;

// export const CREATE_ORDER_INVOICE_MUTATION = gql`
//   mutation createOrderInvoice(
//     $businessId: Int
//     $supplierName: String!
//     $supplierAddress: String
//     $invoiceNumber: String
//     $invoiceImage: String
//     $email: String
//     $mobile: String
//     $dateReceived: String
//   ) {
//     createOrderInvoice(
//       businessId: $businessId
//       supplierName: $supplierName
//       supplierAddress: $supplierAddress
//       invoiceNumber: $invoiceNumber
//       invoiceImage: $invoiceImage
//       email: $email
//       mobile: $mobile
//       dateReceived: $dateReceived
//     ) {
//       message
//       invoice {
//         id
//         dateCreated
//         supplierAddress
//         supplier{
//           name
//         }
//         invoiceImage
//         invoiceNumber
//         business{
//           name
//         }
//       }
//     }
//   }
// `;

// export const UPDATE_ORDER_INVOICE_MUTATION = gql`
//   mutation updateOrderInvoice(
//     $orderInvoiceId: Int!
//     $businessId: Int
//     $supplierName: String!
//     $supplierAddress: String
//     $invoiceNumber: String
//     $invoiceImage: String
//     $email: String
//     $mobile: String
//     $dateReceived: String
//   ) {
//     updateOrderInvoice(
//       orderInvoiceId: $orderInvoiceId
//       businessId: $businessId
//       supplierName: $supplierName
//       supplierAddress: $supplierAddress
//       invoiceNumber: $invoiceNumber
//       invoiceImage: $invoiceImage
//       email: $email
//       mobile: $mobile
//       dateReceived: $dateReceived
//     ) {
//       message
//       invoice {
//         id
//         dateCreated
//         supplierAddress
//         supplier{
//           name
//         }
//         invoiceImage
//         invoiceNumber
//         business{
//           name
//         }
//       }
//     }
//   }
// `;

// export const DELETE_ORDER_INVOICE_MUTATION = gql`
//   mutation deleteOrderInvoice(
//     $orderInvoiceId: Int!
//   ) {
//     deleteOrderInvoice(
//       orderInvoiceId: $orderInvoiceId
//     ) {
//       message
//     }
//   }
// `;

// export const UPDATE_ORDER_INVOICE_BATCHES_MUTATION = gql`
//   mutation updateOrderInvoiceBatches(
//     $orderInvoiceId: Int!
//     $clearInvoiceBatchesBeforeUpdate: Boolean
//     $productBatchMaps: [InvoiceProductBatchMapType]
//   ) {
//     updateOrderInvoiceBatches(
//       orderInvoiceId: $orderInvoiceId
//       clearInvoiceBatchesBeforeUpdate: $clearInvoiceBatchesBeforeUpdate
//       productBatchMaps: $productBatchMaps
//     ) {
//       message
//       invoice {
//         id
//         dateCreated
//         supplierAddress
//         supplier{
//           id
//           name
//         }
//         invoiceImage
//         invoiceNumber
//         business{
//           id
//           name
//         }
//       }
//     }
//   }
// `;

// export const DELETE_ORDER_INVOICE_BATCHES_MUTATION = gql`
//   mutation deleteOrderInvoiceBatches(
//     $orderInvoiceId: Int!
//     $batchIds: [Int]!
//   ) {
//     deleteOrderInvoiceBatches(
//       orderInvoiceId: $orderInvoiceId
//       batchIds: $batchIds
//     ) {
//       message
//       invoice {
//         id
//         dateCreated
//         supplierAddress
//         supplier{
//           id
//           name
//         }
//         invoiceImage
//         invoiceNumber
//         business{
//           id
//           name
//         }
//       }
//     }
//   }
// `;

// export const RECEIVE_ORDER_INVOICE_MUTATION = gql`
//   mutation receiveOrderInvoice(
//     $orderInvoiceId: Int
//   ) {
//     receiveOrderInvoice(
//       orderInvoiceId: $orderInvoiceId
//     ) {
//       message
//       invoice {
//         id
//         dateCreated
//         supplierAddress
//         supplier{
//           id
//           name
//         }
//         invoiceImage
//         invoiceNumber
//         business{
//           id
//           name
//         }
//       }
//     }
//   }
// `;

export const MAIL_PROFORMA_INVOICE_BUSINESS = gql`
  mutation mailProformaInvoiceBusiness($orderId: String!) {
    mailProformaInvoiceBusiness(orderId: $orderId) {
      message
    }
  }
`;

export const DISPATCH_MORE_ORDER = gql`
  mutation dispatchMoreOrder($orderId: Int!) {
    dispatchMoreOrder(orderId: $orderId) {
      message
      supplierorderSet {
        id
        meta
        orderproductSet {
          id
          name
          price
          unitCost
          quantity
          quantityRecieved
          savings
          meta
          supplierOrder {
            status
            supplier {
              name
              settings
            }
          }
          product {
            meta
          }
        }
      }
    }
  }
`;

export const LINK_ORDER_TO_ERP = gql`
  mutation linkOrderToErp($orderId: Int) {
    linkOrderToErp(orderId: $orderId) {
      order {
        id
      }
      message
    }
  }
`;

export const APPROVE_ORDER = gql`
  mutation approveOrder($orderId: Int!) {
    approveOrder(orderId: $orderId) {
      message
      order {
        id
      }
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation createNote($content: String!, $orderId: Int!, $created: String) {
    createNote(content: $content, orderId: $orderId, created: $created) {
      message
    }
  }
`;

export const APPROVEORDERTOXERO = gql`
  mutation approveOrderXero($note: String!, $orderId: Int!) {
    approveOrderXero(note: $note, orderId: $orderId) {
      message
    }
  }
`;

export const SEND_ORDER_TO_MP = gql`
  mutation sendOrderToMp($mpAccountLinkCode: String) {
    sendOrderToMp(mpAccountLinkCode: $mpAccountLinkCode) {
      message
    }
  }
`;

export const MAP_PRODUCT_WITH_MP_INSTANCE = gql`
  mutation matchProductWithMpInstance(
    $mpProductData: MapProductToMPProductInputType!
  ) {
    matchProductWithMpInstance(mpProductData: $mpProductData) {
      message
    }
  }
`;

export const ADD_TO_INVENTORY = gql`
  mutation addToInventory(
    $inboundQueueOrderId: Int!
    $inboundQueueOrderProductId: Int
  ) {
    addToInventory(
      inboundQueueOrderId: $inboundQueueOrderId
      inboundQueueOrderProductId: $inboundQueueOrderProductId
    ) {
      message
    }
  }
`;
