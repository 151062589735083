import {
  DialogContent, Dialog, Button, Typography, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 0.75rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 750px;
  height: fit-content !important;
  overflow: auto;
  padding-bottom: 0.5rem;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  color: #303030;
  margin: 1rem 0 0 0;
`;

export const ReturnTitle = styled(Typography)`
  font-weight: 800;
  font-size: 16px;
  color: #303030;
  margin-top: 1rem;
`;

export const Description = styled(Typography)`
  color: #606060;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonBorder = styled(Button)`
  border: 1px solid #235a91;
  min-width: 6.5rem;
  height: 3rem;
  margin-top: 14px;
  font-size: 13px;
  border-radius: 8px;
`;

export const EmptyFlex = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #606060;
`;

export const EmptyImg = styled('img')`
  width: 6rem;
`;

export const RowContainer = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e7e8e9;
`;

export const RowTitle = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  color: #303030;
`;

export const ReturnTable = styled('table')`
  width: 100%;
`;

export const ReturnTableHeader = styled('tr')`
  background: #f3f9ff;
  height: 3rem !important;
`;

export const ReturnTableRow = styled('tr')``;

export const ReturnTableData = styled('td')`
  font-weight: 700;
  font-size: 15px;
  color: #303030;
  padding: 0.6rem 0.8rem !important;
`;

export const ReturnTableBodyData = styled('td')`
  font-weight: 500;
  font-size: 14px;
  color: #606060;
  padding: 0.6rem 0.8rem !important;
`;

export const ReturnProductFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 1rem;
`;

export const ReturnReceiptHeader = styled(Typography)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const ReturnReceiptStoreName = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  color: #606060;
`;

export const ReturnReceiptStoreAddress = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  color: #606060;
`;

export const ReturnReceiptStoreNumber = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #606060;
`;

export const ReturnReceiptHeaderBg = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0 1rem 0;
  width: 100%;
  height: 60px;
  line-height: 100px;
  background: #235A91;
  color: #fff;
  padding: 0 2rem;
`;
