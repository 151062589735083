import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  SideNavWrapper, SideGrid, SideNavLine, SideNavButton, SideNavText,
  SetupWheelIcon, OutletsIcon, PreferenceIcon, RolesAndPermissionsIcon, ConnectMarketplaceIcon, AppDownload
} from './sideNav.styles';

const SIDE_NAV_ICONS = [
  [<SetupWheelIcon />, 'Business Profile', '/settings/business'],
  [<PreferenceIcon />, 'Preferences', '/settings/preferences'],
  [<RolesAndPermissionsIcon />, 'Roles & Permissions', '/settings/roles-permissions', '/settings/users'],
  [<OutletsIcon />, 'Outlets', '/settings/outlets'],
  [<OutletsIcon />, 'Sub-businesses', '/settings/sub-business'],
  [<AppDownload />, 'Desktop App', '/settings/desktop-setup'],
  [<ConnectMarketplaceIcon />, 'Connect Marketplace', '/settings/connect-marketplace'],
];

const SideNav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Grid item container xs={3}>
      <SideNavWrapper elevation={0}>
        {SIDE_NAV_ICONS.map((sideItem) => (
          <SideGrid item container key={sideItem[1]}>
            <SideNavLine
              orientation="vertical"
              flexItem
              active={pathname === sideItem[2] || pathname === sideItem[3]}
            />
            <SideNavButton
              active={pathname === sideItem[2] || pathname === sideItem[3]}
              onClick={() => navigate(`${sideItem[2]}`)}
            >
              {sideItem[0]}
              <SideNavText>{sideItem[1]}</SideNavText>
            </SideNavButton>
          </SideGrid>
        ))}
      </SideNavWrapper>
    </Grid>
  );
};

export default SideNav;
