import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  Description,
  RowContainer,
  RowTitle,
} from './mapProductDialog.styles';
import currencyFormatter from '../../../shared/currencyFormatter';

const MapProductReturnRow = ({ row, matchHandler }) => {
  const {
    brandName, quantityInStock, erpVersion: { packSize, orderCost }
  } = row;

  return (
    <RowContainer onClick={() => matchHandler(row)}>
      <Box>
        <RowTitle>{brandName}</RowTitle>
        <Description>
          <span>{`Pack Size: ${packSize || 0}`}</span>
          <span>{`Quantity in stock: ${quantityInStock || 0}`}</span>
        </Description>
      </Box>
      <Box>
        <RowTitle>
          ₦
          {currencyFormatter(orderCost)}
        </RowTitle>
      </Box>
    </RowContainer>
  );
};

MapProductReturnRow.propTypes = {
  row: PropTypes.shape(Object),
  matchHandler: PropTypes.func.isRequired,
};

MapProductReturnRow.defaultProps = {
  row: {}
};

export default MapProductReturnRow;
