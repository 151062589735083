import React from 'react';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GridContainer,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MenuButton,
  PaperWrapper,
} from './styles';
import MainContent from '../../../customComponents/mainContent';
import OrderHistoryDetails from './orderDetails';

const OrderHistoryDetailsContainer = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <MenuButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            Back
          </MenuButton>
          <TitleTextGridContainer>
            <div>
              <PrimaryTitle variant="h5">
                Order #
                {id}
              </PrimaryTitle>
            </div>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <OrderHistoryDetails id={id} />
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default OrderHistoryDetailsContainer;
