import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Link, Copy, RocketIcon, Convert
} from '../../../assets/svgs';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px !important;
  }
`;

export const CardTitle = styled('span')`
  font-size: 20px;
  font-weight: 700;
  color: #235A91;
  line-height: 24px;
`;

export const DialogContentStyled = styled(DialogContent)`
  max-width: 46rem !important;
  height: auto;
  box-shadow: none;
  padding: 30px 35px;
`;

export const TitleDesc = styled(Typography)`
  color: #606060;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.99px;
  margin-top: 15px;
`;

export const ItemWrapper = styled(Grid)`
  align-items: center;
  gap: 25px;
  display: flex;
  padding: 10px 0px;
  margin: 20px 0px;

`;

export const MpLink = styled('a')`
  color: #007BFF;
`;

export const ItemContainer = styled(Grid)`
  align-items: center;
  gap: 15px;
  padding: 13px 10px;
  margin: 20px 0px;
  background: #F3F9FF;
  border-radius: 7px;
`;

export const Header = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  line-height: 26.27px;
`;

export const ItemHeader = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  line-height: 26.27px;
`;

export const ItemHeaderDesc = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #606060;
  line-height: 22px;
`;

export const ItemDesc = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #606060;
  line-height: 22px;
`;

export const DialogOkButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  background: #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  width: 47%;


  &:hover {
    background-color: #235A91;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  color: #235A91;
  border-radius: 5px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border: 1.5px solid #235A91;
  width: 47%;
  `;

export const DialogActionButtonText = styled(Typography)`
  font-size: 14px;
  font-weight: 700;

  @media(max-width: 991px) {
    font-size: 30px;
  }
`;

export const DialogActionWrapper = styled(Grid)`
  align-items: center;
  gap: 20px;
  padding: 5px 10px;
  margin: 2rem 0px 10px 0px;
`;

export const CopySvg = styled(Copy)`
  width: 3.5rem;
  height: 3.5rem;
`;

export const LinkSvg = styled(Link)`
  width: 3.5rem;
  height: 3.5rem;
`;

export const ConvertSvg = styled(Convert)`
  width: 3.5rem;
  height: 3.5rem;
  }
`;
export const RocketSvg = styled(RocketIcon)`
  width: 2.5rem;
  height: 3rem;
`;

export const Image = styled('img')`
  width: 3.5rem;
  height: 3.5rem;
`;
