import React from 'react';
import {
  DialogContent,
  Slide,
  Box, Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import warning from '../../assets/images/Warning.gif';
import {
  DialogContainer,
  DialogContentStyled,
  TitleStyled,
  DescStyled,
  FooterStyled
} from './confirmDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmDialog = ({
  openDialog,
  setOpenDialog,
  title,
  desc,
  options,
  loading,
  setStatus,
  fromInventory,
  handleClearCart,
  declineConfirmation,
  declineConfirmationAction,
  callBackFunction,
  callBackFunctionAction,
  handleDescDiaolg,
  handleCallBack
}) => {
  const handleClose = () => {
    if (fromInventory) handleClearCart();
    if (declineConfirmation) declineConfirmationAction();
    if (callBackFunction) return callBackFunctionAction();
    setOpenDialog(false);
  };

  const confirmHandler = () => {
    setStatus(true);
    setOpenDialog(false);
    if (handleDescDiaolg) handleDescDiaolg();
    if (handleCallBack) return handleCallBack();
  };

  return (
    <DialogContainer
      open={openDialog}
      data-testid="confirmDialog"
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpenDialog(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={() => setOpenDialog(false)}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={2}>
              <img src={warning} alt="warning" width={155} />
            </Box>
            <TitleStyled>{title}</TitleStyled>
            <DescStyled>{desc}</DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <Button variant="outlined" onClick={handleClose}>
            {loading ? 'Loading...' : options[0]}
          </Button>
          <Button variant="contained" disabled={loading} onClick={confirmHandler}>
            {loading ? 'Loading...' : options[1]}
          </Button>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

ConfirmDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  setStatus: PropTypes.func,
  fromInventory: PropTypes.bool,
  handleClearCart: PropTypes.func,
  declineConfirmation: PropTypes.bool,
  declineConfirmationAction: PropTypes.func,
  callBackFunction: PropTypes.bool,
  callBackFunctionAction: PropTypes.func,
  handleDescDiaolg: PropTypes.func,
  handleCallBack: PropTypes.func,
};

ConfirmDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  title: '',
  desc: '',
  options: [],
  loading: false,
  setStatus: () => false,
  fromInventory: false,
  handleClearCart: () => null,
  declineConfirmation: false,
  declineConfirmationAction: () => null,
  callBackFunction: false,
  callBackFunctionAction: () => null,
  handleDescDiaolg: () => null,
  handleCallBack: () => null
};

export default ConfirmDialog;
