import { gql } from '@apollo/client';

export const CREATE_REPORT_MUTATION = gql`
  mutation createReport(
    $business: Int
    $status: String
    $products: [StockReportProductType]!
  ) {
    createReport(business: $business, status: $status, products: $products) {
      message
    }
  }
`;

export const CREATE_NEW_REPORT_MUTATION = gql`
  mutation createReport($business: Int!, $status: String!) {
    createReport(business: $business, status: $status) {
      message
      stockReport {
        id
      }
    }
  }
`;

export const UPDATE_REPORT_MUTATION = gql`
  mutation updateReport(
    $stockReportId: Int
    $business: Int
    $status: String
    $products: [StockReportProductUpdateType]!
  ) {
    updateReport(
      stockReportId: $stockReportId
      business: $business
      status: $status
      products: $products
    ) {
      message
    }
  }
`;

export const REMOVE_REPORT_MUTATION = gql`
  mutation removeStockInventory($stockReportId: Int!, $inventoryIds: [Int]!) {
    removeStockInventory(
      stockReportId: $stockReportId
      inventoryIds: $inventoryIds
    ) {
      message
      __typename
    }
  }
`;

export const RECONCILE_STOCK_COUNT = gql`
  mutation reconcileStockCount(
    $productId: Int
    $reason: String
    $note: String
  ) {
    reconcileStockCount(productId: $productId, reason: $reason, note: $note) {
      message
    }
  }
`;

export const CLOSE_REPORT_MUTATION = gql`
  mutation closeReport($stockReportId: Int) {
    closeReport(stockReportId: $stockReportId) {
      message
    }
  }
`;

export const EXPORT_MUTATION = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $status: String
    $dateFrom: String
    $dateTo: String
  ) {
    exportCsv(
      type: $type
      name: $name
      status: $status
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      message
    }
  }
`;

export const EXPORT_BACK_ORDERS_MUTATION = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $search: String
    $dateFrom: String
    $dateTo: String
  ) {
    exportCsv(
      type: $type
      name: $name
      search: $search
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      message
    }
  }
`;

export const EXPORT_PRODUCT_MUTATION = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $category: String
    $productId: String
    $productName: String
    $supplier: String
    $status: String
    $dateFrom: String
    $dateTo: String
  ) {
    exportCsv(
      type: $type
      name: $name
      category: $category
      productId: $productId
      productName: $productName
      supplier: $supplier
      status: $status
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      message
    }
  }
`;

export const EXPORT_GRAPH_REPORT = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $filterBy: String
    $dateFrom: String
    $dateTo: String
  ) {
    exportCsv(
      type: $type
      name: $name
      filterBy: $filterBy
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      message
    }
  }
`;

export const EXPORT_MUTATION_AUTO_PO = gql`
  mutation exportCsv($type: String!, $name: String!, $orderId: String!) {
    exportCsv(type: $type, name: $name, orderId: $orderId) {
      message
    }
  }
`;

export const EXPORT_LOW_ON_STOCK_MUTATION = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $tab: LowOutOfStockTabEnum!
    $search: String
  ) {
    exportCsv(type: $type, name: $name, tab: $tab, search: $search) {
      message
    }
  }
`;

export const EXPORT_ORDER_LIST = gql`
  mutation exportCsv($type: String!, $name: String!, $productName: String) {
    exportCsv(type: $type, name: $name, productName: $productName) {
      message
    }
  }
`;

export const EXPORT_INBOUND_QUEUE_ORDERS = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $orderId: String
    $dateFrom: String
    $dateTo: String
  ) {
    exportCsv(
      type: $type
      name: $name
      orderId: $orderId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      message
    }
  }
`;

export const EXPORT_INBOUND_QUEUE_ORDER_PRODUCTS = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $orderId: String!
    $matchStatus: MatchStatusEnum
  ) {
    exportCsv(
      type: $type
      name: $name
      orderId: $orderId
      matchStatus: $matchStatus
    ) {
      message
    }
  }
`;

export const EXPORT_OREDER_LOG_MUTATION = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $search: String
    $dateFrom: String
    $dateTo: String
  ) {
    exportCsv(
      type: $type
      name: $name
      search: $search
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      message
    }
  }
`;

export const EXPORT_OREDER_LOG_PRODUCT_MUTATION = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $search: String
    $dateFrom: String
    $dateTo: String
    $orderId: String!
  ) {
    exportCsv(
      type: $type
      name: $name
      productName: $search
      dateFrom: $dateFrom
      dateTo: $dateTo
      orderId: $orderId
    ) {
      message
    }
  }
`;

export const EXPORT_OREDER_RETURN_PRODUCT_MUTATION = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $search: String
    $returnOrderId: String
  ) {
    exportCsv(
      type: $type
      name: $name
      search: $search
      returnOrderId: $returnOrderId
    ) {
      message
    }
  }
`;

export const EXPORT_OREDER_RETURN_MUTATION = gql`
  mutation exportCsv(
    $type: String!
    $name: String!
    $search: String
    $dateFrom: String
    $dateTo: String
  ) {
    exportCsv(
      type: $type
      name: $name
      search: $search
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      message
    }
  }
`;
