import React from 'react';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  GridContainer,
  TitleGrid,
  MenuButton,
} from './styles';
import MainContent from '../../../../../customComponents/mainContent';
import ReturnsOrderDetails from './returnDetails';

const InboundReturnDetails = () => {
  const navigate = useNavigate();

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <MenuButton onClick={() => navigate('/inbound-history')}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            Back
          </MenuButton>
        </TitleGrid>
        <ReturnsOrderDetails />
      </GridContainer>
    </MainContent>
  );
};

export default InboundReturnDetails;
