import React, { useState, useEffect } from 'react';
import {
  Button, DialogContent, Grid, Slide
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { RETURN_PRODUCT_INBOUND_QUEUE_ORDER } from '../../../../mutations/products';
import {
  DialogContainer,
  DialogContentStyled,
  ReturnTitle,
  ReturnProductFooter
} from './mapProductDialog.styles';
import CustomInputBase from '../../../customComponents/customInputBase';
import ConfirmDialog from '../../../shared/confirmDialog';
import SuccessDialog from '../../../shared/successDialog';
import ReasonReceiptDialog from './reasonReceiptDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ReasonDialog = ({
  rows, openDialog, setOpenDialog, setSelected
}) => {
  const { inboundQueueOrderId } = useParams();

  const [note, setNote] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmReturn, setConfirmReturn] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [receiptDetails, setReceiptDetails] = useState({});
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);

  const [returnInBoundQueueProduct, { loading }] = useMutation(
    RETURN_PRODUCT_INBOUND_QUEUE_ORDER
  );

  useEffect(() => {
    if (confirmReturn) {
      const returnProductsData = rows.map((item) => ({ inboundQueueOrderProductId: Number(item.id), quantityReturned: item.quantityToReturn }));
      const returnData = {
        inboundQueueOrderId: Number(inboundQueueOrderId),
        note,
        returnProductsData
      };
      returnInBoundQueueProduct({
        variables: { returnData },
        refetchQueries: ['businessInboundQueueOrderProducts']
      }).then(({ data: { returnProductsOnInboundQueueOrder: { inboundQueueOrderReturn } } }) => {
        setConfirmReturn(false);
        setSuccessDialog(true);
        setSelected([]);
        setReceiptDetails(inboundQueueOrderReturn);
      }).catch((err) => toast.error(err.message));
    }
  }, [confirmReturn]);

  const handleClose = () => {
    setOpenDialog(!openDialog);
  };

  const returnHandler = () => {
    handleClose();
    setOpenConfirmDialog(true);
  };

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{
            position: 'absolute',
            top: '1.9rem',
            right: '1.5rem',
            cursor: 'pointer'
          }}
          onClick={handleClose}
        />
        <ReturnTitle>Reason for Return</ReturnTitle>
        <DialogContent sx={{ marginTop: '0', paddingX: 0 }}>
          <p style={{ marginTop: '-1rem' }}>
            You are about to return your product(s), you are required to give a
            reason for return
          </p>

          <Grid item xs={12} style={{ marginTop: '.8rem' }}>
            <CustomInputBase
              multiline
              minRows={3}
              label="Add Note"
              placeholder="This note will help us serve you better"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Grid>
          <ReturnProductFooter>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={returnHandler}>
              Submit
            </Button>
          </ReturnProductFooter>
        </DialogContent>
      </DialogContentStyled>

      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Are you sure you want to return?"
        desc="Hi User, are you sure you want to return the products"
        options={['Cancel', 'Yes, Return']}
        setStatus={setConfirmReturn}
        loading={loading}
      />
      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={setSuccessDialog}
        title="Product on Return List"
        desc="Hi User, your products are currently on return list"
        option="View Receipt"
        refetch={() => { setOpenReceiptDialog(true); }}
      />
      <ReasonReceiptDialog openDialog={openReceiptDialog} setOpenDialog={setOpenReceiptDialog} row={receiptDetails} />
    </DialogContainer>
  );
};

ReasonDialog.propTypes = {
  rows: PropTypes.instanceOf(Array).isRequired,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  setSelected: PropTypes.func.isRequired
};

ReasonDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false
};

export default ReasonDialog;
