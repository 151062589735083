import React, { useState } from 'react';
import {
  Grid, Paper, Table, TableBody, TableHead
} from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { PROMOTION_QUERY, PROMOTION_PRODUCTS_QUERY } from '../../../queries/promotions';
import MainContent from '../../customComponents/mainContent';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { TableHeader } from '../../shared/tableGlobalStyle.styles';
import CustomButton from '../../customComponents/customButton';
import CustomSearchField from '../../shared/CustomSearchField';
import TablePagination from '../../shared/tablePagination';
import PromoHeader from '../promoHeader';
import ReturnRow from './returnRow';
import {
  GridContainer, TitleTextGridContainer, PrimaryTitle, TitleGrid, MenuButton,
  PaperWrapper, TableContainerWrapper, DivFullWidth, SearchGrid,
} from './promoDetails.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';
import PromotionsActionTypes from '../../../providers/reducers/promotions/promotionsTypes';

import { useStateValue } from '../../../providers/stateProvider';

const headers = [
  'Product Name', 'Pack Size', 'Cost Price', 'Selling Price', 'Promo Price', 'Quantity Sold'
];

const PromoReview = () => {
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const [{ user }, dispatch] = Object.values(useStateValue());
  const { platform, allowedPermissionsMap: { userPermissions } } = user;

  const navigate = useNavigate();
  const { id: promoId } = useParams();

  const isEditable = (_status) => _status === 'UPCOMING' || _status === 'ONGOING';

  const {
    loading: promoLoading, data: promotionData
  } = useQuery(PROMOTION_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id: promoId }
  });
  const { promotion } = promotionData ?? {};
  const {
    id, name, description, unit, value, startDate, endDate, status
  } = promotion ?? {};

  const getInput = (promoInput) => {
    switch (promoInput) {
      case 'PERCENTAGE': return 'By Percentage';
      case 'AMOUNT': return 'By Amount';
      default:
        break;
    }
  };

  const handleEditPromo = () => {
    dispatch({
      type: PromotionsActionTypes.UPDATE_PROMOTIONS,
      payload: {
        id,
        promoName: name,
        description,
        promoInput: getInput(unit),
        promoValue: value,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        status
      }
    });
    navigate(`/promotions/create/${promoId}`);
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  const variables = {
    promotionId: promoId,
    ...(searchText.length > 2 && { searchProductName: searchText }),
    pageCount,
    pageNumber,
  };

  const { loading, error, data } = useQuery(PROMOTION_PRODUCTS_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const {
    promotionProducts = [], promotionProductsTotalNumber = 0
  } = data || {};

  const rows = promotionProducts?.map((promoProd) => ({
    ...new Product(promoProd?.product, platform),
    // promoPrice: promoProd?.promoPrice
  }));

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container style={{ marginBottom: '1rem' }}>
          <TitleTextGridContainer item xs={12} md={6}>
            <MenuButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft style={{ fontSize: '1.8rem', fill: '#235A91' }} />
            </MenuButton>
            <PrimaryTitle style={{ fontSize: '14px', fontWeight: '500', color: '#235A91' }}>back</PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGrid>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={6}>
            <PrimaryTitle variant="h5">{`${promotion?.name} Details`}</PrimaryTitle>
          </TitleTextGridContainer>
          {isEditable(status) ? (
            <Grid item container alignItems="center" justifyContent="flex-end" xs={12} md={6}>
              <CustomButton
                onClick={handleEditPromo}
                type="primary"
                header
                style={{
                  width: '11.125rem', height: '3rem', fontWeight: '700', fontSize: '12px', color: '#ffffff', border: '1px solid #235A91',
                  background: '#235A91', borderRadius: '5px', boxShadow: '0px 4px 14px rgba(151, 151, 151, 0.19)'
                }}
              >
                Edit promo
              </CustomButton>
            </Grid>
          ) : '' }
        </TitleGrid>
        <PromoHeader
          promotion={promotion}
          loading={promoLoading}
        />
        <TitleGrid container item>
          <PrimaryTitle variant="h5">Promo Products</PrimaryTitle>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchGrid item container>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search"
              handleChange={(e) => setSearchText(e?.target?.value)}
              style={{ width: '100%' }}
            />
          </SearchGrid>
          <TableContainerWrapper component={Paper}>
            {loading ? <ProductsTableLoader /> : (
              <Table>
                <TableHead>
                  {returnHeaders()}
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {rows.map((_row) => (
                    <ReturnRow
                      key={_row.id}
                      row={_row}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainerWrapper>
          <DivFullWidth>
            {promotionProductsTotalNumber > 0 && (
              <TablePagination
                total={promotionProductsTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </DivFullWidth>
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default PromoReview;
