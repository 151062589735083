import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Paper, Table, TableBody } from '@mui/material';
import TablePagination from '../../shared/tablePagination';
import { UNRECONCILED_AUTOMATIC_SUPPLIER_ORDERS } from '../../../queries/orders';
import {
  TableContainerWrapper,
  DivFullWidth,
} from './managePurchaseOrder.styles';
import {
  TableComponent,
  TableColumnHeaders,
  TableHeader
} from '../../shared/tableGlobalStyle.styles';
import ReturnRow from './returnRow';
import ManagePoFilter from './managePOFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';

const headers = [
  { name: 'Date', width: '200px' },
  { name: 'Supplier', width: '200px' },
  { name: 'PO Number', width: '200px' },
  { name: 'Parent ID', width: '320px' },
  { name: 'Amount of Products', width: '220px' },
  { name: 'Total Order Value', width: '220px' },
  { name: 'Order Status', width: '200px' }
];

const initialState = {
  currentPage: 1,
  currentPageCount: 20,
  poNumber: '',
  parentId: '',
  status: '',
  supplier: '',
  dateFrom: '',
  dateTo: '',
  filters: {}
};

const ManagePurchaseOrderContainer = () => {
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [state, setState] = useState(initialState);

  const {
    poNumber, status, parentId, supplier, dateFrom, dateTo, filters
  } = state;

  const handleSetFilter = () => {
    setState({
      ...state,
      filters: {
        poNumber,
        parentId,
        status,
        dateFrom,
        dateTo,
        supplier
      }
    });
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader
      style={{ minWidth: width }}
      className={`erp-uat-${name.replace(/ /g, '-')}`}
      key={name}
    >
      {name}
    </TableHeader>
  ));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    pageCount,
    pageNumber,
    ...(dateFrom && { dateFrom }),
    ...(dateTo && { dateTo }),
    ...filters,
  };

  const { loading, error, data } = useQuery(UNRECONCILED_AUTOMATIC_SUPPLIER_ORDERS, {
    fetchPolicy: 'no-cache',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const products = data?.unreconciledAutomaticSupplierOrders;

  return (
    <>
      <ManagePoFilter
        state={state}
        handleFilterChange={handleFilterChange}
        handleSetFilter={handleSetFilter}
        setState={setState}
      />

      <TableContainerWrapper component={Paper}>
        {loading ? (
          <ProductsTableLoader />
        ) : (
          <TableComponent>
            <Table>
              <TableColumnHeaders>{returnHeaders()}</TableColumnHeaders>
              <TableBody>
                {products.map((order) => (
                  <ReturnRow key={order.id} row={order} />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
        <DivFullWidth>
          {data?.supplierOrderTotalNumber > 0 && (
          <TablePagination
            total={data?.supplierOrderTotalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
          )}
        </DivFullWidth>
      </TableContainerWrapper>
    </>
  );
};

export default ManagePurchaseOrderContainer;
