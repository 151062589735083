import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Grid, TableBody, Button } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import useSearchParamsState from '../../helpers/ulrSearchParams';
import { ALL_PRODUCT_BATCHES_QUERY } from '../../../queries/products';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import TablePagination from '../../shared/tablePagination';
import { EXPORT_MUTATION } from '../../../mutations/reports';
import CustomNewTextField from '../../shared/CustomNewTextField';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import SuccessDialog from '../../shared/successDialog';
import StatusDropdown from './statusDropdown';
import { useStateValue } from '../../../providers/stateProvider';
import {
  TableGrid, TableHeader,
  TableComponent, TableColumnHeaders, FooterWrapper, FilterGrid
} from './batchDetails.styles';
import SingleBatchDialog from '../../products/productDetails/singleBatchDialog';

const headers = [
  { name: 'S/N', width: '70px' }, { name: 'Date Created', width: '250px' }, { name: 'Suppliers', width: '250px' }, { name: 'Product Name', width: '250px' },
  { name: 'Batch#', width: '150px' }, { name: 'Order Cost', width: '130px' }, { name: 'Expiry Date', width: '150px' }, { name: 'Status', width: '150px' }, { name: 'QTY In Pack', width: '120px' },
  { name: 'Last Updated Field', width: '175px' }, { name: 'Last Update By', width: '150px' }, { name: 'Last Update Date', width: '250px' },
  { name: 'Previous (Dynamic) Info', width: '230px' }, { name: 'Current (Dynamic) Info', width: '220px' }, { name: 'Action', width: '210px' }
];

const BatchDetails = () => {
  const { batchNumber, productName } = useParams();

  const [batchToEdit, setBatchToEdit] = useState('');
  const [openBatchDialog, setOpenBatchDialog] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [dates, setDates] = useState(null);

  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [searchProductName, setSearchProductName] = useSearchParamsState('searchP', '');
  const [searchBatchNumber, setSearchBatchNumber] = useSearchParamsState('searchB', '');
  const [status, setStatus] = useSearchParamsState('status', '');
  const [dFilter, setDFilter] = useSearchParamsState('dts', '');
  const valState = { searchProductName, searchBatchNumber };

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  useEffect(() => {
    if (dFilter) setDates(JSON.parse(dFilter));
  }, [dFilter]);

  useEffect(() => {
    if ((productName && productName.length >= 3) && (batchNumber && batchNumber.length >= 3)) {
      setSearchProductName(productName);
      setSearchBatchNumber(batchNumber);
    }
  }, [batchNumber, productName]);

  const FilterFields = [
    { name: 'searchProductName', label: 'Enter Product Name' },
    { name: 'searchBatchNumber', label: 'Batch Number' },
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'searchProductName': return setSearchProductName(value);
      default: return setSearchBatchNumber(value);
    }
  };

  const handleBatchEdit = (row) => {
    setBatchToEdit(row);
    setOpenBatchDialog(true);
  };

  const handleUrlDates = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setDFilter(JSON.stringify(dateRange));
  };

  const handleSubmit = (dateRange) => {
    setDates(dateRange);
    handleUrlDates(dateRange);
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader style={{ width }} key={name}>
      {name}
    </TableHeader>
  ));

  const handleStatusChange = (_status) => {
    setDotsButtonEl(null);
    setStatus(_status);
  };

  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const handleDownloadCSV = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    const newFileName = `batch_details ${randomNum}`;
    const { dateFrom, dateTo } = dates || {};
    exportMutation({
      variables: {
        type: 'batch_details',
        name: newFileName,
        productName: searchProductName,
        batchNumber: searchBatchNumber,
        dateFrom: (dateFrom ? dateFrom.toISOString().split('T')[0] : undefined),
        dateTo: (dateTo ? dateTo.toISOString().split('T')[0] : undefined)
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const options = [
    { name: 'sellable', disabled: false },
    { name: 'damaged', disabled: false },
    { name: 'expired', disabled: false }
  ];

  const variables = {
    pageCount,
    pageNumber,
    ...(dates && { dateFrom: dates?.dateFrom }),
    ...(dates && { dateTo: dates?.dateTo }),
    ...(status && { status }),
    ...(searchProductName.length >= 3 ? { searchProductName } : { searchProductName: '' }),
    ...(searchBatchNumber.length >= 3 ? { searchBatchNumber } : { searchBatchNumber: '' }),
  };

  const {
    loading, error, data, refetch
  } = useQuery(ALL_PRODUCT_BATCHES_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const {
    allProductBatches = [], allProductBatchesTotalNumber = 0
  } = data || {};

  const unitOfMeasurement = batchToEdit?.product?.erpVersion?.unitOfMeasurement;
  const noOfUom = batchToEdit?.product?.erpVersion?.noOfUom;

  return (
    <>
      <FilterGrid item container spacing={1}>
        {FilterFields.map((field) => (
          <Grid item container style={{ width: '29.9%' }} key={field?.name}>
            <CustomNewTextField
              field={field}
              state={valState}
              handleChange={handleFilterChange}
              straight
              style={{ width: '100%', marginRight: 0 }}
              disabled={!userPermissions?.includes('batch_details_set_filter')}
            />
          </Grid>
        ))}
        <Grid
          item
          container
          style={{ width: '16.8%', pointerEvents: !userPermissions?.includes('batch_details_set_filter') ? 'none' : '' }}
        >
          <CustomDateRanges
            handleSubmit={handleSubmit}
            defaultRange={dates}
            xs={12}
            styles={{
              height: '47px', width: '111%', border: '1px solid #bababa', fontSize: '11.8px'
            }}
          />
        </Grid>
        <Grid item container style={{ width: '9%' }}>
          <Button
            variant="outlined"
            onClick={(e) => setDotsButtonEl(e.currentTarget)}
            disabled={!userPermissions?.includes('batch_details_set_filter')}
            style={{ width: '100%' }}
          >
            <FilterList style={{ width: '20px', marginRight: '.4rem' }} />
            Filter
          </Button>
        </Grid>
        <Grid item container style={{ width: '14.4%' }}>
          <Button
            variant="contained"
            style={{ height: '3rem', width: '100%' }}
            onClick={handleDownloadCSV}
          >
            Download CSV
          </Button>
        </Grid>
      </FilterGrid>
      <TableGrid item container>
        {loading
          ? <ProductsTableLoader />
          : (
            <TableComponent item container>
              <TableColumnHeaders item container>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {allProductBatches.map((_row, indx) => (
                  <ReturnRow
                    key={_row.id}
                    row={_row}
                    rowIndx={indx}
                    handleBatchEdit={handleBatchEdit}
                    refetch={refetch}
                  />
                ))}
              </TableBody>
            </TableComponent>
          )}
      </TableGrid>
      <FooterWrapper item container>
        {allProductBatchesTotalNumber > 0 && (
        <TablePagination
          total={allProductBatchesTotalNumber}
          pageCount={+pageCount}
          setPageCount={setPageCount}
          pageNumber={+pageNumber}
          setPageNumber={setPageNumber}
        />
        )}
      </FooterWrapper>
      <SingleBatchDialog
        open={openBatchDialog}
        onClose={() => setOpenBatchDialog(false)}
        row={{ ...batchToEdit, unitOfMeasurement, noOfUom }}
        updateBatch={batchToEdit}
        refetch={refetch}
      />
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={`${batchToEdit?.product?.brandName} Edited Successfully!`}
        desc={`Hi Pharm, You have successfully updated ${batchToEdit?.product?.brandName}`}
        option="Ok"
        refetch={refetch}
      />
      <StatusDropdown
        dotsButtonEl={dotsButtonEl}
        setDotsButtonEl={setDotsButtonEl}
        options={options}
        action={handleStatusChange}
      />
    </>
  );
};

export default BatchDetails;
