import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import toast from 'react-hot-toast';
import { Button, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import { FIND_SUPPLIERS_QUERY, ALL_SUPPLIERS_QUERY } from '../../../queries/suppliers';
import {
  CREATE_BATCH_MUTATION,
  UPDATE_BATCH_MUTATION
} from '../../../mutations/products';
import {
  HeaderStyled, FooterStyled, DialogContainer,
  DialogTitleStyled, DialogBatchContentStyled
} from '../../shared/uploadProduct/individual/individualProductDialog.styles';
import SuccessDialog from '../../shared/successDialog';
import CustomInputBase from '../../customComponents/customInputBase';
import CustomSelectInputBase from '../../customComponents/customSelectInputBase';

const defaultReceived = moment().format('YYYY-MM-DD');
const defaultExp = moment().add(1, 'years').format('YYYY-MM-DD');

const initialErrorState = {
  batchNoError: false,
  quantityError: false,
  dateReceivedError: false,
  expiryDateError: false,
  unitCostError: false,
  unitOfMeasurementError: false,
  supplierError: false,
  supplierIdError: false,
};

const SingleBatchDialog = ({
  open, onClose, row, refetch, updateBatch
}) => {
  const {
    id: productId, brandName, unitOfMeasurement: rowUnitOfMeasurement, noOfUom
  } = row;
  const initialState = {
    batchNo: '',
    quantity: 0,
    dateReceived: defaultReceived,
    expiryDate: defaultExp,
    unitCost: '',
    quantityInPack: 0,
    unitOfMeasurement: row?.unitOfMeasurement ?? 'Pack',
    supplier: '',
    supplierId: ''
  };

  const useNoOfUom = noOfUom === 0 ? 1 : noOfUom;

  const unitOfMeasurementValue = () => {
    const unitMapping = {
      PACK: ['Pack'],
      SACHET: ['Sachet', 'Pack'],
      UNIT: ['Unit', 'Pack'],
    };
    return unitMapping[rowUnitOfMeasurement?.toUpperCase()] || ['Pack'];
  };

  const [state, setState] = useState(initialState);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);

  useEffect(() => {
    const { quantity, unitOfMeasurement } = state;
    let qtyInPack = 0;
    if (unitOfMeasurement.toLowerCase() === 'pack') {
      qtyInPack = quantity / 1;
    } else {
      qtyInPack = quantity / useNoOfUom;
    }
    setState({ ...state, quantityInPack: qtyInPack.toFixed(1) });
  }, [state]);

  useEffect(() => {
    if (updateBatch) {
      const {
        batchNo, supplier, dateRecieved, expiryDate,
        quantityInStock, orderCost
      } = row;
      const _dR = moment(dateRecieved).format('YYYY-MM-DD');
      const _eD = moment(expiryDate).format('YYYY-MM-DD');
      setState({
        ...state, supplierId: '', supplier: supplier?.name, batchNo, quantity: quantityInStock, dateReceived: _dR, expiryDate: _eD,
        unitCost: orderCost
      });
    }
  }, [updateBatch]);

  const {
    batchNoError,
    quantityError,
    dateReceivedError,
    expiryDateError,
    unitCostError,
    unitOfMeasurementError,
    supplierIdError,
  } = errorState;

  const { data: allSuppliersData } = useQuery(ALL_SUPPLIERS_QUERY, {
    variables: {
      onlyBusinessSuppliers: true
    }
  });
  const suppliers = allSuppliersData?.allSuppliers?.map((newSupplier) => {
    const { id, name } = newSupplier;
    return {
      id, name
    };
  });

  const handleChangeuom = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'batchNo':
      case 'unitOfMeasurement':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length || value === undefined
        });

      case 'quantity':
        return setErrorState({
          ...errorState,
          quantityError: value <= 0 || value === undefined
        });

      // case 'supplierId':
      case 'supplier':
      case 'dateReceived':
      case 'expiryDate':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value || value === undefined
        });

      case 'supplierId':
        if (!updateBatch) {
          return setErrorState({
            ...errorState,
            supplierIdError: !value || value === undefined
          });
        }
        return null;

      case 'unitCost':
        return setErrorState({
          ...errorState,
          unitCostError: value === ''
        });

      default:
        return null;
    }
  };

  const handleChangeSupplier = (event) => {
    const { name, value } = event.target;
    const isSupplier = suppliers.find((supplier) => supplier.name === value);
    setState({
      ...state,
      supplier: isSupplier?.name,
      supplierId: isSupplier.id
    });
    validateState(name, value);
  };

  const [
    findSupplier, { loading }
  ] = useLazyQuery(FIND_SUPPLIERS_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if ((name === 'supplierName') && value.length > 2) {
      findSupplier({
        fetchPolicy: 'no-cache',
        variables: {
          search: value,
          onlyBusinessSuppliers: true
        }
      });
    }
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const handleClose = () => {
    onClose();
    setState(initialState);
  };

  const [createBatch, { loading: batchLoading }] = useMutation(CREATE_BATCH_MUTATION);
  const [updateBatchHandler, { loading: batchUpdateLoading }] = useMutation(UPDATE_BATCH_MUTATION);

  const handleBatch = () => {
    if (updateBatch) {
      const { id: batchId } = updateBatch;
      updateBatchHandler({
        variables: {
          ...state, uom: state.unitOfMeasurement.toUpperCase(), batchId,
        },
      }).then(() => {
        handleClose();
        setSuccessOpenDialog(true);
        if (refetch) refetch();
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      createBatch({
        variables: { ...state, uom: state.unitOfMeasurement.toUpperCase(), productId }
      }).then(({ data: { createBatch: { message } } }) => {
        setSuccessOpenDialog(true);
        if (refetch) refetch();
        handleClose();
      }).catch((err) => {
        toast.error(err?.message);
      });
    }
  };

  const createBatchHandler = () => {
    const {
      batchNo, quantity, dateReceived, expiryDate, unitCost, supplier, supplierId, unitOfMeasurement
    } = state;

    if (batchNo === '') {
      toast.error('Batch No is required');
    } else if (quantity <= 0) {
      toast.error('Quantity must be greater than 0');
    } else if (!dateReceived) {
      toast.error('Date received is required');
    } else if (!expiryDate) {
      toast.error('Expiry date is required');
    } else if (unitCost === '') {
      toast.error('Valid unit cost is required');
    } else if (unitOfMeasurement === '') {
      toast.error('unit of measurement cost is required');
    } else if (supplier === '') {
      toast.error('Supplier is required');
    } else if (!updateBatch && supplierId === '') {
      toast.error('Supplier ID is required');
    } else {
      return handleBatch();
    }

    return setErrorState({
      ...errorState,
      batchNoError: batchNo === '',
      quantityError: quantity <= 0,
      dateReceivedError: !dateReceived,
      expiryDateError: !expiryDate,
      unitCostError: unitCost === '',
      unitOfMeasurementError: unitOfMeasurement === '',
      supplierError: supplier === '',
      supplierIdError: supplierId === '',
    });
  };

  const fields = [
    {
      name: 'batchNo', label: 'Batch No', secured: updateBatch, show: true, placeholder: 'Enter Batch No',
      error: batchNoError, helperText: 'Batch No is required', required: true
    },
    {
      name: 'supplierId', label: 'Supplier', options: suppliers,
      secured: updateBatch, show: true, placeholder: 'Select',
      onChange: handleChangeSupplier,
      val: state?.supplier,
      error: supplierIdError, helperText: 'Supplier is required', required: true
    },
    {
      name: 'unitOfMeasurement', label: 'Unit Of Measurement', options: unitOfMeasurementValue(), secured: false, show: true,
      onChange: handleChangeuom, placeholder: 'Select',
      error: unitOfMeasurementError, helperText: 'Unit of measurement can either be Unit or Sachet', required: true
    },
    {
      name: 'quantity', label: 'Quantity', secured: false, placeholder: '0', type: 'number', error: quantityError, helperText: 'Quantity is required',
    },
    {
      name: 'quantityInPack', label: 'QTY in Pack', secured: true, placeholder: '0', type: 'number'
    },
    {
      name: 'expiryDate', label: 'Expiry Date', secured: false, placeholder: 'Expiry Date', type: 'date', error: expiryDateError, helperText: 'Expiry date is required',
    },
    {
      name: 'unitCost', label: 'Cost Price', secured: false, placeholder: 'Enter cost price', type: 'number', error: unitCostError, helperText: 'Cost price is required',
    },
    {
      name: 'dateReceived', label: 'Date Received', secured: updateBatch, placeholder: 'Date Received', type: 'date', error: dateReceivedError, helperText: 'Date received is required',
    },
  ];

  const returnValue = (name, value) => {
    switch (name) {
      case 'quantity':
        return value && Number(value);
      default:
        return value;
    }
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, secured, placeholder, onChange, val, type, readOnly
    } = field;
    const value = state[fieldName];
    if ([
      'supplierId', 'unitOfMeasurement'
    ].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange || handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={returnValue(fieldName, value)}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={loading}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={readOnly}
      />
    );
  };

  const loadingState = batchLoading || batchUpdateLoading

  return (
    <>
      <DialogContainer
        open={open}
        data-testid="singleBatchDialog"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogBatchContentStyled>
          <HeaderStyled>
            <DialogTitleStyled>{brandName}</DialogTitleStyled>
            <Close
              fontSize="small"
              onClick={handleClose}
              style={{ cursor: 'pointer', marginTop: '-10px' }}
            />
          </HeaderStyled>
          <Grid container spacing={2} mt={1}>
            {fields.map((field) => (
              <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
          <FooterStyled>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" data-testid="batch_button" disabled={loadingState} onClick={createBatchHandler}>
              { loadingState ? 'Loading...' : updateBatch ? 'Update' : 'Add batch' }
            </Button>
          </FooterStyled>
        </DialogBatchContentStyled>
      </DialogContainer>
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title="New Batch Added!"
        desc="Hi Pharm, Your new batch has been successfully added"
        option="ok"
        refetch={refetch}
      />
    </>
  );
};

SingleBatchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  refetch: PropTypes.func,
  row: PropTypes.instanceOf(Object).isRequired,
  updateBatch: PropTypes.instanceOf(Object)
};

SingleBatchDialog.defaultProps = {
  onClose: () => { },
  refetch: () => null,
  updateBatch: null
};

export default SingleBatchDialog;
