import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import currencyFormatter from '../../../../../utils/currencyFormatter';

const ReturnRow = ({
  row
}) => {
  const {
    dateCreated,
    orderId,
    noOfInbounded,
    valueOfInbounded,
    mpAccount,
    id
  } = row;
  const navigate = useNavigate();

  const renderDate = () => moment(dateCreated).format('DD MMM YYYY h:mm A');
  const handleRoute = () => navigate(`/inbound-order-detail/${id}/${orderId}`);

  return (
    <MainTableRow style={{ cursor: 'pointer' }} onClick={handleRoute}>
      <BodyCell>{renderDate()}</BodyCell>
      <BodyCell>{orderId}</BodyCell>
      <BodyCell>{noOfInbounded}</BodyCell>
      <BodyCell>{currencyFormatter(valueOfInbounded)}</BodyCell>
      <BodyCell>{mpAccount?.name}</BodyCell>
    </MainTableRow>

  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
