import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';

const ReturnRow = ({
  row
}) => {
  const {
    sku,
    brandName,
    quantityInStock,
    packSize
  } = row;

  return (
    <MainTableRow>
      <BodyCell>{sku}</BodyCell>
      <BodyCell>{brandName}</BodyCell>
      <BodyCell>{packSize}</BodyCell>
      <BodyCell>{quantityInStock}</BodyCell>
      <BodyCell>{quantityInStock}</BodyCell>
    </MainTableRow>

  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
