import {
  Grid, Divider, IconButton, Typography, Paper
} from '@mui/material';

import { styled } from '@mui/material/styles';
import {
  SetupWheel, Outlets, Preference, RolesAndPermissions, ConnectMarketplace, DownloadSvg
} from '../../../../assets/svgs';

export const SideNavWrapper = styled(Paper)`
  height: 31rem;
  border-radius: 12px;
  padding: 24px 0;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 2.9600000381469727px 66.63999938964844px 0px rgba(163, 171, 185, 0.12);
`;

export const SideGrid = styled(Grid)`
  align-items: center;
  margin-bottom: 10px;

  &:hover {
    & > hr {
      background-color: #235A91;
    }
    & > button {
      background-color: #F1F8FF;
    }
  }
`;

export const SideNavLine = styled(Divider)`
  height: 100%;
  align-self: auto;
  width: 3%;
  border-radius: 0px 8px 8px 0px;
  border: none;
  background-color: ${({ active }) => (active ? '#235A91' : '#FFF')};
`;

export const SideNavButton = styled(IconButton)`
  border-radius: 0px;
  padding: 20px 0 20px 40px;
  width: 97%;
  justify-content: flex-start;
  background-color: ${({ active }) => (active ? '#F1F8FF' : '#FFF')};
`;

export const SideNavItems = styled(Grid)`
  margin-top:50px;
  width: 400px;
`;

export const SideNavText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #606060;
  margin-left: 35px;
`;
export const SideNavItemImage = styled('img')`
  height: 30px;
`;

export const SetupWheelIcon = styled(SetupWheel)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const OutletsIcon = styled(Outlets)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const ConnectMarketplaceIcon = styled(ConnectMarketplace)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const PreferenceIcon = styled(Preference)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const RolesAndPermissionsIcon = styled(RolesAndPermissions)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const AppDownload = styled(DownloadSvg)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 3rem;
  }
`;
