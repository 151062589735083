import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import CartDropdown from './cartDropdown';
import AddPrescriptionDialog from './addPrescriptionDialog';
import currencyFormatter from '../../../utils/currencyFormatter';
import { useStateValue } from '../../../providers/stateProvider';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';
import { round, getUser } from '../../../utils/utils';
import {
  CaretUpIcon, CaretDownIcon, DotsV
} from '../../../assets/svgs';
import {
  CartItemPack, CartItemTitle, MissedSaleIcon, ButtonsWrapper, GridCell, DropDownArrow,
  CustomInputBase, PriceGrid, DiscountGrid, CurrencyTypo, UOMContainer, Indicator, Measurement
} from './cartRow.styles';
import UOMDropdown from './UOMDropdown';
import { toTitleCase } from '../../../utils/toTitleCase';

const CartRow = ({ item }) => {
  const {
    product: {
      erpVersion: { noOfUom }, unitOfMeasurement: defaultUnitOfMeasurement, selectedUnitOfMeasurement
    }
  } = item;
  const measurementToUse = selectedUnitOfMeasurement || defaultUnitOfMeasurement;
  const unitOfMeasurement = toTitleCase(measurementToUse) || 'Pack';
  const measurementToShow = (toTitleCase(defaultUnitOfMeasurement) === 'Pack' || !noOfUom || noOfUom === 0);

  const isManualSale = item?.batches?.size;

  const [calDiscount, setCalDiscount] = useState('');
  const [UOM, setUOM] = useState(unitOfMeasurement || 'Pack');
  const [showDiscount, setShowDiscount] = useState(false);
  const [openPrescription, setOpenPrescription] = useState(false);
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [dotsUOMButtonEl, setDotsUOMButtonEl] = useState(null);

  const [{ sale: { cart, customerModalData }, user }, dispatch] = Object.values(useStateValue());
  const { session, allowedPermissionsMap: { userPermissions } } = user;

  useEffect(() => {
    setUOM(unitOfMeasurement);
    dispatch({
      type: SaleActionTypes.UPDATE_CART_UOM,
      payload: { product: item, unitOfMeasurement }
    });
  }, [unitOfMeasurement]);

  const loggedInUser = getUser(session);

  const changeQty = (quantity) => {
    setDotsButtonEl(null);
    if (!isManualSale && quantity > item.product.quantityInStock) return;

    if (quantity > 0 && isManualSale) {
      const cartItem = cart.get(item.productId);
      dispatch({
        type: SaleActionTypes.UPDATE_BATCH_TO_CART,
        payload: { product: cartItem.product }
      });
      return;
    }
    dispatch({
      type: SaleActionTypes.UPDATE_CART_QUANTITY,
      payload: { product: item, quantity }
    });
  };

  const removeItem = () => {
    setDotsButtonEl(null);
    dispatch({
      type: SaleActionTypes.REMOVE_ITEM_FROM_CART,
      payload: { product: item }
    });
  };

  const handleChange = (e) => {
    if (!isManualSale) {
      const { value } = e?.target ?? '';
      if (Number(value) > item.product.quantityInStock) return;
      if (value && +value < 0) return;
      dispatch({
        type: SaleActionTypes.UPDATE_CART_QUANTITY,
        payload: {
          product: item,
          quantity: value
        }
      });
    }
  };

  const handleShowDiscount = () => {
    setDotsButtonEl(null);
    setShowDiscount(!showDiscount);
  };

  const missedSale = () => {
    setDotsButtonEl(null);
    dispatch({
      type: SaleActionTypes.TOGGLE_MISSED_SALE,
      payload: { product: item }
    });
  };

  const getItemTotal = () => {
    if (UOM === 'Pack') return Math.ceil(item.quantity * item.resolvedPriceInUseValue);
    return Math.ceil((item.quantity / noOfUom) * item.resolvedPriceInUseValue);
  };

  const getDiscountPercentage = () => {
    const disc = item?.discount || 0;
    const value = getItemTotal();
    return round((disc * 100) / value);
  };

  const handleDiscount = (e) => {
    const { value } = e?.target ?? '';
    if (value && (+value < 0 || +value > getItemTotal())) return;
    setCalDiscount(value);
    dispatch({
      type: SaleActionTypes.ADD_PRODUCT_DISCOUNT,
      payload: {
        prodToDiscount: item,
        discount: Number(value),
        isPercentage: false
      }
    });
  };

  const handleOpenPrescription = (cartItem) => {
    setDotsButtonEl(null);
    setOpenPrescription(true);
    // setProdToDiscount(cartItem);
    // setShowPopper(true);
  };

  const handleClosePrescription = () => {
    setOpenPrescription(false);
  };

  const discountName = showDiscount ? 'Remove Discount' : 'Add Discount';

  const options = [
    { name: 'Missed Sale', action: () => missedSale() },
    {
      name: discountName,
      action: () => handleShowDiscount(),
      disabled: !userPermissions?.includes('sales_discount')
    },
    {
      name: !item?.prescription ? 'Add Prescription' : 'View prescription',
      action: () => handleOpenPrescription(item),
      disabled: !customerModalData?.id
    },
    { name: 'Remove', action: () => removeItem() }
  ];

  const UOMoptions = () => (
    measurementToShow
      ? [{ name: 'Pack' }]
      : [{ name: 'Pack' }, { name: toTitleCase(defaultUnitOfMeasurement) }]
  );

  const handleUOMChange = (unit) => {
    setUOM(unit);
    dispatch({
      type: SaleActionTypes.UPDATE_CART_UOM,
      payload: { product: item, unitOfMeasurement: unit }
    });
    setDotsUOMButtonEl(null);
  };

  const toggleUOMModal = (e) => {
    if (isManualSale) {
      const cartItem = cart.get(item.productId);
      dispatch({
        type: SaleActionTypes.UPDATE_BATCH_TO_CART,
        payload: { product: cartItem.product }
      });
      return;
    }
    setDotsUOMButtonEl(e.currentTarget);
  };

  return (
    <Grid item container style={{ borderBottom: '.5px solid #e1e1e1' }}>
      <GridCell item container direction="column">
        <CartItemTitle>{item.brandName}</CartItemTitle>
        <CartItemPack>
          1 pack at
          <b>{currencyFormatter(item.resolvedPriceInUseValue)}</b>
        </CartItemPack>
      </GridCell>
      <GridCell item container>
        <CustomInputBase
          type="number"
          placeholder="0"
          min={1}
          size="medium"
          value={item.quantity}
          onChange={(e) => handleChange(e)}
          onClick={({ target: { value } }) => value > 0 && changeQty(value)}
          endAdornment={(
            <InputAdornment position="end">
              <Grid item container direction="column">
                <CaretUpIcon
                  style={{ width: '.8rem', height: '.8rem', cursor: 'pointer' }}
                  onClick={() => changeQty(+item.quantity + 1)}
                />
                <CaretDownIcon
                  style={{ width: '.8rem', height: '.8rem', cursor: 'pointer' }}
                  onClick={() => +item.quantity > 1 && changeQty(+item.quantity - 1)}
                />
              </Grid>
            </InputAdornment>
          )}
        />
      </GridCell>
      <GridCell item container>
        <Grid
          item
          container
          onClick={(e) => toggleUOMModal(e)}
        >
          <UOMContainer>
            <Indicator />
            <Measurement>{UOM}</Measurement>
            <DropDownArrow />
          </UOMContainer>
        </Grid>
      </GridCell>
      <GridCell item container style={{ textAlign: 'center', alignItems: 'center' }}>
        <PriceGrid item md={8} style={{ textDecoration: item.missedSale ? 'line-through' : 'none' }}>
          {currencyFormatter(getItemTotal())}
        </PriceGrid>
        <ButtonsWrapper item md={4}>
          <MissedSaleIcon
            size="small"
            key={item.brandName}
            disableRipple
            onClick={(e) => setDotsButtonEl(e.currentTarget)}
          >
            <DotsV style={{ fontSize: '.8rem' }} />
          </MissedSaleIcon>
        </ButtonsWrapper>
      </GridCell>

      {showDiscount ? (
        <DiscountGrid item container>
          <Grid item container alignItems="center" md={6}>
            <CustomInputBase
              type="number"
              placeholder="0.00"
              value={calDiscount}
              onChange={handleDiscount}
              style={{ width: '65%' }}
              discount
              startAdornment={(
                <InputAdornment position="start">
                  <CurrencyTypo>₦</CurrencyTypo>
                </InputAdornment>
              )}
            />
          </Grid>
          <Grid item md={6} style={{ textAlign: 'right' }}>
            {`${getDiscountPercentage()} %`}
          </Grid>
        </DiscountGrid>
      ) : ''}
      <CartDropdown
        dotsButtonEl={dotsButtonEl}
        setDotsButtonEl={setDotsButtonEl}
        options={options}
      />
      <AddPrescriptionDialog
        open={openPrescription}
        onClose={handleClosePrescription}
        cartItem={item}
        pharmacist={loggedInUser}
      />
      <UOMDropdown
        dotsButtonEl={dotsUOMButtonEl}
        setDotsButtonEl={setDotsUOMButtonEl}
        options={UOMoptions()}
        action={handleUOMChange}
      />
    </Grid>
  );
};

CartRow.propTypes = {
  item: PropTypes.instanceOf(Object),
};

CartRow.defaultProps = {
  item: [],
};

export default CartRow;
