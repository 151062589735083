import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthContainer from './auth/authContainer';
// import ResetPassword from './auth/resetPassword';
import OrdersContainer from './orders/affiliates/ordersContainer';
import AffiliateContainer from './affiliates/affiliatesContainer';
import AffiliateDetailsContainer from './affiliates/affiliatesDetails/affiliatesDetailsContainer';
import DispatchOrdersContainer from './affiliates/affiliatesDetails/dispatchOrdersContainer';
import OrdersAdminContainer from './orders/admin/ordersContainer';
import SalesHistoryContainer from './sales-xxx/dashboard/dashboardContainer';
import OrdersSupplierContainer from './orders/supplier/ordersContainer';
import SupplierDetailsContainer from './toBeDeleted/supplierDetails/supplierDetailsContainer';
import NewOrderContainer from './orders/newOrder/newOrderContainer';
import MarketOrderContainer from './orders/marketplaceOrder/marketOrderContainer';
import Cart from './cart/index';
import SupplierOrderDetail from './orders/supplierorder-detail/index';
import ManualSupplierOrderDetail from './orders/supplierorder-detail/manualSupplierOrderDetail';
import SupplierOrdersContainer from './orders/supplier-orders/supplierOrdersContainer';
import AffiliateOrderDetail from './orders/affiliates/order-detail/index';
import AffiliateFormDetail from './orders/affiliate-orders/detail/index';
import ClosedOrder from './orders/closed/closedOrders';
import SupplierOrdersFormDetail from './orders/supplier-orders/detail/index';
import InventoryContainer from './inventory/inventoryContainer';
import BusinessSetupContainer from './business/businessSetupContainer';
import PermissionsContainer from './setup/rolesAndPermissions/rolesContainer';
import UsersContainer from './setup/rolesAndPermissions/users/usersContainer';
import OutletsContainer from './setup/outlets/outletsContainer';
import ConnectMarketplaceContainer from './setup/connectMarketplace/connectMarketplaceContainer';
import BusinessDetails from './setup/business/businessContainer';
import DispatchContainer from './orders/dispatch/dispatchContainer';
import ReturnOrder from './orders/returns/returnsContainer';
import OrderReturnReview from './orders/returns/review';
import EODReport from './sales-xxx/eodReport/eodReportContainer';
import { useStateValue } from '../providers/stateProvider';
import MapSuppliercsvDialog from './toBeDeleted/uploadSupplier/mapSuppliercsvDialog';
import MapReconcilecsvDialog from './toBeDeleted/uploadSupplier/mapReconcileCsvDialog';
import MapInventoryCSVDialog from './toBeDeleted/uploadSupplier/mapInventoryCSVDialog';
import MapProductCsvDialog from './shared/uploadProduct/mapProductCsvDialog';
import SalesContainer from './sales/salesContainer';
import SaleDetail from './sales/details/saleDetails';
import ReturnsSummary from './returns/summary';
import Feedback from './feedback/feedbackContainer';
import ManualOrderDashboardContainer from './orders/manual-order/ordersContainer';
import ProductDetails from './products/productDetails/productDetailsContainer';
import AffiliateActionTypes from '../providers/reducers/affiliate/affiliateTypes';
import VirtualPayment from './virtual/virtual';
import SalesReconciliationContainer from './sales-xxx/reconciliation/salesReconciliationContainer';
import MapCustomerCsvDialog from './shared/crudModel/uploadModel/mapModelCsvDialog';
import NetworkDetector from '../utils/networkDetector';
import Customers from './customers';
import CustomerDetailsContainer from './customers/customerDetails/customerDetailsContainer';
import Suppliers from './suppliers';
import TherapeuticTypes from './therapeuticTypes';
import TherapeuticTypesDetails from './therapeuticTypes/individual/details/therapeuticTypeDetailsContainer';
import Categories from './categories';
import CategoryDetail from './categories/individual/categoryDetail';
import SalesReconciliationReport from './sales-xxx/salesReconciliation/salesReconciliationContainer';
import MapOrderCsvDialog from './orders/shared/mapOrderCsvDialog';
import AccessControl from './accessControl';
import StockReportContainer from './stockReport/stockReportContainer';
import StockReportDetails from './stockReport/stockReportDetails';
import InvoicesContainer from './invoices/invoicesContainer';
import InvoiceDetailsContainer from './invoices/invoiceDetails/invoiceDetailsContainer';
import SingleBatchDetails from './inventory/batchDetails/singleBatchDetails/singleBatchDetails';
import ChainManagerDashboard from './chainManagerDashboard/chainManagerDashboard';
import StockTransfer from './stockTransfer/stockTransfer';
import Promotions from './promotions/promotions';
import CreatePromotion from './promotions/createPromotion';
import PromoProducts from './promotions/promoProducts/promoProducts';
import PromoReview from './promotions/promoReview/promoReview';
import PromoDetails from './promotions/promoDetails/promoDetails';
import NotificationSettings from './notificationSettings/notificationSettings';
import NotificationsArchive from './notificationSettings/notificationsArchive';
import ReceivedTransfers from './stockTransfer/receivedTransfers/receivedTransfers';
import StockTransferLogs from './stockTransfer/stockTransferLogs/stockTransferLogs';
import ReceivedTransferDetails from './stockTransfer/receivedTransferDetails/receivedTransferDetails';
import NewReportContainer from './stockReport/newReport/newReportContainer';
import HelpDeskContainer from './helpDesk/helpDeskContainer';
import PreferencesContainer from './setup/preferences/preferencesContainer';
import Notification from './notification/index';
import DataAnalysis from './dataAnalysis/dataAnalysis';
import SingleDataAnalysis from './dataAnalysis/singleDataAnalysis';
import ScDashboard from './scDashboard/dashboardContainer';
import AffiliateDashboard from './affiliateDashboard/dashboardContainer';
import ReportsContainer from './reports/reportsContainer';
import ManageAffiliates from './manageAffiliate/affiliatesContainer';
import AffiliateDetails from './manageAffiliate/affiliateDetails/index';
import Return from './return/returnContainer';
import AdminDashboard from './adminDashboard/dashboardContainer';
import PaymentsContainer from './payments/paymentsContainer';
import DetailsContainer from './orders/marketplaceOrder/detailsPage/detailsContainer';
import DownloadsContainer from './downloads/downloadsContainer';
import TrackOrderContainer from './trackOrder/trackOrderContainer';
import AffiliateInfo from './affiliateProfile';
import PrescriptionContainer from './customers/prescription/prescriptionContainer';
import PurchaseOrderContainer from './inventory/purchaseOrder/purchaseOrderContainer';
import ManagePurchaseOrderContainer from './inventory/mangePurchaseOrder/managePurchaseOrderContainer';
import UpdatePurchaseOrderContainer from './inventory/purchaseOrder/updatePurchaseOrderContainer';
import Uploads from './uploads';
import ManualBatchContainer from './inventory/manualBatch/manualBatchContainer';
import PreloadedInventory from './preloadedInventory';
import SubBusinesses from './setup/subBusinesses/subBusinessContainer';
import AdminBusiness from './adminBusiness';
import SingleInboundDetails from './inventory/inbounds/singleInboundDetails/singleInboundDetails';
import NotificationModal from './notificationSettings/notificationModal';
import LowOnStock from './lowOnStock';
import OrderHistory from './inventory/orderHistory';
import InboundHistory from './inventory/inbounds/inboundHistory';
import OrderHistoryDetailsContainer from './inventory/orderHistory/orderDetails/orderHistoryDetailsContainer';
import NewlyAddedProduct from './inventory/newlyAdded';
import InboundLog from './inventory/orderHistory/inboundLog';
import InboundOrderDetailsContainer from './inventory/inbounds/inboundHistory/inboundOrderLog/logDetails';
import InboundReturnDetails from './inventory/inbounds/inboundHistory/orderReturn/returndetails';
import DesktopSetupContainer from './setup/desktopSetup/desktopSetupContainer';
import SyncLogComponent from './setup/desktopSetup/syncLog';

const App = () => {
  const [{
    user: { isBusinessVirtualStore }
  }, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    if (isBusinessVirtualStore) {
      dispatch({
        type: AffiliateActionTypes.UPDATE_AFFILIATE,
        payload: { isBusinessVirtualStore }
      });
    }
  }, [isBusinessVirtualStore]);

  return (
    <>
      <Routes>
        <Route path="/virtual-store/:orderId" element={<VirtualPayment />} />
        <Route path="/" element={<AuthContainer />} />
        <Route path="/register" element={<AuthContainer />} />
        <Route path="/forgot-password" element={<AuthContainer />} />
        <Route path="/email-verification" element={<AuthContainer />} />
        <Route path="/reset-password" element={<AuthContainer />} />
        <Route path="/orders/:id/detail" element={<SupplierOrderDetail />} />
        <Route path="/orders/:id/affiliate" element={<AffiliateOrderDetail />} />
        <Route path="/orders" element={<OrdersContainer />} />
        <Route path="/sales" element={<SalesContainer />} />
        <Route path="/returns" element={<SalesContainer />} />
        <Route path="/returns/summary" element={<ReturnsSummary />} />
        <Route path="/history" element={<SalesHistoryContainer />} />
        <Route path="/eod-report" element={<EODReport />} />
        <Route path="/sales-reconciliation-report" element={<SalesReconciliationReport />} />
        <Route path="/sales-reconciliation" element={<SalesReconciliationContainer />} />
        <Route path="/sale-details/:id/:param?" element={<SaleDetail />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/customers/:id/details" element={<CustomerDetailsContainer />} />
        <Route path="/map-customer-csv" element={<MapCustomerCsvDialog />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/category/:id/details" element={<CategoryDetail />} />
        <Route path="/suppliers" element={<Suppliers />} />
        <Route path="/access-control" element={<AccessControl />} />
        <Route path="/affiliates" element={<AffiliateContainer />} />
        <Route path="/affiliates/:id/details" element={<AffiliateDetailsContainer />} />
        <Route path="/dispatch-orders" element={<DispatchOrdersContainer />} />
        <Route path="/orders-admin" element={<OrdersAdminContainer />} />
        <Route path="/orders-supplier" element={<OrdersSupplierContainer />} />
        <Route path="/cart" element={(<Cart />)} />
        <Route path="/manual-order" element={(<NewOrderContainer onlyMarketProducts={false} primaryTitle="New Manual Order" />)} />
        <Route path="/manual-cart" element={(<Cart manualCart backRoute="/manual-order" />)} />
        <Route path="/manual-order-dashboard" element={<ManualOrderDashboardContainer />} />
        <Route path="/stock-count-report" element={<StockReportContainer />} />
        <Route path="/dashboard" element={<AdminDashboard />} />
        <Route path="/reports/:id/details" element={<StockReportDetails />} />
        <Route path="/manual-stock-report/:id?" element={(<NewReportContainer />)} />
        <Route path="/new-order" element={<MarketOrderContainer />} />
        <Route path="/new-order/:id/details" element={<DetailsContainer />} />
        <Route path="/open-orders" element={<SupplierOrdersContainer backRoute="/manual-order-dashboard" onlyMarketProducts={false} primaryTitle="Open Orders" />} />
        <Route path="/invoices" element={<InvoicesContainer />} />
        <Route path="/invoices/:id/details" element={(<InvoiceDetailsContainer />)} />
        <Route path="/batch-details-info/:batchId" element={<SingleBatchDetails />} />
        <Route path="/cm-dashboard" element={<ChainManagerDashboard />} />
        <Route path="/stock-transfer" element={<StockTransfer />} />
        <Route path="/stock-transfer-logs" element={<StockTransferLogs />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/promotions/details/:id" element={<PromoDetails />} />
        <Route path="/promotions/create/:id?" element={<CreatePromotion />} />
        <Route path="/promotions/products/:id?/:name?" element={<PromoProducts />} />
        <Route path="/promotions/review-confirm/:id/:name?" element={<PromoReview />} />
        <Route path="/notification-settings" element={<NotificationSettings />} />
        <Route path="/notifications/:tab?" element={<NotificationsArchive />} />
        <Route path="/received-transfers" element={<ReceivedTransfers />} />
        <Route path="/received-transfers/:id/details" element={<ReceivedTransferDetails />} />
        <Route path="/receive-manual-order/:id" element={<ManualSupplierOrderDetail />} />
        <Route path="/receive-automatic-order/:id" element={<ManualSupplierOrderDetail />} />
        <Route path="/map-order-csv" element={<MapOrderCsvDialog />} />
        <Route path="/suppliers/:id/details" element={<SupplierDetailsContainer />} />
        <Route path="/orders-admin/dispatch/:id" element={<DispatchContainer />} />
        <Route path="/suppliers-orders/:id/:redirectTo" element={<SupplierOrdersFormDetail />} />
        <Route path="/return-order" element={<ReturnOrder />} />
        <Route path="/return-order/review" element={<OrderReturnReview />} />
        <Route path="/suppliers-orders/:id" element={<SupplierOrdersFormDetail />} />
        <Route path="/suppliers-orders" element={<SupplierOrdersContainer />} />
        <Route path="/affiliate-order/:id/:param?" element={<AffiliateFormDetail />} />
        <Route path="/closed-order/:id" element={<ClosedOrder />} />
        <Route path="/map-supplier-csv" element={<MapSuppliercsvDialog />} />
        <Route path="/map-product-csv" element={<MapProductCsvDialog />} />
        <Route path="/inventory/:tab?/:status?/:dateFrom?/:dateTo?/:productId?/:productName?" element={<InventoryContainer />} />
        <Route path="/products/:id/details" element={<ProductDetails />} />
        <Route path="/map-product-reconcile-csv" element={<MapReconcilecsvDialog />} />
        <Route path="/csv-inventory-upload" element={<MapInventoryCSVDialog />} />
        <Route path="/business-setup" element={<BusinessSetupContainer />} />
        <Route path="/settings/outlets" element={<OutletsContainer />} />
        <Route path="/settings/connect-marketplace" element={<ConnectMarketplaceContainer />} />
        <Route path="/settings/sub-business" element={<SubBusinesses />} />
        <Route path="/settings/business" element={<BusinessDetails />} />
        <Route path="/settings/preferences" element={<PreferencesContainer />} />
        <Route path="/settings/desktop-setup" element={<DesktopSetupContainer />} />
        <Route path="/settings/users" element={<UsersContainer />} />
        {/* <Route path="/settings/users/details/:id" element={<SingleUserDetails />} /> */}
        <Route path="/settings/roles-permissions" element={<PermissionsContainer />} />
        {/* <Route path="/settings/" element={<BusinessDetails />} /> */}
        <Route path="/feedback" element={<Feedback />} />
        {/* <Route path="/reset_password/:uidb64/:token" element={<ResetPassword />} /> */}
        <Route path="/therapeutic-types" element={<TherapeuticTypes />} />
        <Route path="/therapeutic-type/:id/details" element={<TherapeuticTypesDetails />} />
        <Route path="/help-desk" element={<HelpDeskContainer />} />
        <Route path="/data-analysis" element={<DataAnalysis />} />
        <Route path="/data-analysis/:id" element={<SingleDataAnalysis />} />
        <Route path="/sc-dashboard" element={<ScDashboard />} />
        <Route path="/affiliate-dashboard" element={<AffiliateDashboard />} />
        <Route path="/return" element={<Return />} />
        <Route path="/manage-affiliates" element={<ManageAffiliates />} />
        <Route path="/manage-affiliates/:id/details" element={<AffiliateDetails />} />
        <Route path="/market-order" element={<NewOrderContainer />} />
        <Route path="/payments" element={<PaymentsContainer />} />
        <Route path="/downloads" element={<DownloadsContainer />} />
        <Route path="/track-order" element={<TrackOrderContainer />} />
        <Route path="/account-info" element={<AffiliateInfo />} />
        <Route path="/prescription/:id" element={<PrescriptionContainer />} />
        <Route path="/purchase-order" element={<PurchaseOrderContainer />} />
        <Route path="/manage-purchase-order" element={<ManagePurchaseOrderContainer />} />
        <Route path="/update-purchase-order/:id" element={<UpdatePurchaseOrderContainer />} />
        <Route path="/reports" element={<ReportsContainer />} />
        <Route path="uploads" element={<Uploads />} />
        <Route path="/manual-batches" element={<ManualBatchContainer />} />
        <Route path="/admin/preloaded-inventory" element={<PreloadedInventory />} />
        <Route path="/admin/business" element={<AdminBusiness />} />
        <Route path="/inbound-details-info/:inboundQueueOrderId/:orderId?" element={<SingleInboundDetails />} />
        <Route path="/low-on-stock" element={<LowOnStock />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/inbound-history" element={<InboundHistory />} />
        <Route path="/order-history-details/:id" element={<OrderHistoryDetailsContainer />} />
        <Route path="/order-history/:id" element={<InboundLog />} />
        <Route path="/newly-added-product" element={<NewlyAddedProduct />} />
        <Route path="/inbound-order-detail/:id/:orderId?" element={<InboundOrderDetailsContainer />} />
        <Route path="/return-order-detail/:id/:receiptNumber?" element={<InboundReturnDetails />} />
        <Route path="/sync-logs" element={<SyncLogComponent />} />

      </Routes>
      <Notification />
      <NotificationModal />
    </>
  );
};

export default NetworkDetector(App);
