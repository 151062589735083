import React, { useState } from 'react';
import { Button, DialogContent, Slide } from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  DialogContainer,
  DialogContentStyled,
  ReturnTable,
  ReturnTableData,
  ReturnTableHeader,
  ReturnTitle,
  ReturnProductFooter
} from './mapProductDialog.styles';
import ReturnMapProductReturnRow from './returnMapProductReturnRow';
import ReasonDialog from './reasonDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ReturnMapProductDialog = ({
  rows, openDialog, setOpenDialog, setSelectedRows, setSelected
}) => {
  const handleClose = () => {
    setOpenDialog(!openDialog);
  };

  const [openReasonModal, setOpenReasonModal] = useState(false);

  const handleChange = (e, prodId) => {
    const { value } = e?.target ?? '';
    const newValue = +value;

    if (value && newValue < 0) return;

    const updatedRows = rows.map((product) => {
      if (product.id === prodId) {
        if (newValue <= product.quantityReceived) {
          return {
            ...product,
            quantityToReturn: newValue,
          };
        }
      }
      return product;
    });
    setSelectedRows(updatedRows);
  };

  const handleIncrement = (prodId) => {
    const updatedRows = rows.map((product) => {
      if (product.id === prodId) {
        const increment = product.quantityToReturn + 1;
        if (increment <= product.quantityReceived) {
          return {
            ...product,
            quantityToReturn: increment,
          };
        }
      }
      return product;
    });
    setSelectedRows(updatedRows);
  };

  const handleDecrement = (prodId) => {
    const updatedRows = rows.map((product) => {
      if (product.id === prodId) {
        const decrement = product.quantityToReturn - 1;
        if (decrement > 0) {
          return {
            ...product,
            quantityToReturn: decrement,
          };
        }
      }
      return product;
    });
    setSelectedRows(updatedRows);
  };

  const renderRowComp = () => (
    rows.map((item, index) => (
      <ReturnMapProductReturnRow
        key={item.id}
        counter={index + 1}
        row={item}
        handleChange={handleChange}
        handleIncrement={handleIncrement}
        handleDecrement={handleDecrement}
      />
    ))
  );

  const reasonHandler = () => {
    setOpenDialog(false);
    setOpenReasonModal(true);
  }

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{
            position: 'absolute', top: '1.9rem', right: '1.5rem', cursor: 'pointer'
          }}
          onClick={handleClose}
        />
        <ReturnTitle>Return Products</ReturnTitle>
        <DialogContent sx={{ marginTop: '0', paddingX: 0 }}>
          <ReturnTable cellPadding={0} cellSpacing={0}>
            <ReturnTableHeader>
              <ReturnTableData>S/N</ReturnTableData>
              <ReturnTableData>Product Name</ReturnTableData>
              <ReturnTableData style={{ whiteSpace: 'nowrap' }}>Batch Number</ReturnTableData>
              <ReturnTableData style={{ whiteSpace: 'nowrap' }}>QTY Received</ReturnTableData>
              <ReturnTableData>QTY to Return</ReturnTableData>
            </ReturnTableHeader>
            {renderRowComp()}
          </ReturnTable>

          <ReturnProductFooter>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={reasonHandler}>
              Return
            </Button>
          </ReturnProductFooter>
        </DialogContent>
      </DialogContentStyled>
      <ReasonDialog openDialog={openReasonModal} setOpenDialog={setOpenReasonModal} rows={rows} setSelected={setSelected} />
    </DialogContainer>
  );
};

ReturnMapProductDialog.propTypes = {
  rows: PropTypes.instanceOf(Array).isRequired,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  setSelectedRows: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired
};

ReturnMapProductDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
};

export default ReturnMapProductDialog;
