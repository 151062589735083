import React, { useEffect, useState } from 'react';
import {
  TableBody, TableContainer, Table,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import {
  TableHeader,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
} from './returnDetails.styles';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import { LOW_OUT_OF_STOCK } from '../../../../queries/products';
import TablePagination from '../../../shared/tablePagination';
import CustomSearchField from '../../../shared/CustomSearchField';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';

const headers = [
  'SKU',
  'Product Name',
  'Dosage Strength',
  'Quantity Ordered',
  'Marketplace Price',
];

const initialState = {
  searchText: '',
  search: '',
};

const OrderHistoryDetails = () => {
  const [state, setState] = useState(initialState);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    searchText, search
  } = state;

  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}
    >
      {header}
    </TableHeader>
  ));

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, search: searchText });
    } else if (!searchText) {
      setState({ ...state, search: '' });
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const { loading, data } = useQuery(LOW_OUT_OF_STOCK, {
    variables: {
      search, pageCount, pageNumber
    },
    fetchPolicy: 'network-only'
  });

  const response = data?.lowOnOrOutOfStockProducts || [];
  const totalNumber = data?.lowOnOrOutOfStockProductsTotalNumber || 0;

  return (
    <PaperWrapper elevation={0}>
      <SearchFlex>
        <CustomSearchField
          name="search"
          value={searchText}
          placeholder="Search for products..."
          handleChange={(e) => handleSearch(e.target.value)}
          style={{ width: '100%', marginRight: '10px' }}
        />
      </SearchFlex>
      <TableGrid item container>
        {loading ? (
          <ProductsTableLoader />
        ) : (
          <TableContainer>
            <Table>
              <MainTableHead>
                {returnHeaders()}
              </MainTableHead>
              <TableBody>
                {
                response?.length
                  ? response?.map((item) => {
                    const idx = item?.id;
                    return <ReturnRow key={idx} row={item} />;
                  })
                  : (
                    <MainTableRow>
                      <BodyCell colspan={7} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                    </MainTableRow>
                  )
              }
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TableGrid>
      <FooterWrapper item container>
        {totalNumber > 0 && (
          <TablePagination
            total={totalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </FooterWrapper>
    </PaperWrapper>
  );
};

export default OrderHistoryDetails;
