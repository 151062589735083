import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import { ReturnTableRow, ReturnTableBodyData } from './mapProductDialog.styles';
import CustomInputBase from '../../../customComponents/customInputBase';
import { CaretDownIcon, CaretUpIcon } from '../../../../assets/svgs';

const ReturnMapProductReturnRow = ({
  row, mapHandler, counter, handleChange, handleIncrement, handleDecrement
}) => {
  const {
    id, productName, batchNo, quantityReceived, quantityToReturn
  } = row;

  return (
    <ReturnTableRow>
      <ReturnTableBodyData>{counter}</ReturnTableBodyData>
      <ReturnTableBodyData>{productName}</ReturnTableBodyData>
      <ReturnTableBodyData>{batchNo}</ReturnTableBodyData>
      <ReturnTableBodyData>{quantityReceived}</ReturnTableBodyData>
      <ReturnTableBodyData>
        <CustomInputBase
          type="number"
          placeholder="0"
          min={1}
          size="medium"
          value={quantityToReturn}
          onChange={(e) => handleChange(e, id)}
          endAdornment={(
            <InputAdornment position="end">
              <Grid item container direction="column">
                <CaretUpIcon
                  style={{
                    width: '1rem',
                    height: '1rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleIncrement(id)}
                />
                <CaretDownIcon
                  style={{
                    width: '1rem',
                    height: '1rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleDecrement(id)}
                />
              </Grid>
            </InputAdornment>
          )}
        />
      </ReturnTableBodyData>
    </ReturnTableRow>
  );
};

ReturnMapProductReturnRow.propTypes = {
  row: PropTypes.shape(Object),
  counter: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  // mapHandler: PropTypes.func.isRequired,
};

ReturnMapProductReturnRow.defaultProps = {
  row: {}
};

export default ReturnMapProductReturnRow;
