import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomNewTextField from '../shared/CustomNewTextField';
import CustomButton from '../customComponents/customButton';
import { FiltersGrid } from './dashboard.styles';
import { useStateValue } from '../../providers/stateProvider';

const DateFilter = ({
  state, handleFilterChange, handleSetFilter
}) => {
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  return (
    <Grid item container xs={12}>
      <FiltersGrid container item>
        <CustomNewTextField
          field={{ name: 'dateFrom', label: 'From', shrink: true }}
          state={state}
          handleChange={handleFilterChange}
          style={{ backgroundColor: '#FFFFFF', borderRadius: '17px' }}
        />
        <CustomNewTextField
          field={{ name: 'dateTo', label: 'To', shrink: true }}
          state={state}
          handleChange={handleFilterChange}
          style={{ backgroundColor: '#FFFFFF', borderRadius: '17px' }}
        />
        <CustomButton
          type="primary"
          style={{ width: '6.5rem', height: '2.95rem' }}
          onClick={handleSetFilter}
          disabled={!userPermissions?.includes('dashboard_set_filter')}
        >
          Set Filter
        </CustomButton>
      </FiltersGrid>

    </Grid>
  );
};

DateFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
};

DateFilter.defaultProps = {
  state: {},
};

export default DateFilter;
