import { gql } from '@apollo/client';

export const SIGNUP_MUTATION = gql`
  mutation createUser($email: String!, $mobile: String, $password: String!) {
    createUser(username: $email, mobileNumber: $mobile, password: $password) {
      message
      token
      user {
        id
        contacts
        status
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation loginUser(
    $loginData: String!
  ) {
    loginUser(
      loginData: $loginData
    ) {
      message
      token
      restToken
      requireFeedback {
        feedbackRequired
        orderId
        affiliateId
      }
      user {
        id
        businessuserSet {
          id
          permissions
          userPermissions
          role {
            id
            name
          }
          systemRole {
            name
          }
          business {
            id
            status
            isAdmin
          }
        }
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation logoutUser($authToken: String!, $restToken: String!) {
    logoutUser(authToken: $authToken, restToken: $restToken) {
      message
      status
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ResetPasswordMail($email: String!) {
    resetPasswordMail(email: $email) {
      message
      resetLink
    }
  }
`;

export const UPDATE_ROLE_BUSINESS_USER_AND_PERMISSIONS = gql`
  mutation updateBusinessUserRolePermission(
    $businessUserId: Int!
    $roleId: Int!
    $permissions: [Int]!
  ) {
    updateBusinessUserRolePermission(
      businessUserId: $businessUserId
      roleId: $roleId
      permissions: $permissions
    ) {
      message
    }
  }
`;

export const SWITCH_ROLE_MUTATION = gql`
  mutation switchRole($oldRole: String) {
    switchRole(oldRole: $oldRole) {
      message
      newRole
    }
  }
`;

export const SWITCH_STORE_MUTATION = gql`
  mutation switchStore($businessId: Int!) {
    switchStore(businessId: $businessId) {
      message
      business {
        id
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      user {
        id
        username
      }
      message
    }
  }
`;
