import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText,
  Slide,
  Grid,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  DialogContainer,
  CardTitle,
  DialogContentStyled,
  TitleDesc,
  ItemWrapper,
  ItemHeader,
  ItemDesc,
  LinkSvg,
  ConvertSvg,
  RocketSvg,
  ItemContainer,
  ItemHeaderDesc,
  Header, CopySvg
} from './setupFlow.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SetupFlow = ({ openDialog, toggleHandler }) => (
  <DialogContainer
    data-testid="product-dialog"
    open={openDialog}
    TransitionComponent={Transition}
    keepMounted
    onClose={toggleHandler}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContentStyled>
      <DialogContentText>
        <Grid style={{ marginTop: '1rem' }}>
          <Box display="flex" justifyContent="space-between">
            <CardTitle>OGApharmacy Offline POS Application</CardTitle>
            <Close
              fontSize="small"
              onClick={toggleHandler}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <TitleDesc>Explore this step-by-step guide to onboard yourself easily</TitleDesc>
        </Grid>
        <ItemContainer container>
          <RocketSvg />
          <Grid>
            <Header>Let’s Get You Started!</Header>
            <ItemHeaderDesc>Follow the simple procedure outlined below, and you&apos;ll be done before you know it.</ItemHeaderDesc>
          </Grid>
        </ItemContainer>
        <ItemWrapper container>
          <LinkSvg />

          <Grid style={{ width: '87%' }}>
            <ItemHeader>Download the Appropriate Version for your Device</ItemHeader>
            <ItemDesc>
              We’ve got different downloads for various devices! Take a moment to find the one that’s just right for yours.
            </ItemDesc>
          </Grid>
        </ItemWrapper>
        <ItemWrapper container>
          <CopySvg />
          <Grid style={{ width: '87%' }}>
            <ItemHeader>Install Downloaded Application</ItemHeader>
            <ItemDesc>
              After downloading, proceed with the installation. Upon successful installation, you will be presented with onboarding screens to guide you through the setup.
            </ItemDesc>
          </Grid>
        </ItemWrapper>
        <ItemWrapper container>
          <ConvertSvg />
          <Grid style={{ width: '87%' }}>
            <ItemHeader>Setup Completion</ItemHeader>
            <ItemDesc>
              Ready to set things up? Just accept the terms, enter your License Key, and add your business info—then you’ll be all set before you know it!
            </ItemDesc>
          </Grid>
        </ItemWrapper>
      </DialogContentText>
    </DialogContentStyled>
  </DialogContainer>
);

SetupFlow.propTypes = {
  openDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
};

SetupFlow.defaultProps = {
  openDialog: false
};

export default SetupFlow;
