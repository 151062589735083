import {
  Button,
  Grid, Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const TitleGridWrapper = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const OutletItems = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
`;

export const OutletContentWrapper = styled(Grid)`
  justify-content: space-between;
  flex-wrap: no-wrap;
  padding: 0 30px 10px;
`;

export const OutletWrapper = styled(Grid)`
  width: 100%;
  flex-direction: column;
  min-height: 73vh;
  margin-left:35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const OutletItem = styled(Grid)`
  margin: 0 20px;
`;

export const FooterGrid = styled(Grid)`
  display: flex;
  height: 75px;
  justify-content: flex-end;
  padding-left: 20px;
  align-items: center;
`;
export const OrderCountText = styled(Typography)`
  font-size: 22px;
  color: #4D4F5C;
  margin: 14px 0;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const BannerContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #ECF6FF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin: 25px auto;
  position: relative;
  overflow: hidden;
`;

export const Container = styled(Grid)`
`;

export const ContentWrapper = styled(Grid)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

`;

export const BackdropFilter = styled('div')`
  position: absolute;

  &.filter1 {
    right: 25%;
    width: 100px;
    height: 150px;
    background: #2ECC71;
    filter: blur(90px);
  }

  &.filter2 {
  right: -10px;
  bottom: -20px;
    width: 90px;
    height: 90px;
  background: #E74C3C;
  filter: blur(70px);

  }

  &.filter3 {
  right: 60px;
    width: 100px;
    top: 30px;
    height: 100px;
    background: #3498DB;
  filter: blur(70px);
  }
`;

export const TextContent = styled(Grid)`
  max-width: 50%;
  padding: 20px 30px;
`;

export const Title = styled(Typography)`
  font-size: 22px;
  color: #235A91;
  font-weight: 700;
  line-height: 27px;
`;

export const Subtitle = styled(Typography)`
  font-size: 12px;
  color: #303030;
  padding-top: 10px;
`;

export const StyledButton = styled(Button)`
  background-color: #235A91;
  color: white;
  font-weight: bold;
  padding: 0px 20px;
  border-radius: 6px;
  text-transform: none;
  margin-top: 15px;
  height: 40px;
`;

export const ImageContainer = styled(Grid)`

`;

export const LogWrapper = styled(Grid)`
  box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
`;

export const LogItem = styled(Grid)`
  display: flex;
  align-items: center;
  background: #F3F9FF;
  border-radius: 6px;
  padding: 20px;
  justify-content: space-between;
`;

export const LogHeader = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const LogTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  color: #303030;
  line-height: 24px;
`;

export const LogDesc = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #606060;
  line-height: 22px;
  padding-top: 10px;
`;

export const SubContainer = styled(Grid)`
box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;
background: #FFFFFF;
border-radius: 12px;
padding: 30px;
margin-top: 30px;
`;

export const AppTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  color: #303030;
  line-height: 24px;
  border-bottom: 1px solid #E7E8E9;
  padding-bottom: 2rem;
`;

export const CardItem = styled(Grid)`
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 30px;
  justify-content: space-between;
  box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;
  margin: 12px 0px;

`;

export const CardHeader = styled(Grid)`
  display: flex;
  gap: 15px;
`;

export const MacWrapper = styled(Grid)`
  display: grid;
  align-items: center;
  gap: 15px;
  grid-template-columns: auto auto;
`;

export const CardTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  color: #606060;
  line-height: 20px;
`;

export const CardDesc = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #606060;
  line-height: 20px;
  padding-top: 5px;
`;

export const DownloadButton = styled(Button)`
  border: .83px solid #235A91;
  font-size: 12px;
  font-weight: 600;
  color: #235A91;
  margin-top: 10px;
  padding: 7px 12px;
  border-radius: 7px;
  box-shadow: 0px 0.83px 1.66px 0px #1018280D;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const MacDownloadButton = styled(Button)`
  border: none;
  font-size: 12px;
  font-weight: 600;
  color: #235A91;
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 5px 0px;
`;

export const LicenseKeyWrapper = styled(Grid)`
  border-top: 0.5px solid #E7E8E9;
  margin-top: 20px;
  padding: 20px 0px;
`;

export const KeyWrapper = styled(Grid)`
display: flex;
  align-items: center;
  gap: 10px;
`;

export const Key = styled(Grid)`
display: flex;
  align-items: center;
  gap: 10px;
  width: 90%;
  padding: 12px 20px;
  border-radius: 8px;
  border: 1px solid #E7E8E9;
  margin: 25px 0;
`;

export const CopyButton = styled(Button)`
  border: 1px solid #235A91;
  font-size: 14px;
  font-weight: 600;
  color: #235A91;
  padding: 10px 15px;
  border-radius: 7px;
  box-shadow: 0px 1px 2px 0px #1018280D;
  display: flex;
  align-items: center;
  gap: 10px;
`;
