import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  BodyCell,
  MainTableRow
} from './returnRow.styles';
import ActionPopper from './actionPopper';
import ReasonReceiptDialog from '../../singleInboundDetails/reasonReceiptDialog';
import currencyFormatter from '../../../../../utils/currencyFormatter';

const ReturnRow = ({
  row
}) => {
  const {
    id,
    dateCreated,
    receiptNumber,
    returnedBy,
    amountOfProducts,
    totalValue
  } = row;
  const [action, setAction] = useState(null);
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  const navigate = useNavigate();

  const { fullName } = returnedBy || {};
  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };
  const handleRoute = () => navigate(`/return-order-detail/${id}/${receiptNumber}`);

  const handleAction = (event, type) => {
    if (type === 'details') {
      handleRoute();
    } else {
      setOpenReceiptDialog(true);
    }
    handleOptions(event);
  };

  return (
    <>
      <MainTableRow>
        <BodyCell>{moment(dateCreated).format('D MMM YYYY h:mm a')}</BodyCell>
        <BodyCell style={{ cursor: 'pointer' }} onClick={() => setOpenReceiptDialog(true)}>
          #
          {receiptNumber}
        </BodyCell>
        <BodyCell style={{ cursor: 'pointer' }}>{id}</BodyCell>
        <BodyCell>{amountOfProducts}</BodyCell>
        <BodyCell>{currencyFormatter(totalValue)}</BodyCell>
        <BodyCell>{fullName}</BodyCell>
        <BodyCell>
          <ActionPopper
            handleAction={handleAction}
            row={row}
          />
        </BodyCell>
      </MainTableRow>
      <ReasonReceiptDialog openDialog={openReceiptDialog} setOpenDialog={setOpenReceiptDialog} row={row} />

    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
