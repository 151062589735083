import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import currencyFormatter from '../../../../../../utils/currencyFormatter';

const ReturnRow = ({
  row, index
}) => {
  const { inboundQueueOrderProduct = {}, quantityReturned } = row || {};

  const {
    productName, batchNo, quantityReceived, packSize, uom, costPrice, expiryDate
  } = inboundQueueOrderProduct || {};
  const formattedExpiryDate = moment(expiryDate).format('MM/YY');

  return (
    <MainTableRow>
      <BodyCell>{index}</BodyCell>
      <BodyCell>{productName}</BodyCell>
      <BodyCell>{batchNo}</BodyCell>
      <BodyCell>{quantityReceived}</BodyCell>
      <BodyCell>{quantityReturned}</BodyCell>
      <BodyCell>{uom}</BodyCell>
      <BodyCell>{packSize}</BodyCell>
      <BodyCell>{currencyFormatter(costPrice)}</BodyCell>
      <BodyCell>{formattedExpiryDate}</BodyCell>

    </MainTableRow>

  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number
};

ReturnRow.defaultProps = {
  row: {},
  index: 0
};

export default ReturnRow;
