import React, { useState } from 'react';
import { ArrowForwardIosRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  TextContent, Subtitle, ImageContainer, Title, StyledButton, ContentWrapper, BackdropFilter, Container, BannerContainer,
  LogWrapper, LogItem, LogHeader, LogTitle, LogDesc, SubContainer, AppTitle, CardItem, CardHeader, CardDesc, CardTitle, DownloadButton, MacDownloadButton, MacWrapper,
  LicenseKeyWrapper, KeyWrapper, Key, CopyButton
} from './desktopSetup.styles';
import {
  WindowsIcon, SyncIcon, Linux, MacIcon, DownloadSvg, CopyIcon
} from '../../../assets/svgs';
import posPc from '../../../assets/images/posPc.png';
import SetupFlow from './setupFlow';
import { BUSINESS_LICENSES_KEY_QUERY } from '../../../queries/businesses';
import { useStateValue } from '../../../providers/stateProvider';

let environmentBucketBinaryFolder = `production.ext/${process.env.DESKTOP_APP_VERSION}`;
if (window.location.host.includes(".uat.")) environmentBucketBinaryFolder = "uat";
if (window.location.host.includes(".canary.")) environmentBucketBinaryFolder = "canary";
if (window.location.host.includes(".staging.")) environmentBucketBinaryFolder = "staging";
if (window.location.host.includes(".internal.")) environmentBucketBinaryFolder = `production/${process.env.DESKTOP_APP_VERSION}`;

const DESKTOP_APP_LINUX_DOWNLOAD_URL = `https://storage.googleapis.com/lifestores_binaries/pos/desktop/${environmentBucketBinaryFolder}/pharmiq-pos-${process.env.DESKTOP_APP_VERSION}-linux_x64.deb`;
const DESKTOP_APP_WINDOWS_DOWNLOAD_URL = `https://storage.googleapis.com/lifestores_binaries/pos/desktop/${environmentBucketBinaryFolder}/pharmiq-pos-${process.env.DESKTOP_APP_VERSION}-win_x64-installer.exe`;
const DESKTOP_APP_MAC_INTEL_DOWNLOAD_URL = `https://storage.googleapis.com/lifestores_binaries/pos/desktop/${environmentBucketBinaryFolder}/pharmiq-pos-${process.env.DESKTOP_APP_VERSION}-mac_x64-installer.app.zip`;
const DESKTOP_APP_MAC_APPLE_CHIP_DOWNLOAD_URL = `https://storage.googleapis.com/lifestores_binaries/pos/desktop/${environmentBucketBinaryFolder}/pharmiq-pos-${process.env.DESKTOP_APP_VERSION}-mac_arm64-installer.app.zip`;

const DesktopAppSetup = () => {
  const navigate = useNavigate();
  const [{
    user: {
      businessId
    }
  }] = Object.values(useStateValue());
  const [openDialog, setOpenDialog] = useState(false);
  const toggleHandler = () => {
    setOpenDialog(!openDialog);
  };

  const {
    data,
  } = useQuery(BUSINESS_LICENSES_KEY_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: businessId,
    },
  });

  const handleCopyLinkCode = () => {
    navigator.clipboard.writeText(data?.businessLicenseKey?.licenseKey)
      .then(() => {
        toast.success('License Key copied to clipboard!');
      })
      .catch(() => {
        toast.error('Failed to copy License Key code!');
      });
  };

  const handleDownload = (url) => {
    window.open(`${url}`, '_blank');
  };

  return (
    <>
      <Container item container justifyContent="flex-end" xs={9}>
        <LogWrapper>
          <LogItem>
            <LogHeader>
              <SyncIcon style={{ fontSize: '4rem' }} />
              <Grid>
                <LogTitle>Offline Synchronization Log</LogTitle>
                <LogDesc>See all the times your application synced online with offline and vice-versa</LogDesc>
              </Grid>
            </LogHeader>
            <ArrowForwardIosRounded onClick={() => navigate('/sync-logs')} style={{ fontSize: '1.5rem', cursor: 'pointer' }} />
          </LogItem>
        </LogWrapper>
        <SubContainer>
          <AppTitle>Desktop App</AppTitle>
          <BannerContainer>
            <BackdropFilter className="filter1" />
            <BackdropFilter className="filter2" />
            <BackdropFilter className="filter3" />
            <ContentWrapper>
              <TextContent>
                <Title>
                  Enjoy Our POS Application
                  <br />
                  Without Internet Connection
                </Title>
                <Subtitle>
                  With OGApharmacy POS, manage sales, sales history, and returns without an internet connection. Enjoy seamless transactions in offline mode, with data sync when you&apos;re back online.
                </Subtitle>
                <StyledButton variant="contained" onClick={toggleHandler}>How to Setup</StyledButton>
              </TextContent>
              <ImageContainer>
                <img src={posPc} alt="Laptop" width={400} />
              </ImageContainer>
            </ContentWrapper>
          </BannerContainer>
          <CardItem>
            <CardHeader>
              <WindowsIcon style={{ fontSize: '2.5rem' }} />
              <Grid>
                <CardTitle>Desktop App for Windows</CardTitle>
                <CardDesc>Support Windows 10 (64 bit) and above</CardDesc>
              </Grid>
            </CardHeader>

            <DownloadButton onClick={() => handleDownload(DESKTOP_APP_WINDOWS_DOWNLOAD_URL)}>
              <DownloadSvg />
              Download
            </DownloadButton>

          </CardItem>

          <MacWrapper>

            <CardItem item>
              <CardHeader>
                <MacIcon style={{ fontSize: '2.5rem' }} />
                <Grid>
                  <CardTitle>Desktop App for MacOS</CardTitle>
                  <CardDesc>Support macOS Intel</CardDesc>
                  <MacDownloadButton onClick={() => handleDownload(DESKTOP_APP_MAC_INTEL_DOWNLOAD_URL)}>
                    <DownloadSvg />
                    Download
                  </MacDownloadButton>
                </Grid>
              </CardHeader>

            </CardItem>

            <CardItem item>
              <CardHeader>
                <MacIcon style={{ fontSize: '2.5rem' }} />
                <Grid>
                  <CardTitle>Desktop App for MacOS</CardTitle>
                  <CardDesc>Support macOS Apple Sillicon</CardDesc>
                  <MacDownloadButton onClick={() => handleDownload(DESKTOP_APP_MAC_APPLE_CHIP_DOWNLOAD_URL)}>
                    <DownloadSvg />
                    Download
                  </MacDownloadButton>
                </Grid>
              </CardHeader>
            </CardItem>
          </MacWrapper>
          <CardItem>
            <CardHeader>
              <Linux style={{ fontSize: '2.5rem' }} />
              <Grid>
                <CardTitle>Desktop App for Linux</CardTitle>
                <CardDesc>Linux Laptop</CardDesc>
              </Grid>
            </CardHeader>
            <DownloadButton onClick={() => handleDownload(DESKTOP_APP_LINUX_DOWNLOAD_URL)}>
              <DownloadSvg />
              Download
            </DownloadButton>
          </CardItem>
          <LicenseKeyWrapper>
            <LogTitle>License Key</LogTitle>
            <LogDesc>View and copy your desktop app license key </LogDesc>
            <KeyWrapper>
              <Key>{data?.businessLicenseKey?.licenseKey || 'N/A'}</Key>
              <CopyButton onClick={handleCopyLinkCode}>
                <CopyIcon />
                Copy
              </CopyButton>
            </KeyWrapper>
          </LicenseKeyWrapper>
        </SubContainer>
      </Container>
      <SetupFlow openDialog={openDialog} toggleHandler={toggleHandler} />

    </>
  );
};

export default DesktopAppSetup;
