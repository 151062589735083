import { ArrowDropDown } from '@mui/icons-material';
import { TableRow, TableCell } from '@mui/material';
import styled, { css } from 'styled-components';

export const MainTableRow = styled(TableRow)`
  :nth-child(even) {
    background: #f3f9ff;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

const dangerStyles = css`
  background-color: #ff0d10;
`;

const successStyles = css`
  background-color: #01b588;
`;

const progressStyles = css`
  background-color: #f29053;
`;

export const DropDownArrow = styled(ArrowDropDown)`
  font-size: 2rem;
  margin-left: 2rem;
  color: #235A91;
`;

export const FlexField = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  text-transform: capitalize;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'FAILED':
      return dangerStyles;
    case 'COMPLETED':
      return successStyles;
    case 'PROCESSING':
      return progressStyles;
    default:
      return dangerStyles;
  }
};

export const StatusColor = styled('div')`
  width: 0.563rem;
  height: 0.563rem;
  border-radius: 50%;
  text-transform: capitalize;

  ${getStyles}
`;

export const StatusContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const OrderListButton = styled('button')`
  border: 1px solid #235a91;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: 600;
  color: #235A91;
  background-color:  #fff;
`;
