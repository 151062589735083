import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  BodyCell, MainTableRow, DropDownArrow, FlexField
} from './returnRow.styles';
import StatusDropdown from './statusDropdown';
import CustomInputBase from '../../customComponents/customInputBase';
import { blockInvalidChar } from '../../../utils/mobileCheck';

const ReturnRow = ({
  row, sn, categories, changeSellingPrice, changePriceFormat
}) => {
  const {
    id, sku, productName, supplier, quantity, costPrice, sellingPrice, pricingCategory, priceInUse
  } = row;

  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [dotsPrinceInUseButtonEl, setPriceInUseDotsButtonEl] = useState(null);

  const priceCategoryOptions = categories?.allCategories?.map((item) => ({ name: item.categoryName, disabled: false }));
  const options = [
    { name: 'Fixed', disabled: false },
    { name: 'Automatic', disabled: false },
  ];

  const handleStatusChange = (value, type) => {
    changePriceFormat(value, row, type);
    setDotsButtonEl(null);
    setPriceInUseDotsButtonEl(null);
  };

  return (
    <>
      <MainTableRow>
        <BodyCell style={{ paddingLeft: '1.2rem' }}>{sn}</BodyCell>
        <BodyCell>{sku}</BodyCell>
        <BodyCell>{productName}</BodyCell>
        <BodyCell>{supplier}</BodyCell>
        <BodyCell>{quantity}</BodyCell>
        <BodyCell>{`₦ ${costPrice}`}</BodyCell>
        <BodyCell>
          <CustomInputBase
            value={sellingPrice}
            variant="filled"
            size="small"
            step={0.01}
            type="number"
            onKeyDown={blockInvalidChar}
            onChange={(e) => changeSellingPrice(e, id)}
            disabled={priceInUse !== 'Fixed'}
            style={{ width: '150px' }}
          />
        </BodyCell>
        <BodyCell>
          <Grid
            item
            container
            onClick={(e) => setPriceInUseDotsButtonEl(e.currentTarget)}
          >
            <FlexField>
              {pricingCategory}
              <DropDownArrow />
            </FlexField>
          </Grid>
        </BodyCell>
        <BodyCell>
          <Grid
            item
            container
            onClick={(e) => setDotsButtonEl(e.currentTarget)}
          >
            <FlexField>
              {priceInUse}
              <DropDownArrow />
            </FlexField>
          </Grid>
        </BodyCell>
      </MainTableRow>
      <StatusDropdown
        dotsButtonEl={dotsButtonEl}
        setDotsButtonEl={setDotsButtonEl}
        options={options}
        action={handleStatusChange}
        type="priceInUse"
      />
      <StatusDropdown
        dotsButtonEl={dotsPrinceInUseButtonEl}
        setDotsButtonEl={setPriceInUseDotsButtonEl}
        options={priceCategoryOptions}
        action={handleStatusChange}
        type="category"
      />
    </>
  );
};
ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  categories: PropTypes.instanceOf(Array).isRequired,
  sn: PropTypes.number.isRequired,
  changeSellingPrice: PropTypes.func.isRequired,
  changePriceFormat: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
