import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BodyCell, MainTableRow, OrderListButton } from './returnRow.styles';

const ReturnRow = ({ row }) => {
  const navigate = useNavigate();
  const {
    id, dateSent, orderListProducts, mpAccount
  } = row;
  const handleRoute = () => {
    navigate(`/order-history-details/${id}`);
  };
  return (
    <MainTableRow>
      <BodyCell>{dateSent ? moment(dateSent).format('DD MMM YYYY h:mm a') : 'N/A'}</BodyCell>
      <BodyCell>{id}</BodyCell>
      <BodyCell>{orderListProducts.length}</BodyCell>
      <BodyCell>{mpAccount ? mpAccount?.name : 'N/A'}</BodyCell>
      <BodyCell>
        <OrderListButton onClick={() => navigate(id)}>View products</OrderListButton>
      </BodyCell>
    </MainTableRow>
  );
};
ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
