import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { Button } from '@mui/material';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import ProductInfo from './productInfo';
import ProductActionTimeline from './productActionTimeline';
import ProductBatchList from './productBatchList';
import GoToTop from '../../customComponents/scrollToTop';
import {
  OrderGridContainer, Back, TitleTextGridContainer, PrimaryTitle,
} from './productDetailsContainer.style';
import { useStateValue } from '../../../providers/stateProvider';
import IndividualProductDialog from '../../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../../providers/reducers/product/productTypes';

import { PRODUCT_DETAILS_QUERY } from '../../../queries/products';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { userCanUpdateProduct } from '../../../providers/reducers/user/userUtils';
import ConfirmDialog from '../../shared/confirmDialog';
import StoresDialog from '../../shared/stores/storesDialog';
import { usePreferencePermissionProductBatch } from '../../../utils/funcs';
import AppToast from '../../shared/appToast';

// confirm dialog initial state
const confirmDialogInitialState = {
  title: '',
  desc: '',
  options: [
    'No',
    'Yes'
  ]
};

const ProductDetailsContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [confirmDialogState, setConfirmDialogState] = useState(confirmDialogInitialState);
  const [storeIds, setStoreIds] = useState([]);
  const [storeConfirmDialog, setStoreConfirmDialog] = useState(false);
  const [openStoreModal, setOpenStoreModal] = useState(false);
  const [confirmStoreStatus, setConfirmStoreStatus] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: '',
    description: ''
  });

  const {
    loading, error, data, refetch
  } = useQuery(PRODUCT_DETAILS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  const [{ user, product: { addProductDialogOpen } }, dispatch] = Object.values(useStateValue());
  const {
    businessPreference, platform, allowedPermissionsMap: { userPermissions }
  } = user;

  const allowed = usePreferencePermissionProductBatch(businessPreference);

  if (loading) return <Loader />;
  // const isChainManager = role === 'chain-manager';
  if (error) return error;
  const {
    product = {}
  } = data || {};
  const rows = new Product(product, platform);

  // Update erpVersion object
  const manufacturer = product?.manufacturer?.name;
  const erpProductVersion = { ...rows, manufacturer };

  // const { isAVersion } = rows.erpVersion;
  const { brandName } = rows;

  const toggleIndividualDialog = () => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: erpProductVersion, refetch }
    });
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const handleStore = () => {
    if (!allowed) {
      setOpenToast(true);
      setToastMessage({
        title: 'Product Creation Disabled!',
        description: 'Oops! You can\'t create products at this time. Please reach out to your central store for authorization.'
      });
      return;
    }
    if (userPermissions?.includes('cm_dashboard_enable_product_sync_to_stores')) {
      setConfirmDialogState({
        ...confirmDialogState,
        title: 'Enable Cross-Store Product Creation',
        desc: 'Clicking yes, means that the product will be created across all stores with unified pricing.',
        options: ['No, Proceed', 'Yes, Proceed']
      });
      setStoreConfirmDialog(true);
    } else {
      toggleIndividualDialog();
      setStoreIds([]);
    }
  };

  if (confirmStoreStatus) {
    setOpenStoreModal(true);
    setStoreConfirmDialog(false);
    // Set confirmation status to false to avoid infinite loop
    setConfirmStoreStatus(false);
    // set selected stores back to empty array
    setStoreIds([]);
  }

  const handleDeclineConfirmation = () => {
    toggleIndividualDialog();
    setStoreIds([]);
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <Back onClick={() => navigate(-1)}>
          <ArrowBackIos fontSize="small" />
          back
        </Back>
        <TitleTextGridContainer>
          <PrimaryTitle>{brandName}</PrimaryTitle>
          { userCanUpdateProduct(user) && (
            <Button
              variant="contained"
              style={{ width: '10rem', height: '2.6rem' }}
              onClick={handleStore}
              disabled={!userPermissions?.includes('inventory_edit')}
            >
              Edit Product
            </Button>
          ) }
        </TitleTextGridContainer>
        <ProductInfo info={erpProductVersion} refetch={refetch} />
        <ProductBatchList info={rows} refetch={refetch} />
        <ProductActionTimeline id={id} />
        {rows.length ? <GoToTop /> : ''}
        <IndividualProductDialog
          dialogOpen={addProductDialogOpen}
          closeDialog={() => toggleIndividualDialog()}
          businessState={rows}
          details
          storeIds={storeIds}
          setStoreIds={setStoreIds}
        />
        <ConfirmDialog
          openDialog={storeConfirmDialog}
          setOpenDialog={setStoreConfirmDialog}
          title={confirmDialogState.title}
          desc={confirmDialogState.desc}
          options={[confirmDialogState.options[0], confirmDialogState.options[1]]}
          setStatus={setConfirmStoreStatus}
          declineConfirmation
          declineConfirmationAction={handleDeclineConfirmation}
        />
        <StoresDialog
          openDialog={openStoreModal}
          setOpenDialog={setOpenStoreModal}
          storeIds={storeIds}
          actionType="individual_prod"
          setStoreIds={setStoreIds}
          toggleDialog={toggleIndividualDialog}
        />
      </OrderGridContainer>
      <AppToast openToast={openToast} setOpenToast={setOpenToast} toastMessage={toastMessage} />

    </MainContent>
  );
};

export default ProductDetailsContainer;
