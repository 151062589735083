import React from 'react';
import SideNav from '../shared/sideNav/sideNav';
import MainContent from '../../customComponents/mainContent';
import TitleGrid from './titleGrid';
import {
  TitleGridWrapper, OutletContentWrapper
} from './desktopSetup.styles';
import DesktopAppSetup from './desktopSetup';

const DesktopSetupContainer = () => (
  <MainContent>
    <TitleGridWrapper>
      <TitleGrid />
    </TitleGridWrapper>
    <OutletContentWrapper container spacing={3}>
      <SideNav />
      <DesktopAppSetup />
    </OutletContentWrapper>
  </MainContent>
);

export default DesktopSetupContainer;
