import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import currencyFormatter from '../../../../../../utils/currencyFormatter';

const ReturnRow = ({
  row, index
}) => {
  const {
    dateInbounded,
    productName,
    quantityInbound,
    uom,
    packSize,
    batchNo,
    quantityReceived,
    expiryDate,
    costPrice
  } = row;

  const renderDate = () => moment(dateInbounded).format('DD MMM YYYY h:mm A');
  const formattedExpiryDate = moment(expiryDate).format('MM/YY');
  return (
    <MainTableRow>
      <BodyCell>{index + 1 }</BodyCell>
      <BodyCell>{renderDate()}</BodyCell>
      <BodyCell>{productName}</BodyCell>
      <BodyCell>{batchNo}</BodyCell>
      <BodyCell>{quantityReceived}</BodyCell>
      <BodyCell>{quantityInbound}</BodyCell>
      <BodyCell>{uom}</BodyCell>
      <BodyCell>{packSize}</BodyCell>
      <BodyCell>{currencyFormatter(costPrice)}</BodyCell>
      <BodyCell>{formattedExpiryDate}</BodyCell>
    </MainTableRow>

  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number
};

ReturnRow.defaultProps = {
  row: {},
  index: 0
};

export default ReturnRow;
