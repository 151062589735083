import React from 'react';
import {
  DialogContentText,
  Slide,
  Grid,
  Typography,
  TableContainer,
  Box,
  Button
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { PrintOutlined, EmailRounded, Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { OgaToken } from '../../../../../graphql/token';
import { SEND_RECEIPT_VIA_MAIL } from '../../../../../mutations/sales';
import currencyFormatter from '../../../../../utils/currencyFormatter';
import { JSONParse } from '../../../../../utils/json';
import {
  CDialog,
  CardTitle,
  DialogContentStyled,
  Hbg,
  HbgSmall,
  CustomerDetails,
  CustomerDetail,
  DefaultTable,
  DefaultTrHead,
  DefaultTd,
  DefaultTr,
  DefaultTdBody,
  Separator,
  CustomerDetailLt,
  ReceiptFooter,
  SmallText,
} from '../return-details.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function SalesReceipt({
  cart,
  sale,
  saleReturnData,
  customer,
  openDialog,
  openSalesReceiptHandler
}) {
  const { businessUser, receiptNumber } = saleReturnData;
  const date = new Date().toLocaleDateString();
  const { business, user } = businessUser;
  const { contacts = '' } = business || {};
  const { mobile_number: mobileNumber } = JSONParse(contacts) || {};
  const { last_name: lastName, first_name: firstName } = JSONParse(user?.profile) || {};

  const {
    sale: {
      discount,
      totalToPay,
      changeDue,
      total,
      meta
    }
  } = sale;

  let { sale_vat: { vat_percent = 0, vat_total = 0 } = {} } = JSONParse(meta) ?? {};

  const getTime = () => {
    const dt = new Date();
    let hours = dt.getHours();
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = dt.getMinutes();
    return `${hours}:${minutes} ${AmOrPm}`;
  };

  const handlePrint = () => {
    window.open(
      `${OgaToken.SERVER_LINK}download_receipt/${receiptNumber}.pdf/sales`,
      '_blank'
    );
  };

  const getDiscount = () => {
    const result = discount / (total / 100);
    return result || 0;
  };

  const getItemPrice = (qty, price) => qty * price;
  const [sendReceiptViaMail] = useMutation(SEND_RECEIPT_VIA_MAIL);

  const handleEmail = () => {
    sendReceiptViaMail({
      variables: {
        receiptNumber
      }
    }).then(() => {
      toast.success('Receipt sent to customer mail successfully');
    }).catch((err) => {
      toast.error(err.message);
    });
  };

  return (
    <div>
      <CDialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={openSalesReceiptHandler}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentStyled>
          <DialogContentText>
            <Grid style={{ marginTop: '1rem' }}>
              <Box display="flex" justifyContent="space-between">
                <CardTitle>Sales Receipt</CardTitle>
                <Close
                  fontSize="small"
                  onClick={openSalesReceiptHandler}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Grid>
            <Hbg>
              <Typography style={{ fontWeight: 'bold' }}>
                Receipt #
                {receiptNumber}
              </Typography>
              <HbgSmall>
                {date}
                {' '}
                {getTime()}
              </HbgSmall>
            </Hbg>
            <CustomerDetails>
              <CustomerDetail>Customer Name:</CustomerDetail>
              <CustomerDetail>{(customer && customer?.name) || 'N/A'}</CustomerDetail>
            </CustomerDetails>
            <CustomerDetails>
              <CustomerDetail>Phone Number:</CustomerDetail>
              <CustomerDetail>{mobileNumber}</CustomerDetail>
            </CustomerDetails>
            <TableContainer style={{ marginTop: '1rem' }}>
              <DefaultTable>
                <DefaultTrHead>
                  <DefaultTd>Product Name</DefaultTd>
                  <DefaultTd>Qty</DefaultTd>
                  <DefaultTd>Price</DefaultTd>
                </DefaultTrHead>

                {cart.map((item, idx) => (item.missedSale ? null : (
                  <DefaultTr key={idx}>
                    <DefaultTdBody>{item.brandName}</DefaultTdBody>
                    <DefaultTdBody>
                      1 x
                      {' '}
                      {item.quantity}
                    </DefaultTdBody>
                    <DefaultTdBody style={{ whiteSpace: 'nowrap' }}>
                      {currencyFormatter(getItemPrice(item.quantity, item.itemPrice))}
                    </DefaultTdBody>
                  </DefaultTr>
                )))}

                <DefaultTr>
                  <DefaultTdBody>Sub Total</DefaultTdBody>
                  <DefaultTdBody />
                  <DefaultTdBody>
                    {currencyFormatter(total)}
                  </DefaultTdBody>
                </DefaultTr>
                <DefaultTr>
                  <DefaultTdBody>
                    {getDiscount()}
                    % Discount
                  </DefaultTdBody>
                  <DefaultTdBody />
                  <DefaultTdBody>
                    {currencyFormatter(discount)}
                  </DefaultTdBody>
                </DefaultTr>
              </DefaultTable>
              <Separator />
              <CustomerDetails>
                <CustomerDetail>Total Before VAT</CustomerDetail>
                <CustomerDetail>
                  {currencyFormatter(total)}
                </CustomerDetail>
              </CustomerDetails>
              <CustomerDetails>
                <CustomerDetail>
                  VAT  (
                  {`${vat_percent}%`}
                  )
                </CustomerDetail>
                <CustomerDetail>
                  {currencyFormatter(vat_total)}
                </CustomerDetail>
              </CustomerDetails>
              <CustomerDetails>
                <CustomerDetailLt>Change Due</CustomerDetailLt>
                <CustomerDetailLt>{ currencyFormatter(changeDue) }</CustomerDetailLt>
              </CustomerDetails>
              <CustomerDetails>
                <CustomerDetail>Payment Method</CustomerDetail>
              </CustomerDetails>
              <CustomerDetails>
                <CustomerDetailLt>Cash</CustomerDetailLt>
                <CustomerDetail>{currencyFormatter(totalToPay)}</CustomerDetail>
              </CustomerDetails>
              <Separator />
              <ReceiptFooter>
                <SmallText>Your Cashier Today</SmallText>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', padding: '.5rem 0' }}
                >
                  {firstName ? `${firstName} ${lastName}` : user.username}
                </Typography>
                <SmallText>
                  <i>Thank You For Shopping With Us. Please Come Again.</i>
                </SmallText>
              </ReceiptFooter>

              <Separator />

              <Grid
                style={{
                  display: 'flex',
                  gap: '.3rem',
                  marginTop: '2rem',
                  padding: '0 2rem'
                }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Button variant="outlined" onClick={openSalesReceiptHandler}>
                  Close Transaction
                </Button>
                <Button variant="contained" style={{ background: '#78AADA' }} onClick={handleEmail}>
                  <EmailRounded
                    fontSize="small"
                    style={{ marginRight: '.5rem' }}
                  />
                  <span>Send To Email</span>
                </Button>
                <Button variant="contained" onClick={handlePrint}>
                  <PrintOutlined
                    fontSize="small"
                    style={{ marginRight: '.5rem' }}
                  />
                  <span>Print Receipt</span>
                </Button>
              </Grid>
            </TableContainer>
          </DialogContentText>
        </DialogContentStyled>
      </CDialog>
    </div>
  );
}

SalesReceipt.propTypes = {
  cart: PropTypes.instanceOf(Object).isRequired,
  saleReturnData: PropTypes.instanceOf(Object).isRequired,
  sale: PropTypes.instanceOf(Object).isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  openDialog: PropTypes.isRequired,
  openSalesReceiptHandler: PropTypes.isRequired
};
