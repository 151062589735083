import {
  TableCell, Grid, Button, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;
  white-space: normal;
  overflow-wrap: break-word;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1) {
      width: 6%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 32%;
    }
    &:nth-child(5) {
      width: 10%;
    }
    &:nth-child(4), &:nth-child(6) {
      width: 15%;
    }
    &:nth-child(7) {
      width: 2%;
    }
  }
`;

export const ActionButton = styled(Button)`
  width: 170px;
  height: 45px;
  background: #303030;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;

  &:hover {
    background-color: #969a9f;
    color: #303030;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;

export const TextWrapper = styled(Typography)`
  padding-left: 1rem;
`;

const PendingStyles = css`
  background-color: #F29053;
`;

const CompletedStyles = css`
  background-color: #00B588;
`;
const FailedStyles = css`
  background-color: #FF0F0F;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'COMPLETED':
      return CompletedStyles;
    case 'FAILED':
      return FailedStyles;
    default:
      return PendingStyles;
  }
};

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`;

export const Status = styled('div')`
  border-radius: 50%;
  width: 9px;
  height: 9px;

  ${getStyles}
`;
