import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DivFullWidth = styled('div')`
  width: 100%;
`;

export const TableGrid = styled(Grid)`
  margin: 0.5rem 0;
  overflow-x: auto;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px;
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
  padding: 0 4rem 4rem;
  overflow-x: hidden;
  margin-bottom: 1rem;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
  margin-bottom: 2rem;
`;

export const MainTableHead = styled(TableHead)``;

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const TableHeader = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #f3f9ff;
  padding: 16px 10px;
  border: 0;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  color: #606060;
  padding-top: 8px;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;
