import React, { useState } from 'react';
import {
  TableBody, TableContainer, Table, Button,
  Grid
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  TableHeader,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
} from './styles';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import CustomDateRanges from '../../../../shared/customDateRanges/customDateRanges';
import { EXPORT_OREDER_LOG_MUTATION } from '../../../../../mutations/reports';
import TablePagination from '../../../../shared/tablePagination';
import CustomSearchField from '../../../../shared/CustomSearchField';
import ProductsTableLoader from '../../../../customComponents/loaders/productsTableLoader';
import { INBOUND_QUEUE_ORDERS } from '../../../../../queries/orders';
import useSearchParamsState from '../../../../helpers/ulrSearchParams';

const headers = [
  'Date Received',
  'Order ID',
  'Amount of Products',
  'Total Order Value',
  'Marketplace Account',
];

const initialState = {
  searchText: '',
  search: '',
  dateFrom: Date,
  dateTo: Date
};

const InboundOrderLog = () => {
  const [state, setState] = useState(initialState);
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [search, setSearch] = useSearchParamsState('search', '');

  const {
    searchText, dateFrom, dateTo
  } = state;

  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}
    >
      {header}
    </TableHeader>
  ));

  const handleSearch = (text) => {
    setSearch(text);
  };

  const handleSubmit = (dateRange) => {
    const { dateFrom: dFrom, dateTo: dTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(dFrom).format('YYYY-MM-DD'),
      dateTo: moment(dTo).format('YYYY-MM-DD')
    }));
  };

  const variables = {
    pageCount,
    pageNumber,
    dateFrom,
    dateTo,
    history: true,
    ...(search.length >= 1 ? { orderId: search } : { orderId: '' }),
  };

  const {
    loading, error, data
  } = useQuery(INBOUND_QUEUE_ORDERS, {
    fetchPolicy: 'no-cache',
    variables
  });

  const [exportMutation, { loading: exportLoading }] = useMutation(EXPORT_OREDER_LOG_MUTATION);
  const handleDownloadCSV = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    exportMutation({
      variables: {
        type: 'download_inbound_order_logs', name: `download_inbound_order_logs${randomNum}`, search, dateFrom, dateTo
      }
    })
      .then(({ data: exportData }) => {
        const { message } = exportData?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };
  if (error) return <div>{error.message}</div>;

  const { businessInboundQueueOrders = [], businessInboundQueueOrdersTotalNumber = 0 } = data || {};

  return (
    <PaperWrapper elevation={0}>
      <SearchFlex>
        <CustomSearchField
          name="search"
          value={search}
          placeholder="Search by order id..."
          handleChange={(e) => handleSearch(e.target.value)}
          style={{ width: '65%', marginRight: '5px' }}
        />
        <Grid item xs={2} style={{ width: '20%' }}>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            xs={12}
            styles={{
              height: '47px',
              width: '105%',
              border: '1px solid #bababa',
              fontSize: '12px'
            }}
          />
        </Grid>
        <Button
          onClick={handleDownloadCSV}
          disabled={exportLoading}
          variant="outlined"
          style={{ width: '15%', marginLeft: '5px' }}
        >
          {exportLoading ? 'loading...' : 'Export CSV'}
        </Button>
      </SearchFlex>
      <TableGrid item container>
        {loading ? (
          <ProductsTableLoader />
        ) : (
          <TableContainer>
            <Table>
              <MainTableHead>
                {returnHeaders()}
              </MainTableHead>
              <TableBody>
                {
                businessInboundQueueOrders?.length
                  ? businessInboundQueueOrders?.map((item) => {
                    const idx = item?.id;
                    return <ReturnRow key={idx} row={item} />;
                  })
                  : (
                    <MainTableRow>
                      <BodyCell colspan={7} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                    </MainTableRow>
                  )
              }
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TableGrid>
      <FooterWrapper item container>
        {businessInboundQueueOrdersTotalNumber > 0 && (
          <TablePagination
            total={businessInboundQueueOrdersTotalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </FooterWrapper>
    </PaperWrapper>
  );
};

export default InboundOrderLog;
