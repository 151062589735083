import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  FiltersGrid, FilterContainer, ButtonOutlined
} from './inboundsFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import { EXPORT_INBOUND_QUEUE_ORDERS } from '../../../mutations/reports';

const InboundsFilter = ({
  search, setSearch, setState, state
}) => {
  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD')
    }));
  };

  const [exportMutation, { loading }] = useMutation(EXPORT_INBOUND_QUEUE_ORDERS);
  const handleDownloadCSV = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    exportMutation({
      variables: {
        type: 'download_inbound_queue_orders', name: `inbound_queue_order${randomNum}`, orderId: search, dateFrom: state.dateFrom, dateTo: state.dateTo
      }
    })
      .then(({ data: exportData }) => {
        const { message } = exportData?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <FilterContainer item container xs={12}>
      <FiltersGrid container item justifyContent="flex-end">
        <Grid item container style={{ width: '73.5%' }}>
          <CustomSearchField
            name="search"
            value={search}
            handleChange={(e) => setSearch(e.target.value)}
            fullWidth
            placeholder="Search"
          />
        </Grid>
        <Grid item>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            xs={12}
            styles={{ height: '47px', width: '15rem', border: '1px solid #bababa' }}
          />
        </Grid>
        <ButtonOutlined onClick={handleDownloadCSV} disabled={loading}>
          { loading ? 'loading ...' : 'Export CSV'}
        </ButtonOutlined>
      </FiltersGrid>
    </FilterContainer>
  );
};

InboundsFilter.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.isRequired,
  setSearch: PropTypes.isRequired,
  search: PropTypes.string.isRequired,
};

export default InboundsFilter;
