import React, { useState } from 'react';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomButton from '../../customComponents/customButton';
import {
  CDialog, DialogWrapper, SubText, HeaderText, DialogHeader, DialogContent, DialogActions,
  CloseIcon, CustomInputBase
} from './rejectReasonDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const RejectReasonDialog = ({ openDialog, closeDialog, rejectTransfers }) => {
  const [rejectReason, setRejectReason] = useState('');

  const handleChange = (event) => {
    const { value } = event.target;
    setRejectReason(value);
  };

  const handleRejectTransfers = () => {
    rejectTransfers(rejectReason);
    closeDialog();
  };

  return (
    <CDialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="md"
      fileslimit={1}
    >
      <DialogWrapper item container>
        <DialogHeader item container justifyContent="space-between">
          <Grid item container xs={6}>
            <HeaderText>Reason for Reject</HeaderText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={closeDialog} />
          </Grid>
          <SubText variant="caption">
            You are about to reject transferred products, you are required to give a reason for reject
          </SubText>
        </DialogHeader>
        <DialogContent item container>
          <SubText variant="caption" style={{ fontWeight: 500 }}>Add Note</SubText>
          <CustomInputBase
            multiline
            minRows={2}
            fullWidth
            placeholder="This Note will help the user know why you rejected the transfer"
            name="description"
            required
            value={rejectReason}
            helperText="Description required"
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions item container>
          <CustomButton
            type="secondary"
            header
            onClick={closeDialog}
            style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            type="primary"
            header
            onClick={handleRejectTransfers}
            disabled={!rejectReason}
            style={{ width: '11rem', height: '2.7rem' }}
          >
            Submit
          </CustomButton>
        </DialogActions>
      </DialogWrapper>
    </CDialog>
  );
};

RejectReasonDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  rejectTransfers: PropTypes.func.isRequired,
};

export default RejectReasonDialog;
