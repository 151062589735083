import React, { useState } from 'react';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  GridContainer,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MenuButton,
  PaperWrapper,
} from './styles';
import MainContent from '../../../customComponents/mainContent';
import ReturnsOrder from './orderReturn';
import Filter from './filter';
import InboundOrderLog from './inboundOrderLog';

const InboundHistory = () => {
  const navigate = useNavigate();
  const [tabVal, setTabVal] = useState('inboundLogs');

  const handleTabChange = (value) => {
    setTabVal(value);
  };

  const currentTab = () => {
    switch (tabVal) {
      case 'returnsLogs':
        return <ReturnsOrder />;
      default:
        return <InboundOrderLog />;
    }
  };

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <MenuButton onClick={() => navigate('/inventory/inbound')}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            Back
          </MenuButton>
          <TitleTextGridContainer>
            <div>
              <PrimaryTitle variant="h5">Inbound History</PrimaryTitle>
            </div>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <Filter
            tabVal={tabVal}
            handleTabChange={handleTabChange}
          />
          {currentTab()}
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default InboundHistory;
