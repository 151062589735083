import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BodyCell,
  ColorField,
  MainTableRow,
  Status,
} from './returnRow.styles';

const ReturnRow = ({
  row
}) => {
  const { dateCreated, message, status } = row || {};

  const date = moment(dateCreated).format('DD MMM YYYY h:mm a');
  const getStatus = () => (
    <ColorField>
      <Status status={status} />
      {status.toLowerCase().replace(/_/g, ' ')}
    </ColorField>
  );

  return (
    <MainTableRow>
      <BodyCell>{date}</BodyCell>
      <BodyCell>{message}</BodyCell>
      <BodyCell>{getStatus()}</BodyCell>

    </MainTableRow>

  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
